export const VERIFY_TOKEN = '[AUTH_STORE] Verify Token';
export const verifyToken = (payload, navigate, onComplete, isLoggingIn = false) => ({
  type: VERIFY_TOKEN,
  payload,
  navigate,
  onComplete,
  isLoggingIn
});

export const LOGOUT = '[AUTH_STORE] Logout';
export const logout = (navigate) => ({
  type: LOGOUT,
  navigate
});
