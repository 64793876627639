import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { ReactComponent as Logo } from '../../../icon/logo.svg';

import './complete-profile-layout.scss';

const CompleteProfileLayout = ({ children }) => {
  return (
    <section className="complete-profile-layout">
      <header>
        <article>
          <Logo />
        </article>
      </header>
      {children}
    </section>
  );
};

CompleteProfileLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default CompleteProfileLayout;
