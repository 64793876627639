export const LEAVE_ALLOCATION_TABS = Object.freeze({
  TIME_OFF: 'Time Off',
  Allocation: 'Allocation'
});

export const LEAVE_ALLOCATION_TAB_OPTIONS = Object.freeze([
  {
    label: 'Allocation',
    value: LEAVE_ALLOCATION_TABS.Allocation
  },
  {
    label: 'Time Off',
    value: LEAVE_ALLOCATION_TABS.TIME_OFF
  }
]);
