import React from 'react';
import { Link, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import KudosManagementDashboardContainer from '../container/kudos-management-dashboard/KudosManagementDashboard.container';
import KudosCategoryManagementContainer from '../container/kudos-management/KudosCategoryManagement.container';

export const kudosManagementRoutes = () => (
  <Route
    path="/kudos-management"
    handle={{
      crumb: () => <Link to="/kudos-management">Kudos Management Dashboard</Link>
    }}
  >
    <Route
      exact
      path=""
      handle={{
        pageTitle: 'Kudos Management'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <KudosManagementDashboardContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="categories"
      handle={{
        pageTitle: 'Kudos Category Management',
        crumb: () => <Link to="/kudos-management/categories">Category Management</Link>
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <KudosCategoryManagementContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
