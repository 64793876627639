import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getClientId } from '../../../tool/auth.util';

import { verifyToken } from '../../../state/action/auth.actions';
import { addSystemNotice } from '../../../state/action/system.actions';

import ProgressLoaderProvider from '../../../provider/ProgressLoader.provider';

import Login from '../../../component/auth/login/Login';
import AuthLayout from '../../../component/layout/auth/AuthLayout';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnSuccess = (credentialResponse, onComplete) => {
    const payload = { token: credentialResponse.credential };
    dispatch(verifyToken(payload, navigate, onComplete, true));
  };

  const handleOnError = () => {
    dispatch(addSystemNotice('Could not verify your account', SNACK_CRITICAL));
  };

  return (
    <GoogleOAuthProvider clientId={getClientId()}>
      <ProgressLoaderProvider>
        <AuthLayout>
          <Login onSuccess={handleOnSuccess} onError={handleOnError} />
        </AuthLayout>
      </ProgressLoaderProvider>
    </GoogleOAuthProvider>
  );
};

export default LoginContainer;
