import { arrayOf, bool, number, object, oneOfType, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as ChevronIcon } from '../../../icon/chevron-icon.svg';

import { Dropdown } from '../dropdown/Dropdown';
import { Input } from '../input/Input';

import './select.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

export const Select = ({
  name,
  options,
  label,
  value,
  error,
  required,
  disabled,
  borderless,
  small,
  buttonAlignEnd,
  onChange
}) => {
  const memoizedValue = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return option?.label;
  }, [options, value]);

  const [open, setOpen] = useState(false);

  const memoizedOptions = useMemo(() => {
    const handleChange = (val) => {
      val = getChangeValue(name, val);
      onChange(val);
      setOpen(false);
    };

    return options.map(({ label, value: optionValue }) => ({
      text: (
        <span className={getClassNames('select__option', { end: buttonAlignEnd })}>
          <div
            className={getClassNames('select__option-ellipse', { checked: optionValue === value })}
          />
          <span>{label}</span>
        </span>
      ),
      onClick: () => handleChange(optionValue)
    }));
  }, [name, onChange, options, value, buttonAlignEnd]);

  return (
    <div className={getClassNames('select', { open, small })}>
      <Dropdown
        expandable={disabled}
        menuItems={memoizedOptions}
        placement="bottom-start"
        sameWidth
      >
        <Input
          name={name}
          label={label}
          required={required}
          value={memoizedValue}
          error={error}
          disabled={disabled}
          borderless={borderless}
          onFocus={() => setOpen(true)}
          action={<ChevronIcon />}
          onActionClick={() => setOpen(!open)}
        />
      </Dropdown>
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
  required: false,
  borderless: false,
  small: false,
  buttonAlignEnd: false
};

Select.propTypes = {
  name: string,
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string, object])
    })
  ).isRequired,
  label: string,
  value: oneOfType([number, string]),
  disabled: bool,
  required: bool,
  borderless: bool,
  small: bool,
  error: string,
  buttonAlignEnd: bool
};
