import { bool } from 'prop-types';
import React from 'react';

import { useProfile } from '../../../../hook/useProfile';

import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';

const BankDetailsForm = ({ disabled }) => {
  const { form, errors, onChange } = useProfile();

  const handleChange = (newState) =>
    onChange({
      bankDetails: {
        ...form?.bankDetails,
        ...newState
      }
    });

  return (
    <>
      <FormRow>
        <Input
          label="Bank Name"
          name="bankName"
          value={form?.bankDetails?.bankName}
          error={errors?.bankName}
          onChange={handleChange}
          disabled={disabled}
          required
        />
        <Input
          label="Account Type"
          name="accountType"
          value={form?.bankDetails?.accountType}
          error={errors?.accountType}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Account Holder Name"
          name="accountHolder"
          value={form?.bankDetails?.accountHolder}
          error={errors?.accountHolder}
          onChange={handleChange}
          disabled={disabled}
          required
        />
        <Input
          label="Account Number"
          name="accountNumber"
          value={form?.bankDetails?.accountNumber}
          error={errors?.accountNumber}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Branch Code"
          name="branchCode"
          value={form?.bankDetails?.branchCode}
          error={errors?.branchCode}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
    </>
  );
};

BankDetailsForm.defaultProps = {
  disabled: false
};

BankDetailsForm.propTypes = {
  disabled: bool
};

export default BankDetailsForm;
