import React from 'react';
import { Link, Route } from 'react-router-dom';

import { NONE, ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import UserManagementContainer from '../container/user/management/UserManagement.container';
import MobileAddUserContainer from '../container/user/management/add/mobile/MobileAddUser.container';
import EditProfileContainer from '../container/user/profile/EditProfile.container';

export const userManagementRoutes = () => (
  <Route
    path="user-management"
    handle={{
      crumb: () => <Link to="/user-management">User Management</Link>,
      pageName: 'User Management',
      pageTitle: 'User Management'
    }}
  >
    <Route
      path=""
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <UserManagementContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path=":userId/:userName"
      handle={{
        crumb: ({ params: { userId, userName } }) => (
          <Link to={`/user-management/${userId}/${userName}`}>{userName}</Link>
        ),
        pageName: NONE,
        pageTitle: 'Manage Employee Profile'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <EditProfileContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="add"
      handle={{
        pageName: 'Add User',
        pageTitle: 'Add User'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
          <MobileAddUserContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
