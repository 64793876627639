import { func } from 'prop-types';
import React from 'react';

import { ROLES } from '../../../../tool/constant';
import { USER_TYPE } from '../../../../tool/prop-types';

import { Button } from '../../../../common/component/button/Button';

import './change-role.scss';

const ChangeRole = ({ user, onCancelClick, onConfirmClick }) => {
  const textByRole = () => {
    switch (user.role) {
      case ROLES.EMPLOYEE:
        return ' a user takes away the ability to edit certain profile information, add and remove employees and change certain roles.';
      case ROLES.ADMIN:
        return ' an admin gives them the ability to edit certain profile information, add and remove employees and change user roles.';
      case ROLES.OPERATIONS:
        return (
          ' an operations user means they will no longer be able to login to this app. Their role can be changed back at any time ' +
          '(allowing them to login again).'
        );
      default:
        throw Error('The user role is not recognized');
    }
  };

  return (
    <article className="change-role">
      <p>
        Making&nbsp;
        <span>
          {user?.firstName} {user?.lastName}
        </span>
        {textByRole()}
      </p>
      <section className="change-role__actions">
        <Button label="Confirm" onClick={onConfirmClick} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </section>
    </article>
  );
};

ChangeRole.defaultProps = {
  user: {}
};

ChangeRole.propTypes = {
  user: USER_TYPE,
  onCancelClick: func
};

export default ChangeRole;
