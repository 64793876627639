import { bool, string } from 'prop-types';

import { getClassNames } from '@neslotech/utils';

import './blip.scss';

const Blip = ({
  label = '',
  secondaryLabel = '',
  colour = '',
  testId,
  rectangular = false,
  large = false
}) => (
  <article className="blip">
    <span
      className={getClassNames('blip__color', { rectangular, large })}
      data-testid={testId}
      style={{ backgroundColor: colour }}
    />
    {label && <span className="blip__label">{label}</span>}
    {secondaryLabel && <span className="blip__secondary-label">{secondaryLabel}</span>}
  </article>
);

Blip.propTypes = {
  colour: string.isRequired,
  label: string,
  secondaryLabel: string,
  testId: string,
  rectangular: bool,
  large: bool
};

export default Blip;
