import React from 'react';
import { Link, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import KudosContainer from '../container/kudos/Kudos.container';

export const kudosRoutes = () => (
  <Route
    path="/kudos"
    handle={{
      crumb: () => <Link to="/kudos">Kudos</Link>,
      pageName: 'Kudos'
    }}
  >
    <Route
      exact
      path=""
      handle={{
        pageTitle: 'Kudos'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <KudosContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
