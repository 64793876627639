import { bool, number } from 'prop-types';
import { useRef } from 'react';

import SkeletonLoader from '../../../../common/component/loader/skeleton/SkeletonLoader';

import './give-kudos-title.scss';

const GiveKudosTitle = ({ remaining, loading }) => {
  const ref = useRef();

  return (
    <hgroup className="give-kudos-title">
      <h1>Give Kudos</h1>
      {/*TODO: Benjamin to fix the skeleton loader to accommodate the span alone*/}
      <SkeletonLoader loading={loading} elementRef={ref}>
        <div ref={ref}>
          <span className="give-kudos-title__remaining">{remaining}</span>
          <span className="give-kudos-title__subtitle">Points Remaining</span>
        </div>
      </SkeletonLoader>
    </hgroup>
  );
};

GiveKudosTitle.defaultProps = {
  loading: false,
  remaining: 0
};

GiveKudosTitle.propTypes = {
  remaining: number,
  loading: bool
};

export default GiveKudosTitle;
