import {
  SET_CLIENT,
  SET_CLIENTS,
  UNLINK_REALTIME_PROJECT
} from '../../action/scheduling/client.actions';

export const initialState = {
  clients: [],
  client: undefined
};

export function clientReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENTS:
      const { clients } = action;
      return {
        ...state,
        clients
      };
    case SET_CLIENT:
      const { client } = action;
      return {
        ...state,
        client
      };
    case UNLINK_REALTIME_PROJECT: {
      const { event } = action;

      const filteredProjects = state.client.projects.filter(
        (project) => project.id !== event.projectId
      );
      return {
        ...state,
        client: {
          ...state.client,
          projects: filteredProjects
        }
      };
    }
    default:
      return state;
  }
}
