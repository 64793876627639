import React, { useEffect, useState } from 'react';

import { ChannelContext } from '../context/Channel.context';

import { usePusher } from '../hook/usePusher';

export const ChannelProvider = ({ channelName, children }) => {
  const [channel, setChannel] = useState();

  const { pusher } = usePusher();

  useEffect(() => {
    if (!channelName || channel) {
      return;
    }

    const subscription = pusher.subscribe(channelName);
    setChannel(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName]);

  const handleBindToEvent = (eventName, callback) => {
    if (Object.keys(channel?.callbacks).length === 0 || !!channel?.callbacks?.get(eventName)) {
      return;
    }

    channel?.bind(eventName, callback, channelName);
  };

  if (!channel) {
    return null;
  }

  return (
    <ChannelContext.Provider value={{ bindToEvent: handleBindToEvent }}>
      {children}
    </ChannelContext.Provider>
  );
};
