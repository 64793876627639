import { arrayOf, bool, func } from 'prop-types';
import React, { useRef } from 'react';

import { isEmpty, noOp } from '@neslotech/utils';

import { getSkeletonPlaceholders } from '../../../tool/loader.helper';

import { NOMINATION_TYPE } from '../../../tool/prop-types';

import KudosNominationCardContainer from '../../../container/kudos/cards/KudosNominationCards.container';

import { Button } from '../../../common/component/button/Button';
import SkeletonLoader from '../../../common/component/loader/skeleton/SkeletonLoader';

import './kudos-timeline.scss';

const KudosTimeline = ({ nominations, onGiveKudosClick, loading }) => {
  const skeletonRef = useRef();

  if (!loading && isEmpty(nominations)) {
    return (
      <section className="kudos-timeline" ref={skeletonRef}>
        <div className="kudos-timeline__empty-message">
          <h6>There is no activity here yet</h6>
          <p>No one has anything to share just yet.</p>
          <p>Nominate your coworkers to make the first post on the timeline!</p>
          <Button label="Give Kudos" onClick={onGiveKudosClick} />
        </div>
      </section>
    );
  }

  const entities = loading ? getSkeletonPlaceholders(['id', 'category'], 5) : nominations;

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <section className="kudos-timeline" ref={skeletonRef}>
        <KudosNominationCardContainer nominations={entities} />
      </section>
    </SkeletonLoader>
  );
};

KudosTimeline.defaultProps = {
  nominations: [],
  onGiveKudosClick: noOp,
  loading: false
};

KudosTimeline.propTypes = {
  nominations: arrayOf(NOMINATION_TYPE),
  onGiveKudosClick: func,
  loading: bool
};

export default KudosTimeline;
