import { arrayOf, bool } from 'prop-types';
import React, { useState } from 'react';

import { VIEW_OPTIONS } from '../../../tool/constant';
import { MOCK_JIRA_RESPONSE } from '../../../tool/mocks';
import { USER_TYPE } from '../../../tool/prop-types';

import { useAuth } from '../../../hook/useAuth';

import { SchedulingAllocationFormContainer } from '../../../container/scheduling/allocation/form/SchedulingAllocationForm.container';

import { Dropdown } from '../../../common/component/dropdown/Dropdown';
import { SearchOptions } from '../../../common/component/input/search/options/SearchOptions';
import { Loader } from '../../../common/component/loader/Loader';

import { ReactComponent as BusinessTimeIcon } from '../../../icon/business-time.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../icon/chev-left-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../../../icon/chev-right-icon.svg';
import { ReactComponent as ClockIcon } from '../../../icon/clock.svg';
import { ReactComponent as EditIcon } from '../../../icon/edit-icon.svg';
import { ReactComponent as ListIcon } from '../../../icon/list.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';
import { ReactComponent as SidebarIcon } from '../../../icon/sidebar-icon.svg';

import { SchedulingCalendar } from './calendar/SchedulingCalendar';
import { JiraSidebar } from './calendar/jira/JiraSidebar';

import './scheduling-dashboard.scss';

export const SchedulingDashboard = ({ users, loading }) => {
  const { id } = useAuth();

  const TEAM_VIEW = 'team_view';

  const [selectedOption, setSelectedOption] = useState(TEAM_VIEW);
  const [openModal, setOpenModal] = useState(null);
  const [scrollToToday, setScrollToToday] = useState(false);

  const onChange = ({ option }) => {
    setSelectedOption(option);
  };

  const filteredUsers =
    selectedOption !== TEAM_VIEW ? users.filter((user) => user.id === id) : users;

  const handleMenuClick = (modalType) => {
    setOpenModal(modalType);
  };

  const closeModal = () => {
    setOpenModal(null);
  };

  const menuItems = [
    { icon: <ListIcon />, text: 'Allocate Time', onClick: () => handleMenuClick('taskAllocation') },
    {
      icon: <ClockIcon />,
      text: 'Allocate Time Off',
      onClick: () => handleMenuClick('leaveAllocation')
    },
    { icon: <BusinessTimeIcon />, text: 'Submit Time' },
    { icon: <EditIcon />, text: 'Submit Request' }
  ];

  const selectedTab =
    openModal === 'leaveAllocation'
      ? 'Time Off'
      : openModal === 'taskAllocation'
      ? 'Allocation'
      : null;

  if (loading) {
    return (
      <div className="scheduling-dashboard__loader">
        <Loader dark />
      </div>
    );
  }

  return (
    <>
      <section className="scheduling-dashboard">
        <header>
          <SearchOptions
            name="option"
            label={VIEW_OPTIONS.find((option) => option.value === selectedOption).label}
            options={VIEW_OPTIONS}
            value={selectedOption}
            onChange={onChange}
            secondaryLabel=""
            focused
          />
          <div className="scheduling-dashboard__controls">
            <div>
              <span className="scheduling-dashboard__navigation-left">
                <ChevronLeftIcon />
              </span>
              <button
                className="scheduling-dashboard__navigation-date"
                onClick={() => setScrollToToday(true)}
              >
                Today
              </button>
              <span className="scheduling-dashboard__navigation-right">
                <ChevronRightIcon />
              </span>
            </div>
            <Dropdown
              dropdownChildren={<JiraSidebar projects={MOCK_JIRA_RESPONSE} />}
              placement="bottom-end"
              offset={[60]}
            >
              <div className="scheduling-dashboard__sidebar-icon">
                <SidebarIcon />
              </div>
            </Dropdown>
            <Dropdown menuItems={menuItems} placement="bottom-end" offset={[0, -12]}>
              <div className="scheduling-dashboard__allocation-icon">
                <PlusIcon />
              </div>
            </Dropdown>
          </div>
        </header>
      </section>
      {selectedTab && (
        <SchedulingAllocationFormContainer onClose={closeModal} selectedTab={selectedTab} />
      )}
      <SchedulingCalendar
        users={filteredUsers}
        personal={selectedOption !== TEAM_VIEW}
        scrollToToday={scrollToToday}
        setScrollToToday={setScrollToToday}
      />
    </>
  );
};

SchedulingDashboard.propTypes = {
  users: arrayOf(USER_TYPE),
  loading: bool.isRequired
};
