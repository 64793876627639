import { CLEAR_ERRORS, SET_ERRORS, SET_USER, SET_USERS } from '../action/user.action';

export const initialState = {
  users: [],
  user: undefined
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      const { users } = action;
      return {
        ...state,
        users
      };
    case SET_USER:
      const { user } = action;
      return {
        ...state,
        user
      };
    case SET_ERRORS:
      const { errors } = action;
      return {
        ...state,
        errors
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: undefined
      };
    default:
      return state;
  }
}
