import React from 'react';

import { useFilters } from '../../../hook/useFilter';

import { Tag } from '../tag/Tag';

import './no-results.scss';

const renderTags = (filters, removeFilters) =>
  Object.keys(filters).map((key) => (
    <Tag key={key} text={key} onClick={() => removeFilters(key)} />
  ));

const NoResults = ({ emptyTitle, emptySubtitle }) => {
  const { filters, removeFilters } = useFilters();

  return (
    <article className="no-results">
      <h3>{emptyTitle}</h3>
      <p>{emptySubtitle}</p>
      <section className="no-results__tags">
        {filters && renderTags(filters, removeFilters)}
      </section>
    </article>
  );
};

export default NoResults;
