import { arrayOf, bool, shape, string } from 'prop-types';
import React from 'react';

import { USER_TYPE } from '../../tool/prop-types';

import AdminDashboard from '../../component/dashboard/AdminDashboard';

const AdminDashboardContainer = ({
  profile,
  users = [],
  directoryUsers = [],
  projects = [],
  loading = false
}) => (
  <AdminDashboard
    profile={profile}
    users={users}
    directoryUsers={directoryUsers}
    projects={projects}
    loading={loading}
  />
);

AdminDashboardContainer.propTypes = {
  profile: shape({}).isRequired,
  users: arrayOf(USER_TYPE),
  directoryUsers: arrayOf(USER_TYPE),
  projects: arrayOf(shape({ id: string })),
  loading: bool
};

export default AdminDashboardContainer;
