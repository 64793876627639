import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadUsers } from '../../../state/action/user.action';

import AppLayout from '../../../component/layout/AppLayout';
import UserManagement from '../../../component/user/management/UserManagement';

const UserManagementContainer = () => {
  const dispatch = useDispatch();

  const stateLoading = useSelector(({ user_store }) => user_store.loading);
  const users = useSelector(({ user_store }) => user_store.users);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const stopLoading = () => setLoading(false);

  useEffect(() => {
    dispatch(loadUsers(stopLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout fullWidthBreadcrumb>
      <UserManagement users={users} loading={loading} />
    </AppLayout>
  );
};

export default UserManagementContainer;
