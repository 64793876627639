import { bool } from 'prop-types';
import React, { createRef } from 'react';

import { useDevice } from '../../../hook/useDevice';
import { useProfile } from '../../../hook/useProfile';

import { Button } from '../../../common/component/button/Button';
import { FileUpload } from '../../../common/component/upload/FileUpload';

import { ReactComponent as CameraIcon } from '../../../icon/camera-icon.svg';

import './profile-image-upload.scss';

const ProfileImageUpload = ({ disabled }) => {
  const fileRef = createRef();

  const { form, onChange, handleFileChange } = useProfile();
  const { mobile } = useDevice();

  return (
    <div className="profile-image-upload">
      {form?.image && (
        <Button
          label={mobile ? 'Remove' : 'Remove Image'}
          onClick={() => onChange({ ...form, image: undefined })}
          text
        />
      )}
      <div className="profile-image-upload__image">
        <img
          src={form?.image ?? form?.defaultImage}
          referrerPolicy="no-referrer"
          alt="Profile icon"
        />
        {!disabled && (
          <div className="profile-image-upload__action">
            <FileUpload onChange={handleFileChange} fileRef={fileRef}>
              <CameraIcon onClick={() => fileRef.current.click()} />
            </FileUpload>
          </div>
        )}
      </div>
      <span>Browse files to update your profile image</span>
      <div className="profile-image-upload__accent-line" />
    </div>
  );
};

ProfileImageUpload.defaultProps = {
  disabled: false
};

ProfileImageUpload.propTypes = {
  disabled: bool
};

export default ProfileImageUpload;
