import { arrayOf, bool, func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { noOp } from '@neslotech/utils';

import { filterElement, searchElement } from '../../../tool/filter.util';
import { LEAVE_CATEGORY_TYPE } from '../../../tool/prop-types';

import { useFilters } from '../../../hook/useFilter';

import { LeaveCategoryFormContainer } from '../../../container/scheduling/leave-category/details/form/LeaveCategoryForm.container';

import { Button } from '../../../common/component/button/Button';
import { Table } from '../../../common/component/table/Table';

import { ReactComponent as ArchiveIcon } from '../../../icon/archive-icon.svg';
import { ReactComponent as ReinstateIcon } from '../../../icon/redo-icon.svg';

import { FilterLayout } from '../../../common/layout/filter/FilterLayout';
import Dialog from '../../../common/layout/portal/Dialog';
import Blip from '../../blip/Blip';

import './leave-category-list.scss';

const TABLE_COLUMNS = [
  { Header: 'Category Name', accessor: 'name' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Category Colour', accessor: 'colour' },
  { Header: '', accessor: 'actions', disableSortBy: true }
];

const DEFAULT_FILTERS = Object.freeze({ status: { filterValues: ['Active'] } });

const FILTER_OPTIONS = Object.freeze([
  {
    name: 'Status',
    active: false,
    singleSelect: true,
    values: [
      { value: 'Archived', selected: false },
      { value: 'Active', selected: true }
    ]
  }
]);

const LeaveCategoryTable = ({
  categories = [],
  loading = false,
  onArchive,
  onReinstate,
  onEdit
}) => {
  const { filters, search, setDefaultOptions, setFilters } = useFilters();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const clearSelectedCategory = () => setSelectedCategory(null);

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    setFilters(DEFAULT_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowData = useMemo(() => {
    const handleAction = (event, category) => {
      event.stopPropagation();
      if (category.deleted) {
        onReinstate(category.id);
        return;
      }

      setSelectedCategory(category);
    };

    return categories
      .map(({ deleted, name, colour, id }) => ({
        name,
        colour: <Blip large colour={colour} />,
        status: deleted ? 'Archived' : 'Active',
        actions: (
          <button onClick={(event) => handleAction(event, { id, deleted })}>
            {deleted ? <ReinstateIcon /> : <ArchiveIcon />}
          </button>
        ),
        onClick: deleted ? noOp : () => onEdit({ id, name, colour })
      }))
      .filter((category) => filterElement(category, filters))
      .filter((category) => searchElement(category.name, search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, filters, search]);

  return (
    <div className="leave-category-list__table">
      <Table
        cols={TABLE_COLUMNS}
        rowData={rowData}
        emptyTitle="No Time Off Categories"
        emptySubtitle="An admin is required to set up time off categories"
        loading={loading}
      />

      {selectedCategory && (
        <Dialog
          heading="Are you sure?"
          message="Are you sure you want to archive this category? This action can be undone by reinstating the category."
          isOpen
          primaryText="Yes"
          secondaryText="No"
          onSecondaryClick={clearSelectedCategory}
          onClose={clearSelectedCategory}
          onPrimaryClick={() => onArchive(selectedCategory.id, clearSelectedCategory)}
        />
      )}
    </div>
  );
};

export const LeaveCategoryList = ({ categories = [], loading = false, onArchive, onReinstate }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleAddButtonClick = () => {
    setSelectedCategory(null);
    setFormVisible(true);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setSelectedCategory(null);
  };

  return (
    <section className="leave-category-list">
      <FilterLayout
        label="Search for something"
        action={<Button label="Add Category" onClick={handleAddButtonClick} />}
      >
        <LeaveCategoryTable
          categories={categories}
          loading={loading}
          onArchive={onArchive}
          onReinstate={onReinstate}
          onEdit={handleEditCategory}
        />
      </FilterLayout>
      {isFormVisible && (
        <LeaveCategoryFormContainer
          onClose={handleCloseForm}
          leaveCategory={selectedCategory}
          isEditMode={!!selectedCategory}
        />
      )}
    </section>
  );
};

LeaveCategoryList.propTypes = {
  categories: arrayOf(LEAVE_CATEGORY_TYPE),
  loading: bool,
  onArchive: func.isRequired,
  onReinstate: func.isRequired
};
