import { bool } from 'prop-types';
import React from 'react';

import { useProfile } from '../../../../hook/useProfile';

import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';

const SocialLinksForm = ({ disabled }) => {
  const { form, errors, onChange } = useProfile();

  const handleChange = (newState) =>
    onChange({
      socialLinks: {
        ...form?.socialLinks,
        ...newState
      }
    });

  return (
    <>
      <FormRow>
        <Input
          label="LinkedIn Link"
          name="linkedIn"
          value={form?.socialLinks?.linkedIn}
          error={errors?.linkedIn}
          onChange={handleChange}
          disabled={disabled}
        />
        <Input
          label="Behance Link"
          name="behance"
          value={form?.socialLinks?.behance}
          error={errors?.behance}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <Input
          label="GitHub Link"
          name="github"
          value={form?.socialLinks?.github}
          error={errors?.github}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormRow>
    </>
  );
};

SocialLinksForm.defaultProps = {
  disabled: false
};

SocialLinksForm.propTypes = {
  disabled: bool
};

export default SocialLinksForm;
