import { bool } from 'prop-types';
import React from 'react';

import { useProfile } from '../../../../hook/useProfile';

import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';

const EmergencyContactForm = ({ disabled }) => {
  const { form, errors, onChange } = useProfile();

  const handleChange = (newState) =>
    onChange({
      emergencyContact: {
        ...form?.emergencyContact,
        ...newState
      }
    });

  return (
    <>
      <FormRow>
        <Input
          label="Emergency Contact Name"
          name="contactName"
          value={form?.emergencyContact?.contactName}
          error={errors?.contactName}
          onChange={handleChange}
          disabled={disabled}
          required
        />
        <Input
          label="Emergency Contact Number"
          name="contactNumber"
          value={form?.emergencyContact?.contactNumber}
          error={errors?.contactNumber}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Medical Aid Option"
          name="medicalAidOption"
          value={form?.emergencyContact?.medicalAidOption}
          error={errors?.medicalAidOption}
          onChange={handleChange}
          disabled={disabled}
        />
        <Input
          label="Dependant Code"
          name="dependantCode"
          value={form?.emergencyContact?.dependantCode}
          error={errors?.dependantCode}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <Input
          label="Main Policy Holder"
          name="policyHolder"
          value={form?.emergencyContact?.policyHolder}
          error={errors?.policyHolder}
          onChange={handleChange}
          disabled={disabled}
        />
        <Input
          label="Medical Aid"
          name="medicalAid"
          value={form?.emergencyContact?.medicalAid}
          error={errors?.medicalAid}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <Input
          label="Medical Aid Number"
          name="medicalAidNumber"
          value={form?.emergencyContact?.medicalAidNumber}
          error={errors?.medicalAidNumber}
          onChange={handleChange}
          disabled={disabled}
        />
      </FormRow>
    </>
  );
};

EmergencyContactForm.defaultProps = {
  disabled: false
};

EmergencyContactForm.propTypes = {
  disabled: bool
};

export default EmergencyContactForm;
