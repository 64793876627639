import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { LEAVE_ALLOCATION_TAB_OPTIONS } from './scheduling-allocation-form.helper';

import { LEAVE_ALLOCATION_TYPE } from '../../../../tool/prop-types';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';
import Tabs from '../../../tabs/Tabs';
import { LeaveAllocationForm } from './leave-allocation/LeaveAllocationForm';
import { TaskAllocationForm } from './task-allocation/TaskAllocationForm';

import './scheduling-allocation-form.scss';

export const SchedulingAllocationForm = ({
  onSubmitLeaveAllocation,
  onClose,
  categories = [],
  isAdmin = false,
  users = [],
  loggedInUserId,
  openedTab,
  projects = [],
  tasks = [],
  onAddTaskAllocation,
  setProjectId,
  allocation = {},
  isEditing = false,
  onRemoveLeaveAllocation,
  onRemoveTaskAllocation,
  onAddProjectTask
}) => {
  const [selectedTab, setSelectedTab] = useState(openedTab);

  const allocationForm = useMemo(() => {
    if (selectedTab === 'Time Off') {
      return (
        <LeaveAllocationForm
          onSubmit={onSubmitLeaveAllocation}
          categories={categories}
          loggedInUserId={loggedInUserId}
          users={users}
          isAdmin={isAdmin}
          onClose={onClose}
          isEditing={isEditing}
          allocation={allocation}
          onRemove={onRemoveLeaveAllocation}
        />
      );
    } else if (selectedTab === 'Allocation') {
      return (
        <TaskAllocationForm
          onSubmit={onAddTaskAllocation}
          tasks={tasks}
          projects={projects}
          loggedInUserId={loggedInUserId}
          users={users}
          isAdmin={isAdmin}
          onClose={onClose}
          setProjectId={setProjectId}
          isEditing={isEditing}
          allocation={allocation}
          onRemove={onRemoveTaskAllocation}
          onAddProjectTask={onAddProjectTask}
        />
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, tasks, categories, projects, allocation]);

  const handleModalClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Modal className="scheduling-allocation-form">
      <ModalLayout onClose={(e) => handleModalClose(e)}>
        <div className="scheduling-allocation-form__tabs">
          {isEditing ? (
            <span className="scheduling-allocation-form__selected-tab">{selectedTab}</span>
          ) : (
            <Tabs
              selectedTab={selectedTab}
              hideFirst
              tabs={LEAVE_ALLOCATION_TAB_OPTIONS}
              setSelectedTab={setSelectedTab}
            />
          )}
        </div>
        {allocationForm}
      </ModalLayout>
    </Modal>
  );
};

SchedulingAllocationForm.propTypes = {
  onClose: func.isRequired,
  isAdmin: bool,
  users: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string
    })
  ),
  categories: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  tasks: arrayOf(
    shape({
      title: string,
      id: string
    })
  ),
  projects: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  loggedInUserId: string.isRequired,
  onSubmitLeaveAllocation: func.isRequired,
  onAddTaskAllocation: func.isRequired,
  openedTab: string.isRequired,
  allocation: LEAVE_ALLOCATION_TYPE,
  isEditing: bool,
  onRemoveLeaveAllocation: func.isRequired,
  onRemoveTaskAllocation: func.isRequired,
  onAddProjectTask: func.isRequired
};
