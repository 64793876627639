import React from 'react';

import { useDevice } from '../../../hook/useDevice';
import { useFilters } from '../../../hook/useFilter';

import { Button } from '../button/Button';
import { Filters } from './Filters';

import './filters-wrapper.scss';

export const FiltersWrapper = () => {
  const { options, setFilters, onClearFilters, filtering } = useFilters();
  const { mobile } = useDevice();

  const handleApplyFilters = () => {
    const selectedOptions = options
      .filter((option) => option.values.some((value) => value.selected))
      .reduce((accum, option) => {
        accum[option.name.toLowerCase()] = {
          filterValues: option.values.filter((value) => value.selected).map((value) => value.value)
        };

        return accum;
      }, {});

    setFilters(selectedOptions);
  };

  return (
    <div className="filters-wrapper">
      <Filters onApplyFilters={handleApplyFilters} />
      {mobile && filtering && <Button label="Clear All" onClick={onClearFilters} text />}
    </div>
  );
};
