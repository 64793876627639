import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/utils';

import { loadAllProjectsMetadata } from '../../../state/action/scheduling/project.actions';
import { loadAllAllocations } from '../../../state/action/scheduling/scheduling-allocation.actions';
import { loadAllLeaveCategories } from '../../../state/action/scheduling/settings/leave-category.actions';

import FilterProvider from '../../../provider/Filter.provider';

import { useSchedulingPermissions } from '../../../hook/useSchedulingPermissions';

import { SchedulingDashboard } from '../../../component/scheduling/dashboard/SchedulingDashboard';

export const SchedulingDashboardContainer = () => {
  const { mappedSchedulingUsers } = useSchedulingPermissions();
  const dispatch = useDispatch();

  const allocations = useSelector(
    ({ scheduling_allocation_store }) => scheduling_allocation_store.allocations
  );
  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);
  const categories = useSelector(({ leave_category_store }) => leave_category_store.categories);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEmpty(allocations)) {
      setLoading(true);
      dispatch(loadAllAllocations(() => setLoading(false)));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(projects)) {
      dispatch(loadAllProjectsMetadata());
    }

    if (isEmpty(categories)) {
      dispatch(loadAllLeaveCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterProvider>
      <SchedulingDashboard users={mappedSchedulingUsers} loading={loading} />
    </FilterProvider>
  );
};
