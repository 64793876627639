import { bool } from 'prop-types';
import React from 'react';

import { useProfile } from '../../../../hook/useProfile';

import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';

const PhysicalAddressForm = ({ disabled }) => {
  const { form, errors, onChange } = useProfile();

  const handleChange = (newState) =>
    onChange({
      physicalAddress: {
        ...form?.physicalAddress,
        ...newState
      }
    });

  return (
    <>
      <FormRow>
        <Input
          label="Complex/Building"
          name="buildingName"
          value={form?.physicalAddress?.buildingName}
          onChange={handleChange}
          disabled={disabled}
        />
        <Input
          label="Street Address"
          name="streetAddress"
          value={form?.physicalAddress?.streetAddress}
          error={errors?.streetAddress}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Suburb"
          name="suburb"
          value={form?.physicalAddress?.suburb}
          error={errors?.suburb}
          onChange={handleChange}
          disabled={disabled}
          required
        />
        <Input
          label="City"
          name="city"
          value={form?.physicalAddress?.city}
          error={errors?.city}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
      <FormRow>
        <Input
          label="Postal Code"
          name="postalCode"
          value={form?.physicalAddress?.postalCode}
          error={errors?.postalCode}
          onChange={handleChange}
          disabled={disabled}
          required
        />
      </FormRow>
    </>
  );
};

PhysicalAddressForm.defaultProps = {
  disabled: false
};

PhysicalAddressForm.propTypes = {
  disabled: bool
};

export default PhysicalAddressForm;
