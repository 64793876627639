import { noOp } from '@neslotech/utils';

const STORE_NAME = '[PROJECT_STORE]';

export const SET_PROJECTS = `${STORE_NAME} Set projects`;

export const LOAD_ALL_PROJECTS = `${STORE_NAME} Load all projects`;
export const loadAllProjects = (onComplete = noOp) => ({
  type: LOAD_ALL_PROJECTS,
  onComplete
});

export const LOAD_PROJECT = `${STORE_NAME} Load project`;
export const loadProject = (id, onComplete) => ({
  type: LOAD_PROJECT,
  id,
  onComplete
});

export const CREATE_PROJECT = `${STORE_NAME} Create project`;
export const SET_PROJECT = `${STORE_NAME} Set project`;
export const createProject = (payload, navigate, onComplete) => ({
  type: CREATE_PROJECT,
  payload,
  navigate,
  onComplete
});

export const UPDATE_PROJECT = `${STORE_NAME} Update project`;
export const updateProject = (id, payload, onSuccess, onComplete) => ({
  type: UPDATE_PROJECT,
  id,
  payload,
  onSuccess,
  onComplete
});

export const SAVE_TEAM_MEMBERS = `${STORE_NAME} Save team members`;
export const saveTeamMembers = (projectId, payload, onSuccess, onComplete) => ({
  type: SAVE_TEAM_MEMBERS,
  projectId,
  payload,
  onSuccess,
  onComplete
});

export const ARCHIVE_PROJECT = `${STORE_NAME} Archive project`;
export const archiveProject = (projectId, onComplete) => ({
  type: ARCHIVE_PROJECT,
  projectId,
  onComplete
});

export const REINSTATE_PROJECT = `${STORE_NAME} Reinstate project`;
export const reinstateProject = (projectId, onComplete) => ({
  type: REINSTATE_PROJECT,
  projectId,
  onComplete
});

export const CLEAR_PROJECT = `${STORE_NAME} Clear project`;
export const clearProject = () => ({ type: CLEAR_PROJECT });

export const LOAD_ALL_PROJECTS_METADATA = `${STORE_NAME} Load all projects metadata`;
export const SET_PROJECTS_METADATA = `${STORE_NAME} Set projects metadata`;
export const loadAllProjectsMetadata = (onComplete = noOp) => ({
  type: LOAD_ALL_PROJECTS_METADATA,
  onComplete
});
