import React from 'react';
import { Link, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import BasicProfileContainer from '../container/user/profile/BasicProfile.container';
import EditProfileContainer from '../container/user/profile/EditProfile.container';

export const userRoutes = () => (
  <Route
    path="users/:userId/profile"
    handle={{
      crumb: ({ params: { userId } }) => <Link to={`/users/${userId}/profile`}>Profile</Link>
    }}
  >
    <Route
      path=""
      handle={{
        pageName: 'Profile',
        pageTitle: 'My Profile'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <BasicProfileContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="edit"
      handle={{
        crumb: ({ params: { userId } }) => (
          <Link to={`/users/${userId}/profile/edit`}>Edit Profile</Link>
        ),
        pageTitle: 'Edit My Profile'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <EditProfileContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
