import { arrayOf } from 'prop-types';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { PROFILE_TYPE, USER_TYPE } from '../../tool/prop-types';

import { Card } from '../../common/component/card/Card';
import SkeletonLoader from '../../common/component/loader/skeleton/SkeletonLoader';

import './employee-dashboard.scss';

const EmployeeDashboard = ({ profile, directoryUsers, loading }) => {
  const skeletonRef = useRef();

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <section className="employee-dashboard" ref={skeletonRef}>
        <h1>
          <span>Welcome Back,</span>
          <span>{profile?.fullName}</span>
        </h1>
        <div className="employee-dashboard__cards">
          <Card light>
            <Link to="/employees/directory">
              <h5>Employee Directory</h5>
              <div className="employee-dashboard__counter">
                <span>{directoryUsers.length}</span>
                <span>Employees</span>
              </div>
            </Link>
          </Card>
          <Card dark>
            <small>A studio of creatives</small>
            <p>
              We are open, collaborative and use our collective experience, design and technical
              expertise to create meaningful and compelling content for all mediums.
            </p>
          </Card>
        </div>
      </section>
    </SkeletonLoader>
  );
};

EmployeeDashboard.propTypes = {
  profile: PROFILE_TYPE,
  directoryUsers: arrayOf(USER_TYPE).isRequired
};

EmployeeDashboard.defaultProps = {
  profile: {}
};

export default EmployeeDashboard;
