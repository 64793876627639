import { func } from 'prop-types';
import React from 'react';
import { useMatches } from 'react-router-dom';

import { NONE } from '../../../tool/constant';

import './mobile-back-action.scss';

import { ReactComponent as BackIcon } from '../../../icon/chev-left-icon.svg';

export const MobileBackAction = ({ onClick }) => {
  const matches = useMatches();

  const pageNames = matches
    // first get rid of any matches that don't have handle and pageName
    .filter((match) => !!match.handle?.pageName)
    // now map them into an array of elements, passing the page name data to each one
    .map((match) => match.handle.pageName);

  const pageName = pageNames[pageNames.length - 1];

  //We check for a value of 'none' when the parent page name is defined, but we do not want
  // to display the child's page name in a button
  if (!pageName || pageName === NONE) {
    return null;
  }

  return (
    <button id="mobile-back-action" onClick={onClick}>
      <BackIcon />
      <span>{pageName}</span>
    </button>
  );
};

MobileBackAction.propTypes = {
  onClick: func.isRequired
};
