import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useRef } from 'react';

import { SKELETON_ENTITY_PLACEHOLDERS } from '../../../../tool/loader.helper';

import { CATEGORY_TYPE } from '../../../../tool/prop-types';

import { useKudos } from '../../../../hook/useKudos';

import { Button } from '../../../../common/component/button/Button';
import ButtonGroup from '../../../../common/component/button/ButtonGroup';
import { Form } from '../../../../common/component/form/Form';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { SearchOptions } from '../../../../common/component/input/search/options/SearchOptions';
import SkeletonLoader from '../../../../common/component/loader/skeleton/SkeletonLoader';
import { TextArea } from '../../../../common/component/textarea/TextArea';

import CategorySelect from '../../input/CategorySelect';
import GiveKudosTitle from './GiveKudosTitle';

import './give-kudos.scss';

const GiveKudos = ({ categories, userOptions, onSubmit, onCancel, loading }) => {
  const { form, onChange, remaining, errors, disabledCategories } = useKudos();
  const skeletonRef = useRef();

  return (
    <>
      <header>
        <GiveKudosTitle remaining={remaining} loading={loading} />
      </header>
      <section className="give-kudos">
        <Form>
          <FormRow fullWidth>
            <SearchOptions
              name="recipientId"
              label="Select user you want to recognise"
              secondaryLabel="You have recognised"
              options={userOptions}
              value={form.recipientId}
              error={errors?.recipientId}
              required
              buttonAlignEnd
              light
              onChange={onChange}
            />
          </FormRow>
          <FormRow fullWidth>
            <SkeletonLoader loading={loading} elementRef={skeletonRef}>
              <div ref={skeletonRef}>
                <CategorySelect
                  categories={loading ? SKELETON_ENTITY_PLACEHOLDERS : categories}
                  disabledItems={disabledCategories}
                  label="Choose category"
                  value={form.categoryId}
                  error={errors?.categoryId}
                  onSelectionChange={onChange}
                />
              </div>
            </SkeletonLoader>
          </FormRow>
          <label htmlFor="message" className="give-kudos__label">
            Write a message
          </label>
          <FormRow fullWidth>
            <TextArea
              name="message"
              value={form.message}
              error={errors?.message}
              onChange={onChange}
            />
          </FormRow>
          <FormRow fullWidth>
            <ButtonGroup>
              <Button label="Send" onClick={onSubmit} />
              <Button label="Cancel" onClick={() => onCancel()} secondary />
            </ButtonGroup>
          </FormRow>
        </Form>
      </section>
    </>
  );
};

GiveKudos.defaultProps = {
  categories: [],
  loading: false
};

GiveKudos.propTypes = {
  categories: arrayOf(CATEGORY_TYPE),
  userOptions: arrayOf(
    shape({
      label: string,
      value: string
    })
  ).isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  loading: bool
};

export default GiveKudos;
