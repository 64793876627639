import React from 'react';

import KudosCategoryManagementProvider from '../../provider/KudosCategoryManagement.provider';

import KudosManagementDashboard from '../../component/kudos-management/KudosManagementDashboard';
import AppLayout from '../../component/layout/AppLayout';

const KudosManagementDashboardContainer = () => (
  <AppLayout fullWidthBreadcrumb>
    <KudosCategoryManagementProvider>
      <KudosManagementDashboard />
    </KudosCategoryManagementProvider>
  </AppLayout>
);

export default KudosManagementDashboardContainer;
