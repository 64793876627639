import { func } from 'prop-types';
import React from 'react';

import { USER_TYPE } from '../../../../tool/prop-types';

import { Button } from '../../../../common/component/button/Button';

import './remove-user.scss';

const RemoveUser = ({ user, onConfirmClick, onCancelClick }) => {
  return (
    <article className="remove-user">
      <p>
        By removing{' '}
        <span>
          {user?.firstName} {user?.lastName}
        </span>{' '}
        they will no longer have access to the app or Neslo email address. This is a permanent
        action and cannot be undone.
      </p>
      <section className="remove-user__actions">
        <Button label="Confirm" onClick={onConfirmClick} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </section>
    </article>
  );
};

RemoveUser.defaultProps = {
  user: {}
};

RemoveUser.propTypes = {
  user: USER_TYPE,
  onConfirmClick: func,
  onCancelClick: func
};

export default RemoveUser;
