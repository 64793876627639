import { getHttpGetOptions, getHttpPatchOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';

import { API_HOST } from './index';

const getAllNotificationsEndpoint = (id) => `${API_HOST}/v1/users/${id}/notifications`;
const getMarkAllNotificationsAsReadEndpoint = (id) =>
  `${getAllNotificationsEndpoint(id)}/mark-all-read`;

export const getLoadAllNotificationsRequest = (userId) => [
  getAllNotificationsEndpoint(userId),
  getHttpGetOptions(getAuthHeaders())
];

export const getMarkAllNotificationsAsReadRequest = (userId) => [
  getMarkAllNotificationsAsReadEndpoint(userId),
  getHttpPatchOptions(null, getAuthHeaders())
];

export const getInvertReadFlagRequest = (userId, id) => [
  `${getAllNotificationsEndpoint(userId)}/${id}/read/invert`,
  getHttpPatchOptions(null, getAuthHeaders())
];
