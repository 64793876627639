import React, { useState } from 'react';

import { ProgressLoaderContext } from '../context/ProgressLoader.context';

import { ProgressLoader } from '../common/component/loader/progress/ProgressLoader';

const ProgressLoaderProvider = ({ children }) => {
  const [percentage, setPercentage] = useState(0);
  const [busy, setProgressLoaderBusy] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const setBusyState = (arg) => {
    if (arg) {
      setPercentage(0);
    } else {
      setPercentage(100);
    }
    setProgressLoaderBusy(arg);
  };

  const value = {
    setProgressLoaderBusy,
    busy,
    setPercentage,
    percentage,
    setBusyState,
    setFullScreen
  };

  return (
    <ProgressLoaderContext.Provider value={value}>
      {busy && <ProgressLoader fullScreen={fullScreen} />}
      {children}
    </ProgressLoaderContext.Provider>
  );
};

export default ProgressLoaderProvider;
