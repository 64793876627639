import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/utils';

import { loadDirectoryUsers } from '../../state/action/directory.actions';
import { loadCompanyNominations, loadLeaders } from '../../state/action/kudos/kudos.actions';

import { ChannelProvider } from '../../provider/Channel.provider';
import KudosProvider from '../../provider/Kudos.provider';

import KudosDashboard from '../../component/kudos/dashboard/KudosDashboard';
import AppLayout from '../../component/layout/AppLayout';

const KudosContainer = () => {
  const dispatch = useDispatch();

  const { directoryUsers, kudosStateLoading, directoryUsersStateLoading } = useSelector(
    ({ kudos_store, profile_store, directory_store }) => ({
      directoryUsers: directory_store.directoryUsers,
      kudosStateLoading: kudos_store.loading,
      directoryUsersStateLoading: directory_store.loading
    }),
    //Prevent re-render on each useSelector call
    shallowEqual
  );

  const [isLeadersLoading, setIsLeadersLoading] = useState(kudosStateLoading ?? true);
  const [isNominationsLoading, setIsNominationsLoading] = useState(kudosStateLoading ?? true);
  const [isDirectoryUsersLoading, setIsDirectoryUsersLoading] = useState(
    !isEmpty(directoryUsers) ? false : directoryUsersStateLoading ?? true
  );

  const memoizedLoading = useMemo(
    () => isLeadersLoading || isNominationsLoading || isDirectoryUsersLoading,
    [isLeadersLoading, isNominationsLoading, isDirectoryUsersLoading]
  );

  useEffect(() => {
    if (isEmpty(directoryUsers)) {
      dispatch(loadDirectoryUsers(() => setIsDirectoryUsersLoading(false)));
    } else {
      setIsDirectoryUsersLoading(false);
      dispatch(loadLeaders(() => setIsLeadersLoading(false)));
      dispatch(loadCompanyNominations(() => setIsNominationsLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryUsers]);

  return (
    <AppLayout showMobileBackButton={false} showBreadcrumb={false}>
      <ChannelProvider channelName="kudos-general">
        <KudosProvider>
          <KudosDashboard
            loading={memoizedLoading}
            companyNominationsLoading={isNominationsLoading}
          />
        </KudosProvider>
      </ChannelProvider>
    </AppLayout>
  );
};

export default KudosContainer;
