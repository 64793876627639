import { arrayOf, bool, node, oneOfType } from 'prop-types';
import React, { useRef } from 'react';

import { PROFILE_TABS } from '../../../../tool/constant';

import { useProfile } from '../../../../hook/useProfile';

import { Button } from '../../../../common/component/button/Button';
import { Card } from '../../../../common/component/card/Card';
import { Form } from '../../../../common/component/form/Form';
import SkeletonLoader from '../../../../common/component/loader/skeleton/SkeletonLoader';

const PrimaryActionButton = ({ adminEditMode, editMode }) => {
  const { selectedTab, onNextClick, form } = useProfile();

  if (
    adminEditMode &&
    selectedTab !== PROFILE_TABS.PERSONAL_INFO &&
    selectedTab !== PROFILE_TABS.IMAGE_UPLOAD
  ) {
    return;
  }

  return (
    <Button
      disabled={!!form?.messages && !!form.messages[selectedTab]}
      type="submit"
      label={selectedTab === PROFILE_TABS.SOCIAL_LINKS || editMode ? 'Save' : 'Next step'}
      onClick={onNextClick}
    />
  );
};

const ProfileDetailsForm = ({ adminEditMode, editMode, showUndo, children, retrieveLoading }) => {
  const { selectedTab, onUndoChangesClick } = useProfile();
  const skeletonRef = useRef();

  return (
    <>
      <SkeletonLoader loading={retrieveLoading} elementRef={skeletonRef}>
        <div ref={skeletonRef}>
          <Card white borderless>
            <Form>{children}</Form>
          </Card>
        </div>
      </SkeletonLoader>
      {selectedTab === PROFILE_TABS.IMAGE_UPLOAD && (
        <p>
          Disclaimer: Updating your profile image will update your Google profile image. This image
          will reflect on your Google account and will reflect across all usages of your Google
          account.
        </p>
      )}
      <div className="profile-details-section__actions">
        <PrimaryActionButton adminEditMode={adminEditMode} editMode={editMode} />
        {showUndo && (
          <Button type="reset" label="Undo Changes" onClick={onUndoChangesClick} secondary />
        )}
      </div>
    </>
  );
};

ProfileDetailsForm.defaultProps = {
  adminEditMode: false,
  editMode: false,
  showUndo: false,
  retrieveLoading: false
};

ProfileDetailsForm.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  adminEditMode: bool,
  editMode: bool,
  showUndo: bool,
  retrieveLoading: bool
};

export default ProfileDetailsForm;
