import React, { useEffect, useMemo } from 'react';
import { useMatches } from 'react-router-dom';

const AppTitle = ({ children }) => {
  const matches = useMatches();

  const pageTitle = useMemo(() => {
    const pageTitles = matches
      // first get rid of any matches that don't have handle and pageTitle
      .filter((match) => !!match.handle?.pageTitle)
      // now map them into an array of elements, passing the page title data to each one
      .map((match) => match.handle.pageTitle);

    return pageTitles[pageTitles.length - 1];
  }, [matches]);

  useEffect(() => {
    document.title = `${pageTitle} | NESLO®`;
  }, [pageTitle]);

  return <>{children}</>;
};

export default AppTitle;
