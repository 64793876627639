import { string } from 'prop-types';
import React from 'react';

import './table-image.scss';

export const TableImage = ({ image, name }) => {
  return <img className="table-image" referrerPolicy="no-referrer" src={image} alt={name} />;
};

TableImage.propTypes = {
  image: string,
  name: string.isRequired
};
