import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearProject } from '../../../../state/action/scheduling/project.actions';
import { clearTasks } from '../../../../state/action/scheduling/task.actions';

import { CreateProjectDetailsContainer } from '../detail/create/CreateProjectDetails.container';
import { ProjectMembersContainer } from '../member/ProjectMembers.container';
import { TaskListContainer } from '../task/TaskList.container';

import { Stepper } from '../../../../component/stepper/Stepper';

const STEPS = Object.freeze([
  { id: 1, title: 'Project Details', component: CreateProjectDetailsContainer, enabled: true },
  { id: 2, title: 'Project Members', component: ProjectMembersContainer, enabled: false },
  { id: 3, title: 'Project Tasks', component: TaskListContainer, enabled: false }
]);

const AddProjectContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const project = useSelector(({ project_store }) => project_store.project);

  useEffect(() => {
    return () => {
      dispatch(clearProject());
      dispatch(clearTasks());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedSteps = useMemo(() => {
    return STEPS.map((step) => {
      if (step.id === 1) {
        return step;
      }

      return { ...step, enabled: !!project };
    });
  }, [project]);

  const handleComplete = () => {
    dispatch(clearTasks());
    navigate(-1);
  };

  const handleCancel = () => navigate(-1);

  return <Stepper steps={mappedSteps} onCancel={handleCancel} onComplete={handleComplete} />;
};

export default AddProjectContainer;
