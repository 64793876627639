import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { getClassNames, isEmpty, noOp } from '@neslotech/utils';

import { ReactComponent as ChevronIcon } from '../../../../../icon/chevron-icon.svg';

import { Dropdown } from '../../../dropdown/Dropdown';
import { SearchInput } from '../SearchInput';

import './search-options.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

export const SearchOptions = ({
  name,
  options,
  label,
  secondaryLabel,
  value,
  error,
  required = false,
  disabled = false,
  borderless = false,
  buttonAlignEnd = false,
  light = false,
  focused = false,
  onChange = noOp
}) => {
  const memoizedValue = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return option?.label;
  }, [options, value]);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = useCallback(
    (val) => {
      onChange(getChangeValue(name, val));
      setOpen(false);
    },
    [name, onChange]
  );

  const memoizedOptions = useMemo(
    () =>
      options
        .filter((option) =>
          isEmpty(searchValue)
            ? true
            : option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map(({ label, value: optionValue }) => ({
          text: (
            <span className={getClassNames('search-options__option', { end: buttonAlignEnd })}>
              <div
                className={getClassNames('search-options__option-ellipse', {
                  checked: optionValue === value
                })}
              />
              <span>{label}</span>
            </span>
          ),
          onClick: () => handleChange(optionValue)
        })),
    [options, value, buttonAlignEnd, searchValue, handleChange]
  );

  return (
    <div className={getClassNames('search-options', { open, light })}>
      <Dropdown
        expandable={disabled}
        menuItems={memoizedOptions}
        placement="bottom-start"
        sameWidth
      >
        <SearchInput
          id={name}
          name={name}
          label={label}
          secondaryLabel={secondaryLabel}
          value={memoizedValue}
          error={error}
          onFocus={() => setOpen(true)}
          action={<ChevronIcon />}
          onActionClick={() => setOpen(!open)}
          onChange={onChange}
          onSearchChange={(val) => setSearchValue(val)}
          searchValue={searchValue}
          borderless={borderless}
          required={required}
          light={light}
          focused={focused}
        />
      </Dropdown>
    </div>
  );
};

SearchOptions.propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string, object, bool])
    })
  ).isRequired,
  label: string,
  secondaryLabel: string,
  value: oneOfType([number, string, object, bool]),
  error: string,
  required: bool,
  disabled: bool,
  borderless: bool,
  buttonAlignEnd: bool,
  onChange: func,
  focused: bool
};
