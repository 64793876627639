import { SET_DIRECTORY_USERS } from '../action/directory.actions';

export const initialState = {
  directoryUsers: []
};

export function directoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DIRECTORY_USERS:
      const { directoryUsers } = action;
      return {
        ...state,
        directoryUsers
      };
    default:
      return state;
  }
}
