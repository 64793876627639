import { bool } from 'prop-types';
import React, { useRef } from 'react';

import { KUDOS_MOBILE_TAB_OPTIONS, KUDOS_TABS, KUDOS_WEB_TAB_OPTIONS } from './kudos.helper';

import { useKudos } from '../../../hook/useKudos';

import GiveKudosContainer from '../../../container/kudos/management/give-kudos/GiveKudos.container';

import { Button } from '../../../common/component/button/Button';
import SkeletonLoader from '../../../common/component/loader/skeleton/SkeletonLoader';
import ScrollIndicator from '../../../common/component/scroll/ScrollIndicator';

import Tabs from '../../tabs/Tabs';
import KudosSidebar from '../sidebar/KudosSidebar';
import KudosTimeline from '../timeline/KudosTimeline';

import './kudos-dashboard.scss';

const KudosTimelineWrapper = ({ loading, companyNominationsLoading }) => {
  const {
    selectedTab,
    personalNominations,
    companyNominations,
    loadingPersonalNominations,
    setIsModalOpen
  } = useKudos();

  switch (selectedTab) {
    case KUDOS_TABS.KUDOS_CARDS:
      return (
        <SkeletonLoader loading={loading}>
          <KudosSidebar />
        </SkeletonLoader>
      );
    case KUDOS_TABS.COMPANY_TIMELINE:
      return (
        <KudosTimeline
          nominations={companyNominations}
          onGiveKudosClick={() => setIsModalOpen(true)}
          loading={companyNominationsLoading}
        />
      );
    case KUDOS_TABS.PERSONAL_TIMELINE:
      return (
        <KudosTimeline
          nominations={personalNominations}
          onGiveKudosClick={() => setIsModalOpen(true)}
          loading={loadingPersonalNominations}
        />
      );
    default:
      throw Error(`Unknown tab value selected: ${selectedTab}`);
  }
};

const KudosDashboard = ({ loading, companyNominationsLoading }) => {
  const { mobile, selectedTab, setSelectedTab, isModalOpen, setIsModalOpen } = useKudos();
  const skeletonRef = useRef();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!mobile) {
    return (
      <>
        <SkeletonLoader loading={loading} elementRef={skeletonRef}>
          <section className="kudos-dashboard" ref={skeletonRef}>
            <aside className="kudos-dashboard__sidebar">
              <Button label="Give Kudos" onClick={handleOpenModal} />
              <KudosSidebar />
            </aside>
            <div className="kudos-dashboard__scroll">
              <ScrollIndicator />
            </div>
            <div className="kudos-dashboard__timeline">
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                hideFirst
                tabs={KUDOS_WEB_TAB_OPTIONS}
              />
              <KudosTimelineWrapper />
            </div>
          </section>
        </SkeletonLoader>
        {isModalOpen && <GiveKudosContainer onClose={handleCloseModal} />}
      </>
    );
  }

  if (isModalOpen) {
    return <GiveKudosContainer onClose={handleCloseModal} />;
  }

  return (
    <section className="kudos-dashboard">
      <header className="kudos-dashboard__mobile-header">
        <Tabs
          tabs={KUDOS_MOBILE_TAB_OPTIONS}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Button label="Give Kudos" onClick={handleOpenModal} />
      </header>
      <div className="kudos-dashboard__mobile-timeline">
        <KudosTimelineWrapper
          companyNominationsLoading={companyNominationsLoading}
          loading={loading}
        />
      </div>
    </section>
  );
};

KudosDashboard.propTypes = {
  loading: bool,
  companyNominationsLoading: bool
};

KudosDashboard.defaultProps = {
  loading: true,
  companyNominationsLoading: true
};

export default KudosDashboard;
