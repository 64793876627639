import { func, string } from 'prop-types';

import { ReactComponent as SmallX } from '../../../icon/close-icon.svg';

import './tag.scss';

export const Tag = ({ text, onClick }) => (
  <section className="tag">
    <p>{text.toUpperCase()}</p>
    <SmallX onClick={onClick} />
  </section>
);

Tag.propTypes = {
  text: string.isRequired,
  onClick: func
};
