import { arrayOf, bool } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty, noOp } from '@neslotech/utils';

import { getSkeletonPlaceholders } from '../../../tool/loader.helper';
import {
  USER_MANAGEMENT_FILTER_OPTIONS,
  USER_MAN_COLS,
  mapRowData,
  mapUsers
} from './user-management.helper';

import { ROLE_OPTIONS } from '../../../tool/constant';
import { USER_TYPE } from '../../../tool/prop-types';

import { useDevice } from '../../../hook/useDevice';
import { useFilters } from '../../../hook/useFilter';

import AddUserContainer from '../../../container/user/management/add/AddUser.container';
import ChangeRoleContainer from '../../../container/user/management/change-role/ChangeRole.container';
import RemoveUserContainer from '../../../container/user/management/remove/RemoveUser.container';

import { Button } from '../../../common/component/button/Button';
import { MobileCard } from '../../../common/component/card/MobileCard';
import SkeletonLoader from '../../../common/component/loader/skeleton/SkeletonLoader';
import NoResults from '../../../common/component/no-results/NoResults';
import { Select } from '../../../common/component/select/Select';
import { Table } from '../../../common/component/table/Table';

import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';
import { ReactComponent as TrashIcon } from './../../../icon/trash-icon.svg';

import { FilterLayout } from '../../../common/layout/filter/FilterLayout';

import './user-management.scss';

const UsersTable = ({ users, setUserToRemove, setUserToChange, loading }) => {
  const navigate = useNavigate();

  const { search, filters, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(USER_MANAGEMENT_FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [USER_MANAGEMENT_FILTER_OPTIONS]);

  return (
    <section className="user-management__table">
      <Table
        cols={USER_MAN_COLS}
        rowData={mapRowData(
          users,
          navigate,
          search,
          filters,
          setUserToRemove,
          setUserToChange,
          loading
        )}
        emptyTitle="There are no results found"
        emptySubtitle={
          filters
            ? 'Try removing some filters to see more results'
            : 'Try editing your search to see more results'
        }
        loading={loading}
      />
    </section>
  );
};

const UserMobileCards = ({ users, setUserToRemove, setUserToChange, onAddEmployee, loading }) => {
  const navigate = useNavigate();

  const skeletonRef = useRef();

  const { search, filters, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(USER_MANAGEMENT_FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [USER_MANAGEMENT_FILTER_OPTIONS]);

  const filteredUsers = useMemo(() => {
    if (loading) {
      return getSkeletonPlaceholders(['id'], 5);
    }

    return mapUsers(users, search, filters);
  }, [users, search, filters, loading]);

  return (
    <>
      {!isEmpty(filteredUsers) && (
        <SkeletonLoader loading={loading} elementRef={skeletonRef}>
          <section className="user-management-mobile-cards" ref={skeletonRef}>
            {filteredUsers.map((user) => (
              <MobileCard
                key={user.id}
                onClick={
                  user?.status === 'Inactive'
                    ? noOp()
                    : () =>
                        navigate(`/user-management/${user.id}/${user.firstName} ${user.lastName}`)
                }
              >
                <img
                  referrerPolicy="no-referrer"
                  src={user?.image ?? user?.defaultImage}
                  alt={`${user?.firstName} ${user?.lastName}`}
                />
                <section className="user-management-mobile-cards__wrapper">
                  <p>
                    {user?.firstName}&nbsp;{user?.lastName}
                  </p>
                  <Select
                    options={ROLE_OPTIONS}
                    onChange={(role) => setUserToChange({ ...user, role })}
                    value={user?.role}
                    borderless
                    small
                  />
                </section>
                <section className="user-management__mobile-icon-wrapper">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setUserToRemove(user);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </section>
              </MobileCard>
            ))}
          </section>
        </SkeletonLoader>
      )}
      {isEmpty(filteredUsers) && !loading && (
        <NoResults
          emptyTitle="There were no results found"
          emptySubtitle={
            filters
              ? 'Try removing some filters to see more results'
              : 'Try editing your search to see more results'
          }
        />
      )}
      <div className="user-management__mobile-action">
        <Button label={<PlusIcon />} onClick={onAddEmployee} />
      </div>
    </>
  );
};

const UserManagement = ({ users, loading }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [userToChange, setUserToChange] = useState(undefined);
  const [userToRemove, setUserToRemove] = useState(undefined);
  const navigate = useNavigate();

  const { mobile } = useDevice();

  const handleAddNewEmployee = () => {
    if (mobile) {
      navigate('add');
      return;
    }

    setShowAdd(true);
  };

  return (
    <>
      <article className="user-management">
        <FilterLayout
          label="Search for something"
          action={<Button label="Add new employee" onClick={handleAddNewEmployee} />}
        >
          {mobile ? (
            <UserMobileCards
              users={users}
              setUserToRemove={setUserToRemove}
              setUserToChange={setUserToChange}
              onAddEmployee={handleAddNewEmployee}
              loading={loading}
            />
          ) : (
            <UsersTable
              users={users}
              setUserToRemove={setUserToRemove}
              setUserToChange={setUserToChange}
              loading={loading}
            />
          )}
        </FilterLayout>
      </article>
      <AddUserContainer open={showAdd} onClose={() => setShowAdd(false)} />
      {userToRemove && (
        <RemoveUserContainer onClose={() => setUserToRemove(undefined)} user={userToRemove} />
      )}
      {userToChange && (
        <ChangeRoleContainer user={userToChange} onClose={() => setUserToChange(undefined)} />
      )}
    </>
  );
};

UserManagement.propTypes = {
  users: arrayOf(USER_TYPE),
  loading: bool
};

UserManagement.defaultProps = {
  users: [],
  loading: false
};

export default UserManagement;
