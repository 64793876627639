import { getHttpPostOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../../../../tool/auth.util';

import { API_HOST } from '../index';

const reportEndpoint = (nominationId) => `${API_HOST}/v1/kudos/nominations/${nominationId}/reports`;

export const getCreateReportRequest = (nominationId, payload) => [
  reportEndpoint(nominationId),
  getHttpPostOptions(payload, getAuthHeaders())
];
