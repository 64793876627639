import { arrayOf, bool, func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { noOp } from '@neslotech/utils';

import { filterElement, searchElement } from '../../../tool/filter.util';
import { CLIENT_TYPE, PROJECT_TYPE } from '../../../tool/prop-types';

import { useFilters } from '../../../hook/useFilter';

import { Button } from '../../../common/component/button/Button';
import { Table } from '../../../common/component/table/Table';

import { ReactComponent as ArchiveIcon } from '../../../icon/archive-icon.svg';
import { ReactComponent as ReinstateIcon } from '../../../icon/redo-icon.svg';

import { FilterLayout } from '../../../common/layout/filter/FilterLayout';
import Dialog from '../../../common/layout/portal/Dialog';
import Blip from '../../blip/Blip';

import './project-list.scss';

const TABLE_COLUMNS = [
  { Header: 'Project Name', accessor: 'name' },
  { Header: 'Client', accessor: 'client' },
  { Header: 'Colour', accessor: 'colour' },
  { Header: 'Budget', accessor: 'budget' },
  { Header: 'Start Date', accessor: 'startDate' },
  { Header: 'End Date', accessor: 'endDate' },
  { Header: '', accessor: 'actions', disableSortBy: true }
];

const DEFAULT_FILTERS = Object.freeze({ type: { filterValues: ['Active'] } });

const getFilterOptions = (clients = []) => [
  {
    name: 'Client',
    active: false,
    singleSelect: true,
    values: clients.map((client) => ({ value: client.name, selected: false }))
  },
  {
    name: 'Type',
    active: false,
    singleSelect: true,
    values: [
      { value: 'Archived', selected: false },
      { value: 'Active', selected: true }
    ]
  }
];

const ProjectTable = ({ loading, projects, clients, onArchive, onReinstate }) => {
  const navigate = useNavigate();
  const { filters, search, setDefaultOptions, setFilters } = useFilters();

  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    setDefaultOptions(getFilterOptions(clients));
    setFilters(DEFAULT_FILTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  const rowData = useMemo(() => {
    const handleAction = (event, project) => {
      event.stopPropagation();
      if (project.deleted) {
        onReinstate(project.id);
        return;
      }

      setSelectedProject(project);
    };

    return projects
      .map((project) => ({
        name: project.name,
        client: project.client.name,
        colour: <Blip large colour={project.colour} />,
        budget: project.budget,
        startDate: project.startDate,
        endDate: project.endDate,
        actions: (
          <button onClick={(event) => handleAction(event, project)}>
            {project.deleted ? <ReinstateIcon /> : <ArchiveIcon />}
          </button>
        ),
        type: project.deleted ? 'Archived' : 'Active',
        onClick: project.deleted ? noOp : () => navigate(`${project.id}/edit`)
      }))
      .filter((project) => filterElement(project, filters))
      .filter(
        (project) =>
          searchElement(project.name, search) || searchElement(project.client.name, search)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, filters, search]);

  return (
    <div className="project-list__table">
      <Table
        cols={TABLE_COLUMNS}
        rowData={rowData}
        emptyTitle="You have no projects"
        emptySubtitle="Try adding your first project to the list"
        loading={loading}
      />

      {selectedProject && (
        <Dialog
          heading="Are you sure?"
          message={
            <span>
              Are you sure you want to archive <strong>{selectedProject.name}</strong>? <br />
              This action can be undone by reinstating the project.
            </span>
          }
          isOpen
          primaryText="Yes"
          secondaryText="No"
          onSecondaryClick={() => setSelectedProject(null)}
          onClose={() => setSelectedProject(null)}
          onPrimaryClick={() => onArchive(selectedProject.id, () => setSelectedProject(null))}
        />
      )}
    </div>
  );
};

export const ProjectList = ({
  loading = false,
  projects = [],
  clients = [],
  onArchive,
  onReinstate
}) => {
  const navigate = useNavigate();

  return (
    <section className="project-list">
      <FilterLayout
        label="Search for something"
        action={<Button label="Add New Project" onClick={() => navigate('add')} />}
      >
        <ProjectTable
          loading={loading}
          projects={projects}
          clients={clients}
          onArchive={onArchive}
          onReinstate={onReinstate}
        />
      </FilterLayout>
    </section>
  );
};

ProjectList.propTypes = {
  loading: bool,
  projects: arrayOf(PROJECT_TYPE),
  clients: arrayOf(CLIENT_TYPE),
  onArchive: func.isRequired,
  onReinstate: func.isRequired
};
