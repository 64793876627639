import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { filterElement, searchElement } from '../../../../tool/filter.util';
import { CHECKBOX_TABLE_USER_TYPE, USER_TYPE } from '../../../../tool/prop-types';

import { FormAction } from '../../../../common/component/form/form-action/FormAction';

import { useFilters } from '../../../../hook/useFilter';

import { FilterLayout } from '../../../../common/layout/filter/FilterLayout';
import UserCheckboxTable from '../../checkbox/table/UserCheckboxTable';

import './project-member-selection.scss';

const FILTER_OPTIONS = Object.freeze([
  {
    name: 'Department',
    active: false,
    singleSelect: true,
    values: [
      { value: 'Engineers', selected: false },
      { value: 'Administration', selected: false },
      { value: 'Executives', selected: false },
      { value: 'Designers', selected: false }
    ]
  }
]);

const CheckboxTableFilter = ({ rows, loading, selectedRowsChanged }) => {
  const { filters, search, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredRows = useMemo(() => {
    return rows
      .filter((row) => filterElement(row, filters))
      .filter(
        (row) =>
          searchElement(row.firstName, search) ||
          searchElement(row.lastName, search) ||
          searchElement(row.department, search)
      );
  }, [rows, filters, search]);

  return (
    <UserCheckboxTable
      rows={filteredRows}
      loading={loading}
      selectedRowsChanged={selectedRowsChanged}
    />
  );
};

CheckboxTableFilter.propTypes = {
  rows: arrayOf(CHECKBOX_TABLE_USER_TYPE),
  loading: bool,
  selectedRowsChanged: func
};

const ProjectMemberSelection = ({ users, existingMembers, loading = false, onBack, onSubmit }) => {
  const [selectedRows, setSelectedRows] = useState(existingMembers);
  const [submitLoading, setLoading] = useState(false);

  const rows = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      firstName: user.person.firstName,
      lastName: user.person.lastName,
      department: user.person.department,
      checked: existingMembers.some((member) => member.userId === user.id)
    }));
  }, [users, existingMembers]);

  const selectedRowsChanged = (newRows) => setSelectedRows(newRows);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(
      selectedRows.map((userRow) => userRow.id),
      () => setLoading(false)
    );
  };

  return (
    <section id="project-member-selection">
      <FilterLayout label="Search for something">
        <CheckboxTableFilter
          rows={rows}
          loading={loading}
          selectedRowsChanged={selectedRowsChanged}
        />
        <div id="project-member-selection__actions">
          <FormAction label="Back" secondary onClick={onBack} />
          <FormAction label="Next" onClick={handleSubmit} loading={submitLoading} />
        </div>
      </FilterLayout>
    </section>
  );
};

ProjectMemberSelection.propTypes = {
  users: arrayOf(USER_TYPE).isRequired,
  existingMembers: arrayOf(shape({ userId: string.isRequired })).isRequired,
  loading: bool,
  onBack: func.isRequired,
  onSubmit: func.isRequired
};

export default ProjectMemberSelection;
