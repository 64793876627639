import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RESPONSE_STATUS } from '../tool/constant';

import { logout } from '../state/action/auth.actions';

export const useAxiosInterceptor = (navigate) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        const status = error.response.status;
        const skipForbidden = error.config?.skipForbidden;

        if (status === RESPONSE_STATUS.UNAUTHORIZED) {
          dispatch(logout(navigate));
        } else if (status === RESPONSE_STATUS.FORBIDDEN && !skipForbidden) {
          navigate('/dashboard');
        }

        return Promise.reject(error);
      }
    );

    // Cleans up the interceptor when component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};
