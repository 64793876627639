import { bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { isValuePresent } from '@neslotech/utils';

import { FormAction } from '../../../../common/component/form/form-action/FormAction';

import { Form } from '../../../../common/component/form/Form';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';
import ColourSelect from '../../../kudos-management/input/ColourSelect';

import './leave-category-form.scss';

const SELECT_COLOURS = [
  { colour: '#FF8080' },
  { colour: '#FFCF96' },
  { colour: '#8B93FF' },
  { colour: '#AEDEFC' },
  { colour: '#59CE8F' }
];

const SELECT_COLOUR_DEFAULT = { colour: '#CECECE' };

const REQUIRED_FIELDS = Object.freeze(['name', 'colour']);

const formify = (category) => {
  return {
    name: category?.name ?? '',
    colour: category?.colour ?? ''
  };
};

export const LeaveCategoryForm = ({ onSubmit, onClose, category = {}, isEditMode = false }) => {
  const [form, setForm] = useState(formify(category));

  const isSubmitDisabled = useMemo(() => {
    return REQUIRED_FIELDS.some((field) => !isValuePresent(form[field]));
  }, [form]);

  const handleColourChange = (colour) => {
    handleInputChange({ colour });
  };

  const handleInputChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  return (
    <Modal className="leave-category-form">
      <ModalLayout
        title={isEditMode ? 'Edit Time Off Category' : 'Add a new time off category'}
        onClose={onClose}
      >
        <Form>
          <FormRow>
            <Input
              label="Category Name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
              required
            />
          </FormRow>
          <FormRow>
            <ColourSelect
              label="Category Colours"
              name="colour"
              colours={SELECT_COLOURS}
              colourPickerButtonColour={SELECT_COLOUR_DEFAULT}
              showColourPicker
              value={form.colour}
              onSelectionChange={handleColourChange}
              customColour={isEditMode ? form.colour : ''}
              required
            />
          </FormRow>
          <div className="leave-category-form__actions">
            <FormAction label="Save" disabled={isSubmitDisabled} onClick={() => onSubmit(form)} />
            <FormAction label="Cancel" secondary onClick={onClose} />
          </div>
        </Form>
      </ModalLayout>
    </Modal>
  );
};

LeaveCategoryForm.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  isEditMode: bool,
  category: shape({
    name: string,
    colour: string
  })
};
