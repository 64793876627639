import { bool } from 'prop-types';

import { getClassNames } from '@neslotech/utils';

import './scroll-indicator.scss';

const ScrollIndicator = ({ small }) => {
  return (
    <div className="scroll-indicator">
      <div className={getClassNames('scroll-indicator__label', { small })}>
        <span>Scroll</span>
      </div>
      <span />
    </div>
  );
};

ScrollIndicator.defaultProps = {
  small: false
};

ScrollIndicator.propTypes = {
  small: bool
};

export default ScrollIndicator;
