import { useState } from 'react';

import { Card } from '../../../common/component/card/Card';
import PieChart from '../../../common/component/chart/PieChart';

import Blip from '../../blip/Blip';

import './popular-categories-card.scss';

const PopularCategoriesCard = ({ segments }) => {
  const [selection, setSelection] = useState(null);

  const setSelectedCategory = (id) => {
    setSelection(id);
  };

  return (
    <section className="popular-categories-card">
      <Card transparent>
        <header className="popular-categories-card__header">
          <h1>
            Most Popular <br />
            Categories this month.
          </h1>
        </header>
        <div className="popular-categories-card__content">
          <div className="popular-categories-card__categories">
            {segments.map((item) => (
              <div
                key={`blip_${item.id}`}
                className={
                  selection === item.id ? 'popular-categories-card__categories--active' : ''
                }
              >
                <Blip testId={item.id} label={item.name} colour={item.color} rectangular />
              </div>
            ))}
          </div>
          <div className="popular-categories-card__chart">
            <PieChart
              segments={segments}
              innerRadiusPercentage={0.5}
              onItemHover={(id) => setSelectedCategory(id)}
            />
          </div>
        </div>
      </Card>
    </section>
  );
};

export default PopularCategoriesCard;
