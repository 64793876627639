import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';

import { API_HOST } from './index';

const getAuthEndpoint = `${API_HOST}/v1/auth`;

const getTokenVerificationEndpoint = () => `${getAuthEndpoint}/verify`;
export const getTokenVerificationRequest = () => [
  getTokenVerificationEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
