import {
  ADD_REALTIME_TASK,
  CLEAR_TASKS,
  REMOVE_REALTIME_TASK,
  SET_TASKS
} from '../../action/scheduling/task.actions';

export const initialState = {
  tasks: []
};

export function taskReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_REALTIME_TASK: {
      const { event } = action;
      return {
        ...state,
        tasks: [{ id: event.taskId, title: event.title }, ...state.tasks]
      };
    }
    case REMOVE_REALTIME_TASK: {
      const { event } = action;

      const filteredTasks = state.tasks.filter((task) => task.id !== event.taskId);
      return {
        ...state,
        tasks: filteredTasks
      };
    }
    case CLEAR_TASKS: {
      return {
        ...state,
        tasks: []
      };
    }
    case SET_TASKS:
      const { tasks } = action;
      return {
        ...state,
        tasks
      };
    default:
      return state;
  }
}
