import {
  getHttpGetOptions,
  getHttpPatchOptions,
  getHttpPostOptions,
  getHttpPutOptions,
  isEmpty
} from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import { PROFILE_TABS, SOCIAL_TYPE } from '../../../tool/constant';

import { API_HOST } from './index';

const getUserEndpoint = (id) => `${API_HOST}/v1/users/${id}`;

const getEmployeeDetailsEndpoint = (id) => `${getUserEndpoint(id)}/employee-details`;
const getAddressesEndpoint = (id) => `${getUserEndpoint(id)}/addresses`;
const getAddressEndpoint = (userId, id) => `${getUserEndpoint(userId)}/addresses/${id}`;
const getBankDetailsEndpoint = (id) => `${getUserEndpoint(id)}/bank-details`;
const getBankDetailEndpoint = (userId, id) => `${getUserEndpoint(userId)}/bank-details/${id}`;
const getEmergencyDetailsEndpoint = (id) => `${getUserEndpoint(id)}/emergency-details`;
const getSocialsEndpoint = (id) => `${getUserEndpoint(id)}/socials`;
const getEmergencyDetailEndpoint = (userId, id) =>
  `${getUserEndpoint(userId)}/emergency-details/${id}`;
const getProfileImageEndpoint = (id) => `${getUserEndpoint(id)}/profile-image`;

const serverifyPersonalInfo = (personalInfo) => ({
  type: 'EmployeeDetailsRequest',
  firstName: personalInfo.firstName,
  secondName: personalInfo.secondName,
  lastName: personalInfo.lastName,
  initials: personalInfo.initials,
  gender: personalInfo.gender,
  dateOfBirth: personalInfo.birthday,
  jobTitle: personalInfo.jobTitle,
  idNumber: personalInfo.identityNumber,
  taxNumber: personalInfo.taxNumber,
  passportNumber: personalInfo.passportNumber,
  passportCountry: personalInfo.passportCountry,
  personalBio: personalInfo.bio,
  department: personalInfo.department,
  lineManagerId: personalInfo.lineManager,
  alternateEmailAddress: personalInfo.alternativeEmail,
  phoneNumber: personalInfo.phoneNumber
});

const serverifyImageUpload = (info) => {
  let value;
  const image = info.image;
  if (!!image) {
    value = image.includes(',') ? image.split(',')[1] : image;
  } else {
    value = info.defaultImage;
  }

  return {
    image: value
  };
};

const getSocialType = (key) => {
  switch (key) {
    case 'linkedIn':
      return SOCIAL_TYPE.LINKED_IN;
    case 'behance':
      return SOCIAL_TYPE.BEHANCE;
    case 'github':
      return SOCIAL_TYPE.GIT_HUB;
    default:
      throw new Error('Unknown key value provided for social type');
  }
};

const serverifySocialLinks = (info) =>
  Object.keys(info)
    .filter((key) => !isEmpty(info[key]))
    .map((key) => ({
      type: getSocialType(key),
      link: info[key]
    }));

export const getSaveProfileInfoRequest = (id, info, key) => {
  switch (key) {
    case PROFILE_TABS.PERSONAL_INFO: {
      const payload = serverifyPersonalInfo(info[PROFILE_TABS.PERSONAL_INFO]);
      return [getEmployeeDetailsEndpoint(id), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.PHYSICAL_ADDRESS: {
      const payload = info[PROFILE_TABS.PHYSICAL_ADDRESS] ?? {};
      return [getAddressesEndpoint(id), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.BANK_DETAILS: {
      const payload = info[PROFILE_TABS.BANK_DETAILS] ?? {};
      return [getBankDetailsEndpoint(id), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.EMERGENCY_CONTACT: {
      const payload = info[PROFILE_TABS.EMERGENCY_CONTACT] ?? {};
      return [getEmergencyDetailsEndpoint(id), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.SOCIAL_LINKS: {
      const payload = serverifySocialLinks(info[PROFILE_TABS.SOCIAL_LINKS] ?? {});
      return [getSocialsEndpoint(id), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.IMAGE_UPLOAD: {
      const payload = serverifyImageUpload(info);
      return [getProfileImageEndpoint(id), getHttpPatchOptions(payload, getAuthHeaders())];
    }
    default:
      throw Error('Unknown selected tab type');
  }
};

export const getUpdateProfileInfoRequest = (userId, info, key) => {
  switch (key) {
    case PROFILE_TABS.PERSONAL_INFO: {
      const payload = serverifyPersonalInfo(info[PROFILE_TABS.PERSONAL_INFO]);
      return [getEmployeeDetailsEndpoint(userId), getHttpPostOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.PHYSICAL_ADDRESS: {
      const payload = info[PROFILE_TABS.PHYSICAL_ADDRESS] ?? {};
      return [getAddressEndpoint(userId, payload.id), getHttpPutOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.BANK_DETAILS: {
      const payload = info[PROFILE_TABS.BANK_DETAILS] ?? {};
      return [
        getBankDetailEndpoint(userId, payload.id),
        getHttpPutOptions(payload, getAuthHeaders())
      ];
    }
    case PROFILE_TABS.EMERGENCY_CONTACT: {
      const payload = info[PROFILE_TABS.EMERGENCY_CONTACT];
      return [
        getEmergencyDetailEndpoint(userId, payload.id) ?? {},
        getHttpPutOptions(payload, getAuthHeaders())
      ];
    }
    case PROFILE_TABS.SOCIAL_LINKS: {
      const payload = serverifySocialLinks(info[PROFILE_TABS.SOCIAL_LINKS] ?? {});
      return [getSocialsEndpoint(userId), getHttpPutOptions(payload, getAuthHeaders())];
    }
    case PROFILE_TABS.IMAGE_UPLOAD: {
      const payload = serverifyImageUpload(info);
      return [getProfileImageEndpoint(userId), getHttpPatchOptions(payload, getAuthHeaders())];
    }
    default:
      throw Error('Unknown selected tab type');
  }
};

const getActivateProfileEndpoint = (id) => `${getUserEndpoint(id)}/activate`;
export const getActivateProfileRequest = (id) => [
  getActivateProfileEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getBasicProfileEndpoint = (id) => `${getUserEndpoint(id)}/basic-profile`;
export const getLoadBasicProfileRequest = (id) => [
  getBasicProfileEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getLoadProfileRequest = (id) => [
  getUserEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];
