import { mapNominationComment } from '../../../tool/kudos.util';

import {
  SET_NOMINATION_COMMENTS,
  SET_NOMINATION_COMMENT_REPLIES,
  SET_REALTIME_NOMINATION_COMMENT,
  SET_REALTIME_NOMINATION_COMMENT_REMOVE,
  SET_REALTIME_NOMINATION_COMMENT_UPDATE,
  SET_REALTIME_NOMINATION_REPLY
} from '../../action/kudos/comment.actions';
import {
  ADD_REALTIME_NOMINATION,
  ADD_REALTIME_NOMINATION_DELETE,
  ADD_REALTIME_NOMINATION_MESSAGE_REMOVE,
  ADD_REALTIME_PERSONAL_NOMINATION,
  ADD_REALTIME_POINTS,
  CLEAR_KUDOS_ERRORS,
  SET_ALL_TIME_POINTS,
  SET_CATEGORIES,
  SET_COMPANY_NOMINATIONS,
  SET_KUDOS_ERRORS,
  SET_LEADERS,
  SET_MOST_FREQUENT_CATEGORIES,
  SET_MOST_POPULAR_CATEGORIES,
  SET_PERSONAL_NOMINATIONS,
  SET_REALTIME_LEADERS,
  SET_REALTIME_MOST_FREQUENT_CATEGORIES,
  SET_REALTIME_NOMINATION_LIKE,
  SET_REALTIME_POPULAR_CATEGORIES,
  SET_REALTIME_REMAINING_POINTS,
  SET_REDEEMABLE_POINTS,
  SET_REMAINING_POINTS
} from '../../action/kudos/kudos.actions';

const initialCategories = [
  {
    frequency: null,
    category: {
      id: '1',
      name: 'loading...',
      colorCode: '#d9d9d9',
      textColorCode: '#000'
    }
  },
  {
    frequency: null,
    category: {
      id: '2',
      name: 'loading...',
      colorCode: '#d9d9d9',
      textColorCode: '#000'
    }
  },
  {
    frequency: null,
    category: {
      id: '3',
      name: 'loading...',
      colorCode: '#d9d9d9',
      textColorCode: '#000'
    }
  }
];

// TODO Look at initial state once loading designs are finalized.
export const initialState = {
  categories: [],
  frequentCategories: [...initialCategories],
  remainingPoints: 0,
  redeemablePoints: 0,
  allTimePoints: 0,
  leaders: [],
  companyNominations: [],
  personalNominations: [],
  popularCategories: [...initialCategories],
  errors: undefined,
  nominationComments: [],
  commentReplies: []
};

export function kudosReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MOST_FREQUENT_CATEGORIES:
      const { frequentCategories } = action;
      return {
        ...state,
        frequentCategories
      };
    case SET_REALTIME_MOST_FREQUENT_CATEGORIES: {
      const { categories } = action;
      return {
        ...state,
        frequentCategories: categories
      };
    }
    case SET_REMAINING_POINTS:
    case SET_REALTIME_REMAINING_POINTS:
      const { remainingPoints } = action;
      return {
        ...state,
        remainingPoints
      };
    case SET_REDEEMABLE_POINTS:
      const { redeemablePoints } = action;
      return {
        ...state,
        redeemablePoints
      };
    case ADD_REALTIME_POINTS:
      const { pointsEvent } = action;
      return {
        ...state,
        redeemablePoints: state.redeemablePoints + pointsEvent.points,
        allTimePoints: state.allTimePoints + pointsEvent.points
      };
    case ADD_REALTIME_PERSONAL_NOMINATION: {
      const { nomination } = action;
      return {
        ...state,
        personalNominations: [nomination, ...state.personalNominations]
      };
    }
    case ADD_REALTIME_NOMINATION: {
      const { nomination } = action;
      return {
        ...state,
        companyNominations: [nomination, ...state.companyNominations]
      };
    }
    case ADD_REALTIME_NOMINATION_MESSAGE_REMOVE: {
      const { nominationEvent } = action;

      const updateNomination = (nomination) => {
        if (nomination.id === nominationEvent.nominationId) {
          nomination.message = nominationEvent.message;
        }
      };

      const companyNominations = [...state.companyNominations];
      companyNominations.forEach(updateNomination);

      const personalNominations = [...state.personalNominations];
      personalNominations.forEach(updateNomination);

      return {
        ...state,
        companyNominations: [...companyNominations],
        personalNominations: [...personalNominations]
      };
    }
    case ADD_REALTIME_NOMINATION_DELETE: {
      const { nominationEvent } = action;

      const companyNominations = [...state.companyNominations].filter(
        (nomination) => nomination.id !== nominationEvent.nominationId
      );
      const personalNominations = [...state.personalNominations].filter(
        (nomination) => nomination.id !== nominationEvent.nominationId
      );

      return {
        ...state,
        companyNominations: [...companyNominations],
        personalNominations: [...personalNominations]
      };
    }
    case SET_ALL_TIME_POINTS:
      const { allTimePoints } = action;
      return {
        ...state,
        allTimePoints
      };
    case SET_LEADERS:
    case SET_REALTIME_LEADERS:
      const { leaders } = action;
      return {
        ...state,
        leaders
      };
    case SET_COMPANY_NOMINATIONS:
      const { companyNominations } = action;
      return {
        ...state,
        companyNominations
      };
    case SET_MOST_POPULAR_CATEGORIES:
      const { popularCategories } = action;
      return {
        ...state,
        popularCategories
      };
    case SET_REALTIME_POPULAR_CATEGORIES: {
      const { categories } = action;
      return {
        ...state,
        popularCategories: categories
      };
    }
    case SET_REALTIME_NOMINATION_LIKE: {
      const { profileId, likeEvent } = action;
      const shouldSetLiked = profileId === likeEvent.likerId;

      const updateNomination = (nomination) => {
        if (nomination.id === likeEvent.nominationId) {
          nomination.totalLikes = likeEvent.totalLikes;
          if (shouldSetLiked) {
            nomination.liked = likeEvent.liked;
          }
        }
      };

      const companyNominations = [...state.companyNominations];
      companyNominations.forEach((nomination) => updateNomination(nomination));
      const personalNominations = [...state.personalNominations];
      personalNominations.forEach((nomination) => updateNomination(nomination));

      return {
        ...state,
        companyNominations: [...companyNominations],
        personalNominations: [...personalNominations]
      };
    }
    case SET_REALTIME_NOMINATION_COMMENT: {
      const { commentEvent, directoryUsers } = action;

      const updateNomination = (nomination) => {
        if (nomination.id === commentEvent.nominationId) {
          nomination.totalComments = commentEvent.totalComments;
        }
      };

      const companyNominations = [...state.companyNominations];
      companyNominations.forEach((nomination) => updateNomination(nomination));
      const personalNominations = [...state.personalNominations];
      personalNominations.forEach((nomination) => updateNomination(nomination));

      const isCommentInContext = state.nominationComments.some(
        (comment) => comment.nominationId === commentEvent.nominationId
      );
      let updatedComments = [...state.nominationComments];
      if (isCommentInContext) {
        updatedComments = [
          mapNominationComment(
            { ...commentEvent, id: commentEvent.commentId, message: commentEvent.comment },
            directoryUsers
          ),
          ...updatedComments
        ];
      }

      return {
        ...state,
        companyNominations: [...companyNominations],
        personalNominations: [...personalNominations],
        nominationComments: [...updatedComments]
      };
    }
    case SET_REALTIME_NOMINATION_COMMENT_UPDATE: {
      const { commentEvent } = action;

      const updateComment = (comment) => {
        if (comment.id === commentEvent.commentId) {
          comment.message = commentEvent.comment;
        }
      };

      const nominationComments = [...state.nominationComments];
      nominationComments.forEach((comment) => updateComment(comment));
      const commentReplies = [...state.commentReplies];
      commentReplies.forEach((comment) => updateComment(comment));

      return {
        ...state,
        nominationComments: [...nominationComments],
        commentReplies: [...commentReplies]
      };
    }
    case SET_REALTIME_NOMINATION_COMMENT_REMOVE: {
      const { commentEvent } = action;

      const companyNominations = [...state.companyNominations];
      const personalNominations = [...state.personalNominations];
      let commentReplies = [...state.commentReplies];
      let nominationComments = [...state.nominationComments];

      let matchedComment = commentReplies.find((comment) => comment.id === commentEvent.commentId);
      if (matchedComment) {
        nominationComments.forEach((comment) => {
          if (comment.id === matchedComment.parentCommentId) {
            comment.totalReplies = commentEvent.totalComments;
          }
        });
        commentReplies = commentReplies.filter((comment) => comment.id !== commentEvent.commentId);
      } else {
        const updateNomination = (nomination) => {
          if (nomination.id === commentEvent.nominationId) {
            nomination.totalComments = commentEvent.totalComments;
          }
        };

        companyNominations.forEach((nomination) => updateNomination(nomination));
        personalNominations.forEach((nomination) => updateNomination(nomination));

        nominationComments = nominationComments.filter(
          (comment) => comment.id !== commentEvent.commentId
        );
      }

      return {
        ...state,
        companyNominations: [...companyNominations],
        personalNominations: [...personalNominations],
        nominationComments: [...nominationComments],
        commentReplies: [...commentReplies]
      };
    }
    case SET_REALTIME_NOMINATION_REPLY: {
      const { replyEvent, directoryUsers } = action;

      const nominationComments = [...state.nominationComments];
      nominationComments.forEach((comment) => {
        if (comment.id === replyEvent.commentId) {
          comment.totalReplies = replyEvent.totalReplies;
        }
      });

      const isReplyInContext = state.commentReplies.some(
        (reply) => reply.nominationId === replyEvent.nominationId
      );
      let updatedReplies = [...state.commentReplies];
      if (isReplyInContext) {
        updatedReplies = [
          mapNominationComment(
            {
              ...replyEvent,
              id: replyEvent.replyId,
              parentCommentId: replyEvent.commentId,
              message: replyEvent.comment,
              totalReplies: 0
            },
            directoryUsers
          ),
          ...updatedReplies
        ];
      }

      return {
        ...state,
        nominationComments: [...nominationComments],
        commentReplies: [...updatedReplies]
      };
    }
    case SET_PERSONAL_NOMINATIONS:
      const { personalNominations } = action;
      return {
        ...state,
        personalNominations
      };
    case SET_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        categories
      };
    case SET_NOMINATION_COMMENTS:
      const { nominationComments } = action;
      return {
        ...state,
        nominationComments
      };
    case SET_NOMINATION_COMMENT_REPLIES:
      const { commentReplies } = action;
      return {
        ...state,
        commentReplies
      };
    case SET_KUDOS_ERRORS:
      const { errors } = action;
      return {
        ...state,
        errors
      };
    case CLEAR_KUDOS_ERRORS:
      return {
        ...state,
        errors: undefined
      };
    default:
      return state;
  }
}
