import {
  ADD_REALTIME_ALLOCATION,
  REMOVE_REALTIME_ALLOCATION,
  SET_ALLOCATIONS,
  UPDATE_REALTIME_ALLOCATION
} from '../../action/scheduling/scheduling-allocation.actions';

export const initialState = {
  allocations: []
};

export function schedulingAllocationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALLOCATIONS:
      const { allocations } = action;
      return {
        ...state,
        allocations
      };
    case ADD_REALTIME_ALLOCATION: {
      const { event } = action;
      return {
        ...state,
        allocations: [
          {
            id: event.id,
            assignee: event.assignee,
            hours: event.hours,
            startDate: event.startDate,
            endDate: event.endDate,
            title: event.title,
            taskId: event.taskId,
            leaveCategoryId: event.leaveCategoryId,
            projectId: event.projectId,
            createdDate: event.createdDate,
            createdBy: event.createdBy,
            allocationType: event.allocationType,
            description: event.description
          },
          ...state.allocations
        ]
      };
    }
    case UPDATE_REALTIME_ALLOCATION: {
      const { event } = action;
      return {
        ...state,
        allocations: state.allocations.map((allocation) =>
          allocation.id === event.id
            ? {
                ...allocation,
                assignee: event.assignee,
                hours: event.hours,
                startDate: event.startDate,
                endDate: event.endDate,
                title: event.title,
                taskId: event.taskId,
                leaveCategoryId: event.leaveCategoryId,
                projectId: event.projectId,
                createdDate: event.createdDate,
                allocationType: event.allocationType,
                createdBy: event.createdBy,
                description: event.description
              }
            : allocation
        )
      };
    }
    case REMOVE_REALTIME_ALLOCATION: {
      const { event } = action;
      return {
        ...state,
        allocations: state.allocations.filter((allocation) => allocation.id !== event.allocationId)
      };
    }
    default:
      return state;
  }
}
