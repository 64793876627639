import { getHttpPatchOptions, getHttpPostOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../../../../tool/auth.util';

import { API_HOST } from '../index';

const kudosEndpoint = `${API_HOST}/v1/kudos`;
const categoriesEndpoint = `${kudosEndpoint}/categories`;

const getCategoryEndpoint = (id) => `${categoriesEndpoint}/${id}`;

const getArchiveCategoryEndpoint = (userId) => `${getCategoryEndpoint(userId)}/deactivate`;
const getReinstateCategoryEndpoint = (userId) => `${getCategoryEndpoint(userId)}/activate`;

export const getArchiveCategoryRequest = (categoryId) => [
  getArchiveCategoryEndpoint(categoryId),
  getHttpPatchOptions(null, getAuthHeaders())
];

export const getReinstateCategoryRequest = (categoryId) => [
  getReinstateCategoryEndpoint(categoryId),
  getHttpPatchOptions(null, getAuthHeaders())
];

export const getUpdateCategoryRequest = (categoryId, payload) => [
  getCategoryEndpoint(categoryId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getCreateCategoryRequest = (payload) => [
  categoriesEndpoint,
  getHttpPostOptions(payload, getAuthHeaders())
];
