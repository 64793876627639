import { bool } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getClassNames, noOp } from '@neslotech/utils';

import { PROFILE_TABS } from '../../../../tool/constant';

import { useDevice } from '../../../../hook/useDevice';
import { useProfile } from '../../../../hook/useProfile';

import { Dropdown } from '../../../../common/component/dropdown/Dropdown';

import { ReactComponent as BurgerMenuIcon } from '../../../../icon/burger-menu-icon.svg';
import { ReactComponent as BackIcon } from '../../../../icon/chev-left-icon.svg';

import './profile-details-tabs.scss';

const mapMenuItems = (tabs, setSelectedTab) =>
  tabs.map((tab) => ({
    text: tab.label,
    onClick: tab.enabled ? () => setSelectedTab(tab.value) : noOp
  }));

const ProfileDetailsTabs = ({ editMode }) => {
  const navigate = useNavigate();

  const { tabs, selectedTab, setSelectedTab, onBackClick } = useProfile();
  const { mobile } = useDevice();

  if (mobile) {
    const selected = tabs.find((tab) => tab.value === selectedTab);
    const isPersonalInfo = selectedTab === PROFILE_TABS.PERSONAL_INFO;

    return (
      <header className="profile-details-tabs__header">
        <button onClick={isPersonalInfo ? () => navigate(-1) : onBackClick}>
          <BackIcon />
          <span>{selected.label}</span>
        </button>
        <Dropdown menuItems={mapMenuItems(tabs, setSelectedTab)} offset={[-80, 0]}>
          <BurgerMenuIcon />
        </Dropdown>
      </header>
    );
  }

  const onItemSelect = (item) => {
    if (editMode || item.enabled) {
      setSelectedTab(item.value);
    }
  };

  return (
    <menu className="profile-details-tabs">
      {(tabs ?? []).map((item) => (
        <li
          key={item.value}
          className={getClassNames('profile-details-tab', {
            selected: item.value === selectedTab,
            enabled: editMode || item.enabled
          })}
        >
          <button onClick={() => onItemSelect(item)}>{item.label}</button>
        </li>
      ))}
    </menu>
  );
};

ProfileDetailsTabs.defaultProps = {
  editMode: false
};

ProfileDetailsTabs.propTypes = {
  editMode: bool
};

export default ProfileDetailsTabs;
