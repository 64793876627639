import { node } from 'prop-types';
import React from 'react';

import './stepper-layout.scss';

export const StepperLayout = ({ children }) => (
  <section className="stepper-layout">{children}</section>
);

StepperLayout.propTypes = {
  children: node.isRequired
};
