import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import { SCHEDULING_ENDPOINT } from '../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  LOAD_ALL_ALLOCATIONS,
  SET_ALLOCATIONS
} from '../../action/scheduling/scheduling-allocation.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performLoadAllAllocations({ onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/allocations`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_ALLOCATIONS, allocations: data });
  } catch ({ response }) {
    yield put(addSystemNotice('Failed to load allocations', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadAllAllocations() {
  yield takeLeading(LOAD_ALL_ALLOCATIONS, performLoadAllAllocations);
}

export default function* allocationSaga() {
  yield all([watchForLoadAllAllocations()]);
}
