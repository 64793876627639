import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../common/component/button/Button';

import { ReactComponent as Logo } from '../../icon/logo.svg';

import './not-found.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <section className="not-found">
      <header>
        <article>
          <Logo />
        </article>
      </header>
      <h1>404</h1>
      <p>Looks like you're lost</p>
      <Button label="Go Home" onClick={() => navigate('/dashboard')} />
    </section>
  );
};

export default NotFound;
