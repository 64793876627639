const WHITE = '#fff';
const BLACK = '#000';

/**
 * Calculate if text colour should be black or white based on background colour.
 * @param colour The background colour in hex format (e.g., "#000").
 *
 * @return The calculated text colour: either "#fff" or "#000"
 */
export const determineTextColour = (colour) => {
  if (!colour) {
    return BLACK;
  }

  colour = colour.replace('#', '');

  const r = parseInt(colour.substring(0, 2), 16) / 255;
  const g = parseInt(colour.substring(2, 4), 16) / 255;
  const b = parseInt(colour.substring(4, 6), 16) / 255;

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance > 0.5 ? BLACK : WHITE;
};
