import { combineReducers } from 'redux';

import { directoryReducer } from './directory.reducer';
import { kudosManagementReducer } from './kudos/kudos-management.reducer';
import { kudosReducer } from './kudos/kudos.reducer';
import { notificationReducer } from './notification.reducer';
import { profileReducer } from './profile.reducer';
import { clientReducer } from './scheduling/client.reducer';
import { projectReducer } from './scheduling/project.reducer';
import { schedulingAllocationReducer } from './scheduling/scheduling-allocation.reducer';
import { schedulingPermissionsReducer } from './scheduling/scheduling-permissions.reducer';
import { leaveCategoryReducer } from './scheduling/settings/leave-category.reducer';
import { taskReducer } from './scheduling/task.reducer';
import { systemReducer } from './system.reducer';
import { userReducer } from './user.reducer';

const rootReducer = combineReducers({
  system_store: systemReducer,
  profile_store: profileReducer,
  user_store: userReducer,
  directory_store: directoryReducer,
  kudos_store: kudosReducer,
  kudos_management_store: kudosManagementReducer,
  notifications_store: notificationReducer,
  project_store: projectReducer,
  scheduling_permissions_store: schedulingPermissionsReducer,
  client_store: clientReducer,
  task_store: taskReducer,
  leave_category_store: leaveCategoryReducer,
  scheduling_allocation_store: schedulingAllocationReducer
});

export default rootReducer;
