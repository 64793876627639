import { CATEGORY_TYPE } from '../../../tool/prop-types';
import './category-tooltip-item.scss';

const CategoryTooltipItem = ({ category }) => (
  <section className="category-tooltip-item">
    <header className="category-tooltip-item__header">
      <span style={{ color: category.colorCode }}>{category.name}</span>
      <span>{`+${category.points}`}</span>
    </header>
    <hr />
    <span>{category.description}</span>
  </section>
);

CategoryTooltipItem.propTypes = {
  category: CATEGORY_TYPE.isRequired
};
export default CategoryTooltipItem;
