import { arrayOf } from 'prop-types';

import { CATEGORY_TYPE } from '../../../tool/prop-types';
import './category-tooltip.scss';

import ScrollIndicator from '../../../common/component/scroll/ScrollIndicator';

import CategoryTooltipItem from './CategoryTooltipItem';

const CategoryTooltip = ({ categories }) => (
  <section className="category-tooltip">
    <div className="category-tooltip__items">
      {categories.map((item) => (
        <span key={item.id}>
          <CategoryTooltipItem category={item} />
        </span>
      ))}
    </div>
    <div>
      <ScrollIndicator small />
    </div>
  </section>
);

CategoryTooltip.propTypes = {
  categories: arrayOf(CATEGORY_TYPE).isRequired
};

export default CategoryTooltip;
