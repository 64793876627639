export const ARCHIVE_KUDOS_CATEGORY = '[KUDOS_MANAGEMENT_STORE] Archive category';
export const REINSTATE_KUDOS_CATEGORY = '[KUDOS_MANAGEMENT_STORE] Reinstate category';
export const UPDATE_KUDOS_CATEGORY = '[KUDOS_MANAGEMENT_STORE] Update category';
export const CREATE_KUDOS_CATEGORY = '[KUDOS_MANAGEMENT_STORE] Create category';
export const SET_KUDOS_CATEGORIES = '[KUDOS_MANAGEMENT_STORE] Set Management Categories';
export const LOAD_KUDOS_CATEGORIES = '[KUDOS_MANAGEMENT_STORE] Load Management Categories';

export const archiveKudosCategory = (categoryId, onComplete) => ({
  type: ARCHIVE_KUDOS_CATEGORY,
  categoryId,
  onComplete
});

export const reinstateKudosCategory = (categoryId, onComplete) => ({
  type: REINSTATE_KUDOS_CATEGORY,
  categoryId,
  onComplete
});

export const updateCategory = (categoryId, payload, onSuccess, onComplete) => ({
  type: UPDATE_KUDOS_CATEGORY,
  categoryId,
  payload,
  onSuccess,
  onComplete
});

export const createKudosCategory = (payload, onSuccess, onError) => ({
  type: CREATE_KUDOS_CATEGORY,
  payload,
  onSuccess,
  onError
});

export const loadKudosManagementCategories = (onComplete, archived = false) => ({
  type: LOAD_KUDOS_CATEGORIES,
  archived,
  onComplete
});
