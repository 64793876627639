import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  archiveKudosCategory,
  reinstateKudosCategory
} from '../../../state/action/kudos/management.actions';

import { useKudosManagement } from '../../../hook/useKudosManagement';

import Dialog from '../../../common/layout/portal/Dialog';

const ToggleKudosCategoryContainer = ({ selectedCategory }) => {
  const { fetchCategories, setCategoryToBeUpdated } = useKudosManagement();

  const dispatch = useDispatch();

  const [dialogLoading, setDialogLoading] = useState(false);

  const handleOnComplete = () => {
    setCategoryToBeUpdated(null);
  };

  const handleDialogResponse = () => {
    setDialogLoading(true);

    if (!selectedCategory) {
      setDialogLoading(false);
      return;
    }

    if (selectedCategory.active) {
      dispatch(
        archiveKudosCategory(selectedCategory.categoryId, () => {
          handleOnComplete();
          setDialogLoading(false);
          fetchCategories();
        })
      );
    } else {
      dispatch(
        reinstateKudosCategory(selectedCategory.categoryId, () => {
          handleOnComplete();
          setDialogLoading(false);
          fetchCategories();
        })
      );
    }
  };

  return (
    selectedCategory.categoryId && (
      <Dialog
        loading={dialogLoading}
        heading={`Are you sure you want to ${
          selectedCategory.active ? 'archive' : 'reinstate'
        } this category?`}
        isOpen
        primaryText="Yes"
        secondaryText="No"
        onPrimaryClick={handleDialogResponse}
        onSecondaryClick={handleOnComplete}
        onClose={handleOnComplete}
      />
    )
  );
};

export default ToggleKudosCategoryContainer;
