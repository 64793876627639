import { arrayOf, bool } from 'prop-types';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { PROFILE_TYPE, PROJECT_TYPE, USER_TYPE } from '../../tool/prop-types';

import { Card } from '../../common/component/card/Card';
import SkeletonLoader from '../../common/component/loader/skeleton/SkeletonLoader';

import './admin-dashboard.scss';

const AdminDashboard = ({ profile, users, directoryUsers, projects, loading = false }) => {
  const skeletonRef = useRef();

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <section className="admin-dashboard" ref={skeletonRef}>
        <h1>
          <span>Welcome Back,</span>
          <span>{profile.fullName}</span>
        </h1>
        <div className="admin-dashboard__cards">
          <Card grey>
            <Link to="/user-management">
              <h5>User Management</h5>
              <div className="admin-dashboard__counter">
                <span>{users.length}</span>
                <span>Employees</span>
              </div>
            </Link>
          </Card>
          <Card grey>
            <Link to="/employees/directory">
              <h5>Employee Directory</h5>
              <div className="admin-dashboard__counter">
                <span>{directoryUsers.length}</span>
                <span>Employees</span>
              </div>
            </Link>
          </Card>
          <Card grey>
            <Link to="/scheduling/dashboard">
              <h5>Scheduling Management</h5>
              <div className="admin-dashboard__counter">
                <span>{projects.length}</span>
                <span>Projects</span>
              </div>
            </Link>
          </Card>
        </div>
      </section>
    </SkeletonLoader>
  );
};

AdminDashboard.propTypes = {
  profile: PROFILE_TYPE,
  users: arrayOf(USER_TYPE).isRequired,
  directoryUsers: arrayOf(USER_TYPE).isRequired,
  projects: arrayOf(PROJECT_TYPE).isRequired,
  loading: bool
};

export default AdminDashboard;
