import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { preventDefault } from '@neslotech/utils';

import './form.scss';

export const Form = ({ children }) => (
  <form className="form" onSubmit={preventDefault}>
    {children}
  </form>
);

Form.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};
