import { instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { filterAllocations } from '../../../tool/scheduling.util';

import { SchedulingAllocation } from '../../../component/scheduling/dashboard/calendar/view/allocation/SchedulingAllocation';

export const SchedulingAllocationContainer = ({ day, userId }) => {
  const allocations = useSelector(
    ({ scheduling_allocation_store }) => scheduling_allocation_store.allocations
  );
  const projects = useSelector(({ project_store }) => project_store.projectsMetadata);
  const categories = useSelector(({ leave_category_store }) => leave_category_store.categories);
  const user = useSelector(({ profile_store }) => profile_store.contextUser);

  const filteredTasks = useMemo(() => {
    return filterAllocations(allocations, day, userId);
  }, [allocations, day, userId]);

  return (
    <>
      {filteredTasks.map((task) => {
        const project = projects.find((project) => project.id === task.projectId);
        const category = categories.find((category) => category.id === task.leaveCategoryId);
        return (
          <SchedulingAllocation
            key={task.id}
            task={task}
            colour={project?.colour ?? category?.colour}
            loggedInUser={user}
          />
        );
      })}
    </>
  );
};

SchedulingAllocationContainer.propTypes = {
  day: instanceOf(Date).isRequired,
  userId: string.isRequired
};
