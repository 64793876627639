import { API_HOST } from '../index';

export const PROJECTS_ENDPOINT = `${API_HOST}/v1/scheduling/projects`;

export const getProjectEndpoint = (projectId) => `${PROJECTS_ENDPOINT}/${projectId}`;

export const getTeamMembersEndpoint = (projectId) =>
  `${getProjectEndpoint(projectId)}/team-members`;

export const getTasksEndpoint = (projectId) => `${getProjectEndpoint(projectId)}/tasks`;

export const getTaskEndpoint = (projectId, taskId) => `${getTasksEndpoint(projectId)}/${taskId}`;
