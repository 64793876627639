import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/utils';

import { mapSchedulingUsers } from '../tool/scheduling.util';

import {
  loadAllSchedulingPermissions,
  loadUserSchedulingPermission
} from '../state/action/scheduling/scheduling-permissions.actions';

import { SchedulingPermissionsContext } from '../context/SchedulingPermissions.context';

export const SchedulingPermissionsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schedulingPermissions = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.permissions
  );
  const users = useSelector(({ directory_store }) => directory_store.directoryUsers);
  const profile = useSelector(({ profile_store }) => profile_store.contextUser);
  const schedulingPermissionLoaded = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.userLoaded
  );

  useEffect(() => {
    if (isEmpty(schedulingPermissions)) {
      dispatch(loadAllSchedulingPermissions(navigate));
    } else if (!schedulingPermissionLoaded) {
      dispatch(loadUserSchedulingPermission(profile.id, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, schedulingPermissions, schedulingPermissionLoaded]);

  const mappedSchedulingUsers = useMemo(
    () => mapSchedulingUsers(schedulingPermissions, users),
    [users, schedulingPermissions]
  );

  const value = {
    schedulingPermissionIds: schedulingPermissions,
    mappedSchedulingUsers: mappedSchedulingUsers,
    schedulingPermissionLoaded
  };

  return (
    <SchedulingPermissionsContext.Provider value={value}>
      {children}
    </SchedulingPermissionsContext.Provider>
  );
};
