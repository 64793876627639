import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { filterElement, searchElement } from '../../../tool/filter.util';
import { USER_TYPE } from '../../../tool/prop-types';

import { useFilters } from '../../../hook/useFilter';

import { Button } from '../../../common/component/button/Button';

import { FilterLayout } from '../../../common/layout/filter/FilterLayout';
import UserCheckboxTable from '../checkbox/table/UserCheckboxTable';

import './scheduling-user-access.scss';

const FILTER_OPTIONS = Object.freeze([
  {
    name: 'Department',
    active: false,
    singleSelect: true,
    values: [
      { value: 'Engineers', selected: false },
      { value: 'Administration', selected: false },
      { value: 'Executives', selected: false },
      { value: 'Designers', selected: false }
    ]
  }
]);

const CheckboxTableFilter = ({ rows, loading, selectedRowsChanged }) => {
  const { filters, search, setDefaultOptions } = useFilters();

  useEffect(() => {
    setDefaultOptions(FILTER_OPTIONS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredRows = useMemo(() => {
    return rows
      .filter((row) => filterElement(row, filters))
      .filter(
        (row) =>
          searchElement(row.firstName, search) ||
          searchElement(row.lastName, search) ||
          searchElement(row.department, search)
      );
  }, [rows, filters, search]);

  return (
    <UserCheckboxTable
      rows={filteredRows}
      loading={loading}
      selectedRowsChanged={selectedRowsChanged}
    />
  );
};

CheckboxTableFilter.propTypes = {
  rows: arrayOf(
    shape({
      id: string,
      firstName: string,
      lastName: string,
      department: string,
      checked: bool
    })
  ),
  loading: bool,
  selectedRowsChanged: func
};

const SchedulingUserAccess = ({
  users,
  permissions,
  loading = false,
  onSubmit = noOp,
  setup = false
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rows = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      department: user.department,
      checked: permissions.some((permission) => permission.userId === user.id)
    }));
  }, [users, permissions]);

  const selectedRowsChanged = (val) => {
    setSelectedRows(val);
  };

  const handleSubmit = () => {
    onSubmit(selectedRows.map((row) => row.id));
  };

  return (
    <section className={getClassNames('scheduling-access-settings', { setup })}>
      <FilterLayout label="Search for something">
        <CheckboxTableFilter
          rows={rows}
          loading={loading}
          selectedRowsChanged={selectedRowsChanged}
        />
        <Button label="Save" disabled={selectedRows.length === 0} onClick={handleSubmit} />
      </FilterLayout>
    </section>
  );
};

SchedulingUserAccess.propTypes = {
  users: arrayOf(USER_TYPE).isRequired,
  permissions: arrayOf(shape({ userId: string.isRequired })).isRequired,
  loading: bool,
  onSubmit: func,
  setup: bool
};

export default SchedulingUserAccess;
