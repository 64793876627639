import { func, string } from 'prop-types';
import React, { createRef } from 'react';

import { ReactComponent as EditIcon } from '../../../icon/edit-block-icon.svg';

import Blip from '../../blip/Blip';

import './selected-colour-picker.scss';

export const SelectedColourPicker = ({ name, colour, onChange }) => {
  const pickerRef = createRef();

  const handleChange = (event) => {
    onChange({ [name]: event.target.value });
  };

  return (
    <div className="selected-colour-picker">
      <div className="selected-colour-picker__picker">
        <Blip large colour={colour} />

        <button onClick={() => pickerRef.current.click()}>
          <EditIcon />
          <input ref={pickerRef} type="color" value={colour} onChange={handleChange} />
        </button>
      </div>
    </div>
  );
};

SelectedColourPicker.propTypes = {
  colour: string.isRequired,
  onChange: func.isRequired
};
