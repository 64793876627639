import { func } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isValuePresent } from '@neslotech/utils';

import { FormAction } from '../../../../../common/component/form/form-action/FormAction';

import { Form } from '../../../../../common/component/form/Form';
import { FormRow } from '../../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../../common/component/input/Input';
import { TextArea } from '../../../../../common/component/textarea/TextArea';

import ColourSelect from '../../../../kudos-management/input/ColourSelect';

import './client-add-form.scss';

const SELECT_COLOURS = [
  { colour: '#FF8080' },
  { colour: '#FFCF96' },
  { colour: '#8B93FF' },
  { colour: '#AEDEFC' },
  { colour: '#59CE8F' }
];

const SELECT_COLOUR_DEFAULT = { colour: '#CECECE' };

const DEFAULT_FORM_STATE = {
  name: '',
  colour: '',
  additionalInformation: ''
};

const REQUIRED_FIELDS = Object.freeze(['name', 'colour']);

const ClientAddForm = ({ onSubmit }) => {
  const navigate = useNavigate();

  const [form, setForm] = useState(DEFAULT_FORM_STATE);

  const isSubmitDisabled = useMemo(() => {
    return REQUIRED_FIELDS.some((field) => !isValuePresent(form[field]));
  }, [form]);

  const handleColourChange = (colour) => {
    handleInputChange({ colour });
  };

  const handleInputChange = (newState) => setForm({ ...form, ...newState });

  return (
    <section className="client-add-form">
      <Form>
        <fieldset>
          <FormRow>
            <Input
              label="Client Name"
              name="name"
              value={form.name}
              onChange={handleInputChange}
              required
            />
          </FormRow>
          <FormRow>
            <ColourSelect
              label="Client Colour"
              name="colour"
              colours={SELECT_COLOURS}
              colourPickerButtonColour={SELECT_COLOUR_DEFAULT}
              showColourPicker
              value={form.colour}
              onSelectionChange={handleColourChange}
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label="Additional Information"
              name="additionalInformation"
              value={form.additionalInformation}
              onChange={handleInputChange}
            />
          </FormRow>
        </fieldset>
        <div className="client-add-form__actions">
          <FormAction label="Cancel" secondary onClick={() => navigate('/scheduling/clients')} />
          <FormAction label="Save" disabled={isSubmitDisabled} onClick={() => onSubmit(form)} />
        </div>
      </Form>
    </section>
  );
};

ClientAddForm.propTypes = {
  onSubmit: func.isRequired
};

export default ClientAddForm;
