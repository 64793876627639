import React, { useEffect, useState } from 'react';

import { isMobile } from '@neslotech/utils';

import { DeviceContext } from '../context/Device.context';

const DeviceProvider = ({ children }) => {
  const [mobile, setMobile] = useState(isMobile());

  useEffect(() => {
    const handleOrientationChange = () => setMobile(isMobile());

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  const value = {
    mobile
  };

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;
