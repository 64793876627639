import React, { useState } from 'react';

import { capitalise, deepClone } from '@neslotech/utils';

import { isEmptyFilter } from '../tool/filter.util';

import { FilterContext } from '../context/Filter.context';

const FilterProvider = ({ children }) => {
  const [search, setSearch] = useState('');

  const [defaultOptions, setDefaultOptions] = useState();
  const [options, setOptions] = useState();

  const [filters, setFilters] = useState();
  const [filtering, setFiltering] = useState(false);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
  };

  const onRemoveFilters = (optionName) => {
    options?.forEach((option) => {
      if (option.name === capitalise(optionName)) {
        option.active = false;
        option.values.forEach((find) => (find.selected = false));
      }
    });
    setOptions([...options]);
    setFilters({
      ...filters,
      [optionName]: null
    });
  };

  const handleDefaultOptions = (options) => {
    setFilters(undefined);
    setDefaultOptions(options);
    handleOptions(deepClone(options));
  };

  const handleOptions = (options) => {
    setOptions(options);
  };

  const handleFilters = (filterValue) => {
    setFilters(filterValue);
    setFiltering(!isEmptyFilter(filterValue));
  };

  const handleClearFilters = () => {
    setOptions(deepClone(defaultOptions));
    setFiltering(false);
    setFilters(undefined);
    setSearch(undefined);
  };

  const value = {
    search,
    filters,
    filtering,
    options,
    clearSearch: () => setSearch(''),
    setOptions: handleOptions,
    setSearch: handleSearch,
    setDefaultOptions: handleDefaultOptions,
    setFilters: handleFilters,
    onClearFilters: handleClearFilters,
    removeFilters: onRemoveFilters
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
