import React from 'react';

import { DeviceContext } from '../context/Device.context';

/**
 * @deprecated rather use html and css
 * @returns {null}
 */
export const useDevice = () => {
  return React.useContext(DeviceContext);
};
