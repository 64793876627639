import { arrayOf, node, oneOfType, string } from 'prop-types';
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import './modal.scss';

export const Modal = ({ children, className }) => {
  const mount = document.getElementById('modal-root');

  const { overlay, portalEl, portalContent } = useMemo(() => {
    const overlay = document.createElement('section');
    overlay.setAttribute('class', 'modal__overlay');

    const portalEl = document.createElement('section');
    portalEl.setAttribute('class', 'modal');

    if (!!className) {
      portalEl.classList.add(className);
    }

    const portalContent = document.createElement('section');
    portalContent.setAttribute('class', 'modal__content');

    return { overlay, portalEl, portalContent };
  }, [className]);

  useEffect(() => {
    mount.appendChild(overlay);
    overlay.appendChild(portalEl);
    portalEl.appendChild(portalContent);

    return () => {
      mount.removeChild(overlay);
    };
  }, [portalEl, mount, overlay, portalContent]);

  return createPortal(children, portalContent);
};

Modal.defaultProps = {
  className: ''
};

Modal.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string
};
