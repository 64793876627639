import React from 'react';
import { Link, Route } from 'react-router-dom';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import NotificationsMobileContainer from '../container/notification/Notifications.mobile.container';

export const notificationRoutes = () => (
  <Route
    path="/notifications"
    handle={{
      crumb: () => <Link to="/dashboard" />,
      pageName: 'Notifications'
    }}
  >
    <Route
      exact
      path=""
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <NotificationsMobileContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
