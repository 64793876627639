import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useKudosManagement } from '../../hook/useKudosManagement';

import SkeletonLoader from '../../common/component/loader/skeleton/SkeletonLoader';

import './kudos-management-dashboard.scss';

const KudosManagementDashboard = () => {
  const { loading, categories } = useKudosManagement();
  const skeletonRef = useRef();

  return (
    <SkeletonLoader loading={loading} elementRef={skeletonRef}>
      <section className="kudos-management-dashboard" ref={skeletonRef}>
        <div className="kudos-management-dashboard__cards">
          <Link to="/kudos-management/categories">
            <h5>Category Management</h5>
            <div className="kudos-management-dashboard__counter">
              <span>{categories.length}</span>
              <span>Categories</span>
            </div>
          </Link>
          <figure>
            <blockquote>
              <p>
                ”Appreciation can make a day, even change a life. Your willingness to put it into
                words is all that is necessary.”
              </p>
            </blockquote>
            <figcaption>- Margaret Cousins</figcaption>
          </figure>
        </div>
      </section>
    </SkeletonLoader>
  );
};

export default KudosManagementDashboard;
