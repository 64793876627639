export const KUDOS_TABS = Object.freeze({
  KUDOS_CARDS: 'kudosCards',
  COMPANY_TIMELINE: 'companyTimeline',
  PERSONAL_TIMELINE: 'personalTimeline'
});

export const KUDOS_WEB_TAB_OPTIONS = Object.freeze([
  {
    label: 'Company Timeline',
    value: KUDOS_TABS.COMPANY_TIMELINE
  },
  {
    label: 'My Timeline',
    value: KUDOS_TABS.PERSONAL_TIMELINE
  }
]);

export const KUDOS_MOBILE_TAB_OPTIONS = Object.freeze([
  {
    label: 'Kudos Cards',
    value: KUDOS_TABS.KUDOS_CARDS
  },
  ...KUDOS_WEB_TAB_OPTIONS
]);
