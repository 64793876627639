import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/utils';

import { loadDirectoryUsers } from '../../state/action/directory.actions';

import EmployeeDirectory from '../../component/employee-directory/EmployeeDirectory';
import AppLayout from '../../component/layout/AppLayout';

const userPlaceholders = [
  { id: '1' },
  { id: '2' },
  { id: '3' },
  { id: '4' },
  { id: '5' },
  { id: '6' },
  { id: '7' }
];

const EmployeeDirectoryContainer = () => {
  const dispatch = useDispatch();

  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);
  const directoryUsers = useSelector(({ directory_store }) => directory_store.directoryUsers);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    if (isEmpty(directoryUsers)) {
      dispatch(loadDirectoryUsers(() => setLoading(false)));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryUsers]);

  return (
    <AppLayout fullWidthBreadcrumb>
      <EmployeeDirectory users={!loading ? directoryUsers : userPlaceholders} loading={loading} />
    </AppLayout>
  );
};

export default EmployeeDirectoryContainer;
