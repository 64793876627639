import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { ReactComponent as CloseIcon } from '../../../icon/close-icon.svg';

import './modal.scss';

export const ModalLayout = ({ title, onClose, children, large, customClass }) => {
  const classes = `${getClassNames('modal-layout', { large })} ${customClass}`;

  return (
    <dialog open className={classes}>
      <header className="modal-layout__header">
        <h3 className="modal-layout__header-title">{title}</h3>
        <span className="modal-layout__close" onClick={onClose} role="dialog" onKeyDown={noOp}>
          <CloseIcon />
        </span>
      </header>
      <section className="modal-layout__content">{children}</section>
    </dialog>
  );
};

ModalLayout.defaultProps = {
  large: false,
  title: '',
  customClass: ''
};

ModalLayout.propTypes = {
  title: oneOfType([string, node]),
  onClose: func.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
  large: bool,
  customClass: string
};
