import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';

import { PusherContext } from '../context/Pusher.context';

export const PusherProvider = ({ children }) => {
  const [pusher, setPusher] = useState();

  useEffect(() => {
    Pusher.logToConsole = true;
    const pusherControl = new Pusher(process.env.REACT_APP_PUSHER_KEY ?? '', {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? 'eu'
    });
    if (!pusher) {
      setPusher(pusherControl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pusher) {
    return null;
  }

  return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
};
