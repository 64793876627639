import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import './button-group.scss';

const ButtonGroup = ({ children }) => <div className="button-group">{children}</div>;

ButtonGroup.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default ButtonGroup;
