import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPatchOptions,
  getHttpPostOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';

import { API_HOST } from './index';

const getUsersEndpoint = () => `${API_HOST}/v1/users`;
const getUserEndpoint = (id) => `${getUsersEndpoint()}/${id}`;
const getUserRoleEndpoint = (id) => `${getUserEndpoint(id)}/role`;

export const getLoadUsersRequest = () => [getUsersEndpoint(), getHttpGetOptions(getAuthHeaders())];

export const getAddUserRequest = (payload) => [
  getUsersEndpoint(),
  getHttpPostOptions(payload, getAuthHeaders())
];

export const getRemoveUserRequest = (id) => [
  getUserEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getChangeUserRoleRequest = (id, payload) => [
  getUserRoleEndpoint(id),
  getHttpPatchOptions(payload, getAuthHeaders())
];
