const STORE_NAME = '[LEAVE_ALLOCATION_STORE]';

export const CREATE_LEAVE_ALLOCATION = `${STORE_NAME} Create leave allocation`;
export const createLeaveAllocation = (leaveAllocation, onSuccess, onComplete) => ({
  type: CREATE_LEAVE_ALLOCATION,
  leaveAllocation,
  onSuccess,
  onComplete
});

export const CREATE_USER_LEAVE_ALLOCATION = `${STORE_NAME} Create user leave allocation`;
export const createUserLeaveAllocation = (userId, leaveAllocation, onSuccess, onComplete) => ({
  type: CREATE_USER_LEAVE_ALLOCATION,
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
});

export const UPDATE_LEAVE_ALLOCATION = `${STORE_NAME} Update leave allocation`;
export const updateLeaveAllocation = (
  leaveAllocationId,
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
) => ({
  type: UPDATE_LEAVE_ALLOCATION,
  leaveAllocationId,
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
});

export const REMOVE_LEAVE_ALLOCATION = `${STORE_NAME} Remove leave allocation`;
export const removeLeaveAllocation = (leaveAllocationId, userId, onSuccess, onComplete) => ({
  type: REMOVE_LEAVE_ALLOCATION,
  leaveAllocationId,
  userId,
  onSuccess,
  onComplete
});
