import React from 'react';

import { useKudosManagement } from '../../../hook/useKudosManagement';

import AddKudosCategory from '../../../component/kudos-management/add/AddKudosCategory';

const AddCategoryContainer = () => {
  const {
    categoryToBeSaved,
    resetCategory,
    errors,
    onCreateCategory,
    clearErrors,
    isModalOpen,
    setIsModalOpen
  } = useKudosManagement();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetCategory();
  };

  return (
    isModalOpen && (
      <AddKudosCategory
        category={categoryToBeSaved}
        errors={errors}
        onFocus={clearErrors}
        onSave={(form) => onCreateCategory(form)}
        onClose={handleCloseModal}
      />
    )
  );
};

export default AddCategoryContainer;
