import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadMostFrequentCategories,
  loadRedeemablePoints,
  loadRemainingPoints
} from '../../state/action/kudos/kudos.actions';

import { useAuth } from '../../hook/useAuth';

import PointsCard from '../../component/kudos/my-points/PointsCard';

const PointsCardContainer = () => {
  const { id } = useAuth();
  const dispatch = useDispatch();

  const frequentCategories = useSelector(({ kudos_store }) => kudos_store.frequentCategories);
  const remainingPoints = useSelector(({ kudos_store }) => kudos_store.remainingPoints);
  const redeemablePoints = useSelector(({ kudos_store }) => kudos_store.redeemablePoints);
  const allTimePoints = useSelector(({ kudos_store }) => kudos_store.allTimePoints);

  useEffect(() => {
    if (!!id) {
      dispatch(loadMostFrequentCategories(id));
      dispatch(loadRemainingPoints(id));
      dispatch(loadRedeemablePoints(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const stats = [
    {
      id: 1,
      value: `${remainingPoints}`,
      description: 'Left to give'
    },
    {
      id: 2,
      value: `${redeemablePoints}`,
      description: 'Redeemable'
    },
    {
      id: 3,
      value: `${allTimePoints}`,
      description: 'All time'
    }
  ];

  return <PointsCard stats={stats} categories={frequentCategories} />;
};

export default PointsCardContainer;
