// notifications
export const NOTIFICATIONS_RECEIVED = 'notifications-received';

// points
export const POINTS_RECEIVED = 'points-received';

// kudos
export const KUDOS_NOMINATION = 'kudos-nomination';
export const KUDOS_NOMINATION_REMOVE = 'kudos-nomination-remove';
export const KUDOS_NOMINATION_DELETE = 'kudos-nomination-delete';
export const KUDOS_LEADERBOARD = 'kudos-leaderboard';
export const KUDOS_POPULAR_CATEGORIES = 'kudos-popular-categories';
export const KUDOS_NOMINATION_LIKE = 'kudos-nomination-like';
export const KUDOS_NOMINATION_COMMENT = 'kudos-nomination-comment';
export const KUDOS_NOMINATION_COMMENT_UPDATE = 'kudos-nomination-comment-update';
export const KUDOS_NOMINATION_COMMENT_REMOVE = 'kudos-nomination-comment-remove';
export const KUDOS_NOMINATION_REPLY = 'kudos-nomination-reply';
export const KUDOS_MOST_NOMINATED_CATEGORIES = 'kudos-most-nominated';
export const KUDOS_REMAINING_POINTS = 'kudos-remaining-points';

// scheduling
export const TASK_CREATED = 'task-created';
export const TASK_REMOVED = 'task-removed';
export const UNLINK_PROJECT = 'unlink-project';
export const TASK_ALLOCATION_CREATED = 'task-allocation-created';
export const TASK_ALLOCATION_UPDATED = 'task-allocation-updated';
export const LEAVE_ALLOCATION_CREATED = 'leave-allocation-created';
export const LEAVE_ALLOCATION_UPDATED = 'leave-allocation-updated';
export const ALLOCATION_REMOVED = 'allocation-removed';
