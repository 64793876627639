import { bool, func, node } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './comment-input-action.scss';

const CommentInputAction = ({ action, onActionClick, disabled, error, filled }) =>
  action && (
    <button
      className={getClassNames('comment-input-action', { disabled, error, filled })}
      onClick={onActionClick}
    >
      {action}
    </button>
  );

CommentInputAction.defaultProps = {
  action: undefined,
  disabled: false,
  error: false,
  filled: false
};

CommentInputAction.propTypes = {
  action: node,
  onActionClick: func.isRequired,
  disabled: bool,
  error: bool,
  filled: bool
};

export default CommentInputAction;
