import React from 'react';

import NotificationsProvider from '../../provider/Notifications.provider';

import AppLayout from '../../component/layout/AppLayout';
import Notifications from '../../component/notification/Notifications';

const NotificationsMobileContainer = () => (
  <AppLayout showMobileBackButton={false}>
    <NotificationsProvider>
      <Notifications />
    </NotificationsProvider>
  </AppLayout>
);

export default NotificationsMobileContainer;
