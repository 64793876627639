import React from 'react';

import { PROFILE_TYPE } from '../../tool/prop-types';

import EmployeeDashboard from '../../component/dashboard/EmployeeDashboard';

const EmployeeDashboardContainer = ({ profile, directoryUsers, loading }) => (
  <EmployeeDashboard profile={profile} directoryUsers={directoryUsers} loading={loading} />
);

EmployeeDashboardContainer.propTypes = {
  profile: PROFILE_TYPE
};

EmployeeDashboardContainer.defaultProps = {
  profile: undefined
};

export default EmployeeDashboardContainer;
