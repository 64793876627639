import { addDays, differenceInDays } from 'date-fns';

import { isEmpty } from '@neslotech/utils';

import profileIcon from '../icon/profile-icon.svg';

export const mapSchedulingUsers = (permissions, users) => {
  if (isEmpty(permissions) || isEmpty(users)) {
    return [];
  }

  return users
    .filter((user) => permissions.some((permission) => permission.userId === user.id))
    .map((user) => ({
      id: user.id,
      image: user.image ?? user.defaultImage ?? profileIcon,
      department: user.department,
      jobTitle: user.jobTitle,
      role: user.role,
      firstName: user.firstName,
      lastName: user.lastName
    }));
};

export const ALLOCATION_HEIGHT_INCREMENT = 27;
export const SMALLEST_ALLOCATION_HEIGHT_INCREMENT = 23;

export const calculateHeight = (hours) => {
  const roundedDown = Math.floor(hours);
  return SMALLEST_ALLOCATION_HEIGHT_INCREMENT + ALLOCATION_HEIGHT_INCREMENT * roundedDown;
};

export const filterAllocations = (allocations, day, userId) =>
  allocations.filter((task) => {
    const taskStartDate = new Date(task.startDate);
    const taskEndDate = new Date(task.endDate);
    const selectedDay = new Date(day);

    if (task.assignee !== userId) {
      return false;
    }

    return (
      (taskStartDate <= selectedDay && taskEndDate >= selectedDay) ||
      taskStartDate.toDateString() === selectedDay.toDateString()
    );
  });

export const calculateDaysOfWeek = () => {
  // This will need to change to the earliest date a user was created
  const startDate = new Date(2024, 10, 0);

  // added 90 to reflect 3 months from today so the user can scroll until 3 months from today
  const totalDays = differenceInDays(new Date(), startDate) + 90;

  return Array.from({ length: totalDays }, (_, i) => addDays(startDate, i));
};
