import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './card.scss';

export const Card = ({
  children,
  white,
  light,
  borderless,
  shadowed,
  dark,
  grey,
  transparent,
  cardTitle
}) => {
  return (
    <article
      title={cardTitle}
      className={getClassNames('card', {
        white,
        light,
        borderless,
        shadowed,
        dark,
        grey,
        transparent
      })}
    >
      {children}
    </article>
  );
};

Card.defaultProps = {
  white: false,
  light: false,
  borderless: false,
  shadowed: false,
  dark: false,
  transparent: false,
  cardTitle: ''
};

Card.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  white: bool,
  light: bool,
  borderless: bool,
  shadowed: bool,
  dark: bool,
  transparent: bool,
  cardTitle: string
};
