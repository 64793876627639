import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createClient } from '../../../../../state/action/scheduling/client.actions';

import { useProgressLoader } from '../../../../../hook/useProgressLoader';

import ClientAddForm from '../../../../../component/scheduling/client/form/add/ClientAddForm';

export const ClientAddContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();

  const handleSubmit = (client) => {
    setBusyState(true);
    dispatch(createClient(client, navigate, () => setBusyState(false)));
  };
  return <ClientAddForm onSubmit={handleSubmit} />;
};
