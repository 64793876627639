import { number, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { getClassNames } from '@neslotech/utils';

import { determineTextColour } from '../../../../../../tool/color.util';
import { LEAVE_ALLOCATION, ROLES } from '../../../../../../tool/constant';
import { USER_TYPE } from '../../../../../../tool/prop-types';
import { calculateHeight } from '../../../../../../tool/scheduling.util';

// eslint-disable-next-line max-len
import { SchedulingAllocationFormContainer } from '../../../../../../container/scheduling/allocation/form/SchedulingAllocationForm.container';

import './scheduling-allocation.scss';

export const SchedulingAllocation = ({ task, colour, loggedInUser }) => {
  const [openModal, setOpenModal] = useState(false);

  const isAdmin = loggedInUser.role === ROLES.ADMIN;
  const isLeaveAllocation = task.allocationType === LEAVE_ALLOCATION;
  const isOwnTask = task.assignee === loggedInUser.id;

  const canInteract = isAdmin || (!isLeaveAllocation && isOwnTask);
  const cursorStyle = canInteract ? 'pointer' : 'default';

  const textColour = useMemo(() => determineTextColour(colour), [colour]);

  return (
    <>
      <button
        className={getClassNames('scheduling-allocation', { leave: isLeaveAllocation })}
        style={{
          backgroundColor: colour,
          height: `${calculateHeight(task.hours)}px`,
          cursor: cursorStyle,
          color: textColour
        }}
        onClick={() => {
          if (canInteract) {
            setOpenModal(true);
          }
        }}
      >
        <span>{task.title}</span>
        <span>{task.hours}h</span>
      </button>

      {openModal && (
        <SchedulingAllocationFormContainer
          allocation={task}
          selectedTab={isLeaveAllocation ? 'Time Off' : 'Allocation'}
          onClose={() => setOpenModal(false)}
          isEditing
        />
      )}
    </>
  );
};

SchedulingAllocation.propTypes = {
  task: shape({
    title: string.isRequired,
    hours: number.isRequired
  }).isRequired,
  colour: string,
  loggedInUser: USER_TYPE
};
