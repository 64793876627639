import { arrayOf, func, number, shape, string } from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';

import { noOp } from '@neslotech/utils';

import './pie-chart.scss';

/**
 * Convert degrees to radians.
 * The Math library trigonometry functions require radians instead of degrees as input.
 * @param degrees The degrees to convert.
 * @returns {number} The radians.
 */
const radians = (degrees) => (degrees * Math.PI) / 180;

/**
 * Rotate a point around a center point at a specific angle.
 * @param px The x coordinate of the point to rotate.
 * @param py The y coordinate of the point to rotate.
 * @param dx The x coordinate of the center point.
 * @param dy The y coordinate of the center point.
 * @param angle The angle to rotate in radians.
 * @returns {{x: number, y: number}} The rotated point object.
 */
const rotatePoint = (px, py, dx, dy, angle) => {
  //Translate to origin.
  px -= dx;
  py -= dy;

  //Rotate around origin.
  let x = px * Math.sin(angle) - py * Math.sin(angle);
  let y = py * Math.cos(angle) + px * Math.sin(angle);

  //Translate back to center point.
  x += dx;
  y += dy;

  return { x, y };
};

const PieChart = ({ innerRadiusPercentage, segments, onItemHover }) => {
  const [size, setSize] = useState(0);
  const elementRef = useRef(null);

  const setLayoutSize = () => {
    if (!elementRef) {
      return;
    }
    const size = Math.min(elementRef.current.offsetHeight, elementRef.current.offsetWidth);
    setSize(size);
  };

  useLayoutEffect(() => {
    setLayoutSize();
    window.addEventListener('resize', setLayoutSize);

    return () => {
      window.removeEventListener('resize', setLayoutSize);
    };
  }, []);

  const outerRadius = size / 2;
  const innerRadius = outerRadius * innerRadiusPercentage;

  const total = segments.reduce((sum, item) => sum + item.value, 0);
  const centerPoint = { x: outerRadius, y: outerRadius };
  let accumulator = 0;

  return (
    <div className="pie-chart" ref={elementRef}>
      <svg width={outerRadius * 2} height={outerRadius * 2} xmlns="http://www.w3.org/2000/svg">
        {segments.map((item) => {
          const arcStartCalc = (accumulator / total) * 360;
          const arcStart = arcStartCalc === 0 ? 0.001 : arcStartCalc;
          const arcEnd = ((accumulator + item.value) / total) * 360;
          const arcSize = arcEnd - arcStart;
          accumulator += item.value;

          //Outer circle arc.
          const p1 = rotatePoint(outerRadius, 0, centerPoint.x, centerPoint.y, radians(arcStart));
          const p2 = rotatePoint(outerRadius, 0, centerPoint.x, centerPoint.y, radians(arcEnd));

          //Inner circle arc.
          const p3 = rotatePoint(
            outerRadius,
            outerRadius - innerRadius,
            centerPoint.x,
            centerPoint.y,
            radians(arcStart)
          );
          const p4 = rotatePoint(
            outerRadius,
            outerRadius - innerRadius,
            centerPoint.x,
            centerPoint.y,
            radians(arcEnd)
          );

          /* The large arc flag indicates if the larger side of the arc should be drawn.
             If the angle of the segment is larger than 180. e.g. 250 and the flag
             is not set (equal to '0'), the angle of the arc that would be drawn is 110
             and if the flag is set (equal to '1') then the angle will be 250.*/
          const majorArcFlag = arcSize > 180 ? '1' : '0';

          return (
            <g
              key={item.id}
              className="pie-chart--grow"
              onMouseEnter={() => onItemHover(item.id)}
              onMouseLeave={() => onItemHover(null)}
            >
              {/*This path is used to allow hovering from the center of the chart.*/}
              <path
                d={`M ${p1.x} ${p1.y}
                  A ${outerRadius} ${outerRadius} 0 ${majorArcFlag} 1 ${p2.x} ${p2.y}
                  L ${outerRadius} ${outerRadius}
                  z`}
                fill="transparent"
              />
              {/*The path defining the chart segment.*/}
              <path
                id={`path_${item.id}`}
                d={`M ${p1.x} ${p1.y}
                  A ${outerRadius} ${outerRadius} 0 ${majorArcFlag} 1 ${p2.x} ${p2.y}
                  L ${p4.x} ${p4.y}
                  A ${innerRadius} ${innerRadius} 0 ${majorArcFlag} 0 ${p3.x} ${p3.y}
                  z`}
                fill={item.color}
              />
            </g>
          );
        })}
      </svg>
    </div>
  );
};

PieChart.defaultProps = {
  innerRadiusPercentage: 0.5,
  segments: [],
  onItemHover: noOp
};

PieChart.propTypes = {
  innerRadiusPercentage: number,
  segments: arrayOf(
    shape({
      id: string,
      value: number,
      color: string
    })
  ),
  onItemHover: func
};

export default PieChart;
