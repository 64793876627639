import { arrayOf, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as DropdownArrow } from '../../../../../icon/chevron-icon.svg';

import { FilterLayout } from '../../../../../common/layout/filter/FilterLayout';

import './jira-sidebar.scss';

const JiraSidebarList = ({ projects = [] }) => {
  const [openProjects, setOpenProjects] = useState({});

  const toggleProjectTasks = (projectId) => {
    setOpenProjects((prevState) => ({
      ...prevState,
      [projectId]: !prevState[projectId]
    }));
  };

  return (
    <div className="jira-sidebar-list">
      {projects.map((project) => (
        <div key={project.id}>
          <button
            className={getClassNames('jira-sidebar-list__header', {
              open: openProjects[project.id]
            })}
            onClick={() => toggleProjectTasks(project.id)}
          >
            <span>{project.name}</span>
            <DropdownArrow />
          </button>
          {openProjects[project.id] &&
            project.tasks.map((task) => (
              <div
                className="jira-sidebar-list__task-container"
                style={{ backgroundColor: project.colour }}
                key={task.id}
              >
                <p>{task.summary}</p>
                <div>
                  <img src={task.profileImageSrc} referrerPolicy="no-referrer" alt="Profile icon" />
                  <span>{task.name}</span>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export const JiraSidebar = ({ projects = [] }) => (
  <section className="jira-sidebar">
    <header>
      <h6>Jira</h6>
    </header>
    <FilterLayout label="Search for something">
      <JiraSidebarList projects={projects} />
    </FilterLayout>
  </section>
);

JiraSidebar.propTypes = {
  projects: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      colour: string.isRequired,
      tasks: arrayOf(
        shape({
          id: string.isRequired,
          summary: string.isRequired,
          name: string.isRequired,
          profileImageSrc: string.isRequired
        })
      )
    })
  )
};
