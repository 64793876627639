import { arrayOf, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { getClassNames, noOp } from '@neslotech/utils';

import { getEmployeeProfileURL } from '../../employee-directory/employee-directory.helper';
import { KUDOS_TABS } from '../dashboard/kudos.helper';

import { ROLES, USER_AVATAR_ALT } from '../../../tool/constant';
import { NOMINATION_TYPE } from '../../../tool/prop-types';

import { useKudos } from '../../../hook/useKudos';

import DeleteNominationContainer from '../../../container/kudos/management/delete-nomination/DeleteNomination.container';

import { Card } from '../../../common/component/card/Card';
import { Dropdown } from '../../../common/component/dropdown/Dropdown';

import { ReactComponent as CloseIcon } from '../../../icon/close-icon.svg';
import { ReactComponent as CommentIcon } from '../../../icon/comment-icon.svg';
import { ReactComponent as EllipsisIcon } from '../../../icon/ellipsis-icon.svg';
import { ReactComponent as HeartIcon } from '../../../icon/heart-icon.svg';
import { ReactComponent as LikeIcon } from '../../../icon/like-icon.svg';
import { ReactComponent as TrashIcon } from '../../../icon/trash-icon.svg';

import Dialog from '../../../common/layout/portal/Dialog';
import CategoryTag from '../category-tag/CategoryTag';
import NominationCardCommentSection from '../comment/NominationCardCommentSection';

import './kudos-nomination-card.scss';

const KudosNominationCard = ({
  nomination,
  onNominationLikeToggle,
  nominationComments,
  commentReplies,
  onLoadComments,
  onLoadReplies,
  onReplySubmit,
  onCommentSubmit,
  onRemoveComment,
  onUpdateNominationComment
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { handleRemoveNominationMessage, basicProfile, setSelectedTab } = useKudos();
  const cardRef = useRef(null);
  const [showAllComments, setShowAllComments] = useState(false);
  const [focusCommentField, setFocusCommentField] = useState(false);
  const [liked, setLiked] = useState(nomination.liked);

  const category = nomination.category;
  const categoryColour = category.colorCode;

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const nominationId = searchParams.get('nominationId');

    if (nominationId === nomination.id) {
      setSelectedTab(KUDOS_TABS.COMPANY_TIMELINE);
      cardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const menuItems = useMemo(() => {
    const result = [];
    if (basicProfile.role === ROLES.ADMIN || nomination.senderId === basicProfile.id) {
      result.push({
        text: 'Remove Message',
        icon: <TrashIcon />,
        onClick: () => setDialogOpen(true)
      });
    }

    if (basicProfile.role === ROLES.ADMIN) {
      result.push({
        text: 'Delete Nomination',
        icon: <CloseIcon />,
        onClick: () => setModalOpen(true)
      });
    }

    return result;
  }, [basicProfile.id, basicProfile.role, nomination.senderId]);

  const onLike = () => {
    const invertedVal = !liked;
    setLiked(invertedVal);

    onNominationLikeToggle(nomination.id, () => {
      setLiked(!invertedVal);
    });
  };

  const handleDialogResponse = () => {
    handleRemoveNominationMessage(nomination.id, nomination.senderId);
    setDialogOpen(false);
  };

  const canDeleteMessage =
    basicProfile.role === ROLES.ADMIN || nomination.senderId === basicProfile.id;

  return (
    <section ref={cardRef} id={nomination.id} className="kudos-nomination-card">
      <Card>
        <article className="kudos-nomination-card__details">
          <div>
            <Link to={getEmployeeProfileURL(nomination.senderId)}>
              <img
                src={nomination.profileImageSrc}
                referrerPolicy="no-referrer"
                alt={USER_AVATAR_ALT}
              />
            </Link>
            <blockquote className="kudos-nomination-card__details-wrapper">
              <p>
                <Link to={getEmployeeProfileURL(nomination.senderId)}>{nomination.sender}</Link>
                &nbsp;gave Kudos to&nbsp;
                <Link to={getEmployeeProfileURL(nomination.receiverId)}>{nomination.receiver}</Link>
                &nbsp;for&nbsp;
                <span style={{ color: categoryColour }}>{category.name}</span>
              </p>
              <time title={nomination.date}>{nomination.timeSent}</time>
            </blockquote>
          </div>
          <div>
            {canDeleteMessage && (
              <Dropdown menuItems={menuItems} offset={[32, 0]} placement="bottom-start">
                <EllipsisIcon />
              </Dropdown>
            )}
          </div>
        </article>
        <div className="kudos-nomination-card__description">
          <p>{nomination.message}</p>
        </div>
        <CategoryTag category={category} />
        <div className="kudos-nomination-card__total-reactions">
          <div>
            {nomination.totalLikes > 0 && (
              <div>
                <LikeIcon />
                <span>{nomination.totalLikes}</span>
              </div>
            )}
          </div>
          <div>
            {nomination.totalComments > 0 && (
              <button
                onClick={() => {
                  onLoadComments(nomination.id);
                  setShowAllComments(true);
                }}
              >
                {nomination.totalComments}&nbsp;
                {nomination.totalComments === 1 ? 'Comment' : 'Comments'}
              </button>
            )}
          </div>
        </div>
        <div className="kudos-nomination-card__threads">
          <button onClick={onLike}>
            <HeartIcon
              className={getClassNames('kudos-nomination-card__heart-icon', {
                liked
              })}
            />
            <span>Like</span>
          </button>
          <button onClick={() => setFocusCommentField(true)}>
            <CommentIcon />
            <span>Comment</span>
          </button>
        </div>

        <NominationCardCommentSection
          userImage={basicProfile.image ?? basicProfile.defaultImage}
          onCommentSubmit={onCommentSubmit}
          nominationId={nomination.id}
          showAllComments={showAllComments}
          focusCommentField={focusCommentField}
          setFocusCommentField={setFocusCommentField}
          comments={nominationComments}
          replies={commentReplies}
          onReplySubmit={onReplySubmit}
          onLoadReplies={onLoadReplies}
          loggedInUserId={basicProfile.id}
          loggedInUserRole={basicProfile.role}
          onRemoveComment={onRemoveComment}
          onUpdateNominationComment={onUpdateNominationComment}
        />
      </Card>
      {isModalOpen && (
        <DeleteNominationContainer
          nominationId={nomination.id}
          onClose={() => setModalOpen(false)}
        />
      )}
      {isDialogOpen && (
        <Dialog
          heading="Are you sure?"
          message={
            <>
              You are about to remove a nomination message for&nbsp;
              <span className="kudos-nomination-card__creator">{nomination.sender}</span>. Please
              confirm.
            </>
          }
          isOpen={isDialogOpen}
          primaryText="Confirm"
          secondaryText="Cancel"
          onPrimaryClick={handleDialogResponse}
          onSecondaryClick={() => setDialogOpen(false)}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </section>
  );
};

KudosNominationCard.defaultProps = {
  nomination: {
    totalLikes: 0,
    liked: false
  },
  onNominationLikeToggle: noOp,
  onLoadComments: noOp,
  onLoadReplies: noOp,
  nominationComments: [],
  onUpdateNominationComment: noOp,
  commentReplies: [],
  onRemoveComment: noOp
};

KudosNominationCard.propTypes = {
  nomination: NOMINATION_TYPE,
  onNominationLikeToggle: func,
  nominationComments: arrayOf(
    shape({
      id: string.isRequired,
      nominationId: string.isRequired,
      profileImageSrc: string.isRequired,
      sender: string.isRequired,
      message: string.isRequired,
      createdBy: string.isRequired
    })
  ),
  commentReplies: arrayOf(
    shape({
      id: string.isRequired,
      nominationId: string.isRequired,
      profileImageSrc: string.isRequired,
      sender: string.isRequired,
      message: string.isRequired,
      createdBy: string.isRequired,
      parentCommentId: string.isRequired
    })
  ),
  onLoadComments: func,
  onLoadReplies: func,
  onCommentSubmit: func.isRequired,
  onReplySubmit: func.isRequired,
  onUpdateNominationComment: func,
  onRemoveComment: func.isRequired
};

export default KudosNominationCard;
