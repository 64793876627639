const STORE_NAME = '[TASK_STORE]';

export const CREATE_TASK = `${STORE_NAME} Create task`;
export const createTask = (projectId, payload, onSuccess, onComplete) => ({
  type: CREATE_TASK,
  projectId,
  payload,
  onSuccess,
  onComplete
});

export const REMOVE_TASK = `${STORE_NAME} Remove task`;
export const removeTask = (projectId, taskId, onComplete) => ({
  type: REMOVE_TASK,
  projectId,
  taskId,
  onComplete
});

export const ADD_REALTIME_TASK = `${STORE_NAME} add task in realtime`;
export const addRealtimeTask = (event) => ({
  type: ADD_REALTIME_TASK,
  event
});

export const REMOVE_REALTIME_TASK = `${STORE_NAME} remove task in realtime`;
export const removeRealtimeTask = (event) => ({
  type: REMOVE_REALTIME_TASK,
  event
});

export const CLEAR_TASKS = `${STORE_NAME} Clear tasks`;
export const clearTasks = () => ({ type: CLEAR_TASKS });

export const SET_TASKS = `${STORE_NAME} Set tasks`;
export const LOAD_TASKS = `${STORE_NAME} Load tasks`;
export const loadTasks = (id, onComplete) => ({
  type: LOAD_TASKS,
  id,
  onComplete
});
