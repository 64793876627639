import { generateId, isEmpty } from '@neslotech/utils';

export const SKELETON_ENTITY_PLACEHOLDERS = [{ id: '1' }, { id: '2' }, { id: '3' }];

// in case you need to bind/define specific/expected properties
// to the placeholders (during loading)
export const getSkeletonPlaceholders = (
  dynamicProperties = [],
  noOfElements = SKELETON_ENTITY_PLACEHOLDERS.length
) => {
  if (!dynamicProperties || !Array.isArray(dynamicProperties)) {
    throw Error('This function is pointless to use without dynamicProperties');
  }

  if (noOfElements === SKELETON_ENTITY_PLACEHOLDERS.length && isEmpty(dynamicProperties)) {
    return SKELETON_ENTITY_PLACEHOLDERS;
  }

  return new Array(noOfElements).fill({}).map((placeholder) => {
    const updated = { ...placeholder };

    dynamicProperties.forEach((dyProp) => {
      updated[dyProp] = `placeholder ${generateId()}`;
    });

    return updated;
  });
};

export const DRILLABLE_ELEMENTS = ['div', 'section', 'aside', 'nav', 'ul', 'ol'];

export const getCssValue = (node, propertyName) => {
  if (!node || !propertyName) {
    return undefined;
  }

  return window.getComputedStyle(node)[propertyName];
};

export const getAllCSSMargins = (node) => {
  if (!node) {
    return {};
  }

  return {
    marginRight: getCssValue(node, 'marginRight'),
    marginLeft: getCssValue(node, 'marginLeft'),
    marginTop: getCssValue(node, 'marginTop'),
    marginBottom: getCssValue(node, 'marginBottom')
  };
};

export const getNodeBoxModel = (element) => {
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
    top: element.offsetTop,
    left: element.offsetLeft,
    ...getAllCSSMargins(element)
  };
};

export const getPlaceholderStyles = (node) => {
  return {
    width: node.width,
    height: node.height,
    top: node.top,
    left: node.left,
    background: '#dfdfdf',
    marginRight: node.marginRight,
    marginLeft: node.marginLeft,
    marginTop: node.marginTop,
    marginBottom: node.marginBottom
  };
};
