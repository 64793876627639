import { useLayoutEffect, useState } from 'react';

export const useHover = (element) => {
  const [active, setActive] = useState(false);
  const handleOnMouseEnter = (event) => {
    setActive(true);
  };
  const handleOnMouseLeave = () => {
    setActive(false);
  };

  useLayoutEffect(() => {
    const hoverElement = element.current;
    if (!!element) {
      hoverElement.addEventListener('mouseover', handleOnMouseEnter);
      hoverElement.addEventListener('mouseleave', handleOnMouseLeave);
    }

    return () => {
      if (!!element) {
        hoverElement?.removeEventListener('mouseover', handleOnMouseEnter);
        hoverElement?.removeEventListener('mouseleave', handleOnMouseLeave);
      }
    };
  }, [element]);

  return { active };
};
