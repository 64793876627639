import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import './tabs.scss';

const Tabs = ({ selectedTab, setSelectedTab, tabs }) => (
  <nav>
    <ul className="tabs">
      {tabs.map((item) => (
        <li
          key={item.label}
          className={getClassNames('tabs__tab', { selected: item.value === selectedTab })}
          onKeyDown={noOp}
          onClick={() => setSelectedTab(item.value)}
        >
          <span>{item.label}</span>
        </li>
      ))}
    </ul>
  </nav>
);

Tabs.defaultProps = {
  tabs: []
};

Tabs.propTypes = {
  setSelectedTab: func.isRequired,
  selectedTab: oneOfType([string, number]).isRequired,
  tabs: arrayOf(
    shape({
      label: string.isRequired,
      value: oneOfType([string, number]).isRequired
    })
  )
};

export default Tabs;
