import { Outlet } from 'react-router-dom';

import { SchedulingPermissionsProvider } from './SchedulingPermissions.provider';

import { SchedulingLayout } from '../component/layout/scheduling/SchedulingLayout';

const SchedulingRoute = () => {
  return (
    <SchedulingPermissionsProvider>
      <SchedulingLayout>
        <Outlet />
      </SchedulingLayout>
    </SchedulingPermissionsProvider>
  );
};

export default SchedulingRoute;
