import { shape, string } from 'prop-types';

import './points-stat.scss';

const PointsStat = ({ stat }) => (
  <article className="points-stat">
    <span className="points-stat__value">{stat.value}</span>
    <span className="points-stat__description">{stat.description}</span>
  </article>
);

PointsStat.prototype = {
  stat: shape({
    value: string,
    description: string
  }).isRequired
};

export default PointsStat;
