import { filterElement, searchElement } from '../../tool/filter.util';

export const mapUsers = (users, search, filters) =>
  users
    .filter((user) => filterElement(user, filters))
    .filter(
      (user) =>
        searchElement(user.firstName, search) ||
        searchElement(user.lastName, search) ||
        searchElement(user.emailAddress, search) ||
        searchElement(user.department, search)
    );

export const FILTER_OPTIONS = Object.freeze([
  {
    name: 'Role',
    active: false,
    values: [
      {
        value: 'Admin',
        selected: false
      },
      {
        value: 'Employee',
        selected: false
      }
    ]
  },
  {
    name: 'Department',
    active: false,
    values: [
      {
        value: 'Engineers',
        selected: false
      },
      {
        value: 'Designers',
        selected: false
      },
      {
        value: 'Executives',
        selected: false
      },
      {
        value: 'Administration',
        selected: false
      }
    ]
  }
]);

export const getEmployeeProfileURL = (userId) => {
  return `/employees/directory/${userId}/profile`;
};
