import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { clearKudosErrors } from '../../../../state/action/kudos/kudos.actions';
import { createReport } from '../../../../state/action/kudos/report.actions';

import { useDevice } from '../../../../hook/useDevice';
import { useProgressLoader } from '../../../../hook/useProgressLoader';

import DeleteNomination from '../../../../component/kudos/management/delete-nomination/DeleteNomination';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';
import { SidePortal } from '../../../../common/layout/portal/SidePortal';

const DeleteNominationContainer = ({ nominationId, onClose }) => {
  const dispatch = useDispatch();

  const { mobile } = useDevice();
  const { setBusyState, setFullScreen } = useProgressLoader();

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  const handleCancel = () => {
    dispatch(clearKudosErrors());
    onClose();
  };

  const handleSubmit = (form) => {
    setBusyState(true);
    dispatch(createReport(nominationId, form, handleCancel, () => setBusyState(false)));
  };

  if (mobile) {
    return (
      <SidePortal open>
        <section className="delete-nomination-modal">
          <DeleteNomination onCancel={onClose} onSubmit={handleSubmit} />
        </section>
      </SidePortal>
    );
  }

  return (
    <Modal className="delete-nomination-modal">
      <ModalLayout onClose={handleCancel} large title="Delete Nomination">
        <DeleteNomination onCancel={onClose} onSubmit={handleSubmit} />
      </ModalLayout>
    </Modal>
  );
};

DeleteNominationContainer.propTypes = {
  onClose: func.isRequired
};

export default DeleteNominationContainer;
