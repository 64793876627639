import { bool, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, isEmpty, noOp } from '@neslotech/utils';

import { useKudosManagement } from '../../../hook/useKudosManagement';

import { Button } from '../../../common/component/button/Button';
import { Form } from '../../../common/component/form/Form';
import { FormRow } from '../../../common/component/form/form-row/FormRow';
import { Input } from '../../../common/component/input/Input';
import NumberPicker from '../../../common/component/number-picker/NumberPicker';
import { TextArea } from '../../../common/component/textarea/TextArea';

import CategoryTag from '../../kudos/category-tag/CategoryTag';
import ColourSelect from '../input/ColourSelect';

import './category-card.scss';

export const SELECT_COLOUR_DEFAULT = Object.freeze({ colour: '#CECECE' });

export const SELECT_COLOURS = Object.freeze([
  { colour: '#00D849' },
  { colour: '#9747FF' },
  { colour: '#FFA800' },
  { colour: '#5A9AF8' },
  { colour: '#EB5D3E' },
  { colour: '#FFD910' },
  { colour: '#3EC1EB' }
]);

export const SELECT_TEXT_COLOURS = Object.freeze([
  { colour: '#080808' },
  { colour: '#FEFEFE', borderColour: '#CECECE' }
]);

const CategoryCard = ({
  category,
  actionText,
  cancel,
  buttonLarge,
  focused,
  inactive,
  errors,
  onClose,
  onActionClick,
  onFocus,
  noPadding
}) => {
  const [form, setForm] = useState({ ...category });
  const { categoryLoading, clearErrors } = useKudosManagement();

  const handleChange = (newState) => {
    clearErrors();
    setForm({ ...form, ...newState });
  };

  const handleOnClose = () => {
    setForm({ ...category });
    onClose();
  };

  const handleOnClick = () => {
    onActionClick(form);
  };

  const handleColourChange = (colorCode) => {
    if (focused) {
      handleChange({ colorCode });
    }
  };

  const handleTextColourChange = (textColorCode) => {
    if (focused) {
      handleChange({ textColorCode });
    }
  };

  const labelName = focused ? 'Category Name' : isEmpty(form.name) ? 'Category Name' : form.name;
  const labelDescription = focused
    ? 'Description'
    : isEmpty(form.description)
    ? 'Description'
    : form.description;

  return (
    <div className={getClassNames('category-card', { focused, inactive, 'no-padding': noPadding })}>
      <CategoryTag category={{ ...form, name: isEmpty(form.name) ? 'Category Name' : form.name }} />
      <Form>
        <FormRow fullWidth vertical>
          <Input
            label={labelName}
            name="name"
            value={focused ? form?.name : ''}
            error={errors?.name}
            onFocus={onFocus}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fullWidth vertical>
          <TextArea
            label={labelDescription}
            name="description"
            value={focused ? form?.description : ''}
            error={errors?.description}
            onFocus={onFocus}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fullWidth vertical>
          <ColourSelect
            label="Category Colour"
            name="colorCode"
            value={focused ? form?.colorCode : ''}
            error={errors?.colorCode}
            colours={SELECT_COLOURS}
            colourPickerButtonColour={SELECT_COLOUR_DEFAULT}
            showColourPicker
            customColour={category?.colorCode}
            disabled={!focused}
            onSelectionChange={handleColourChange}
          />
        </FormRow>
        <FormRow fullWidth>
          <ColourSelect
            label="Text Colour"
            name="textColorCode"
            value={focused ? form?.textColorCode : ''}
            error={errors?.textColorCode}
            colours={SELECT_TEXT_COLOURS}
            disabled={!focused}
            onSelectionChange={handleTextColourChange}
          />
          <NumberPicker
            label="Point Allocation"
            name="points"
            value={form?.points}
            error={errors?.points}
            min={0}
            max={100}
            disabled={!focused}
            onChange={(points) => handleChange({ points })}
          />
        </FormRow>
      </Form>
      <div className={getClassNames('category-card__actions', { 'button-large': buttonLarge })}>
        <Button label={actionText} onClick={handleOnClick} loading={focused && categoryLoading} />
        {cancel && !buttonLarge && (
          <Button label="Cancel" onClick={handleOnClose} secondary borderless fit />
        )}
        {cancel && buttonLarge && <Button label="Cancel" onClick={handleOnClose} secondary />}
      </div>
    </div>
  );
};

CategoryTag.defaultProps = {
  cancel: false,
  focused: false,
  inactive: false,
  onClose: noOp,
  onActionClick: noOp,
  onFocus: noOp
};

CategoryCard.protoTypes = {
  category: shape({
    colorCode: string,
    textColorCode: string,
    name: string,
    points: number
  }).isRequired,
  actionText: string.isRequired,
  cancel: bool,
  focused: bool,
  inactive: bool,
  onClose: func,
  onActionClick: func,
  onFocus: func
};

export default CategoryCard;
