import { Outlet } from 'react-router-dom';

import { SchedulingSettingsLayout } from '../component/layout/scheduling/settings/SchedulingSettingsLayout';

const SchedulingSettingsRoute = () => (
  <SchedulingSettingsLayout>
    <Outlet />
  </SchedulingSettingsLayout>
);

export default SchedulingSettingsRoute;
