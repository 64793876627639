import { any, arrayOf, bool, node, number, oneOfType, shape } from 'prop-types';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import './tooltip.scss';

import { useHover } from '../../../../hook/useHover';

/**
 * Create a tooltip on the hover state of a predefined parent component.
 * @param children The content to display inside the tooltip component.
 * @param parent The parent component where the hover needs to be implemented.
 * @param fullWidth Should the tooltip be displayed as a full screen tooltip. Used mostly on mobile devices.
 * @param offset The tooltip arrow is centered by default. This is to offset left from the center.
 *               This is used in the case where the tooltip might render offscreen.
 * @param zIndex The optional zIndex for the tooltip. If not set it defaults to the parent zIndex;
 * @returns {React.ReactPortal} The portal component.
 * @constructor
 */
export const Tooltip = ({ children, parent, fullWidth, offset, zIndex, isVisible }) => {
  const { active } = useHover(parent);
  const tooltipVisible = isVisible !== undefined ? isVisible : active;

  const parentBounds = useRef({ top: 0, left: 0, width: 0, height: 0 });
  const arrowBounds = useRef({ width: 0, height: 0 });
  const contentBounds = useRef({ width: 0, height: 0 });

  const mount = document.getElementById('tooltip-root');
  const tooltip = document.createElement('section');
  const arrow = document.createElement('div');
  const content = document.createElement('section');

  useEffect(() => {
    tooltip.setAttribute('class', `tooltip ${tooltipVisible ? 'tooltip--active' : ''}`);
    arrow.setAttribute('class', 'tooltip__arrow');
    content.setAttribute('class', `tooltip__content ${fullWidth ? 'tooltip--full-width' : ''}`);
  }, [tooltipVisible, fullWidth, tooltip, arrow, content]);

  const setBounds = (ref, element) => {
    //Update display to get accurate bounds of the element.
    const bounds = element.getBoundingClientRect();

    if (!zIndex) {
      zIndex = element.zIndex;
    }

    ref.current = {
      top: bounds.top,
      left: bounds.left,
      width: bounds.width,
      height: bounds.height,
      zIndex
    };
  };

  const handlePositionTooltip = () => {
    //Get all tooltip component bounds for calculations.
    setBounds(arrowBounds, arrow);
    setBounds(contentBounds, content);

    const parentCenter = parentBounds.current.left + parentBounds.current.width / 2;
    const contentCenter = contentBounds.current.width / 2;
    const arrowCenter = arrowBounds.current.width / 2;

    //Position tooltip container
    const left = fullWidth ? 0 : parentCenter - contentCenter + offset;
    const top = parentBounds.current.top + parentBounds.current.height + arrowBounds.current.height;
    tooltip.setAttribute(
      'style',
      `top: ${top}px; left: ${left}px; z-index: ${parentBounds.current.zIndex}`
    );

    //Position tooltip arrow
    const arrowLeft = (fullWidth ? parentCenter : contentCenter) - arrowCenter - offset;
    arrow.setAttribute(
      'style',
      `left: ${arrowLeft}px; z-index: ${parentBounds.current.zIndex + 1}`
    );
  };

  useEffect(() => {
    //Mount tooltip
    mount.appendChild(tooltip);
    tooltip.appendChild(arrow);
    tooltip.appendChild(content);

    handlePositionTooltip();

    const handleOnScroll = () => {
      setBounds(parentBounds, parent.current);
      handlePositionTooltip();
    };

    document.body.addEventListener('scroll', handleOnScroll);

    return () => {
      mount.removeChild(tooltip);
      document.body.removeEventListener('scroll', handleOnScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltip, mount, content, arrow, parentBounds]);

  useLayoutEffect(() => {
    if (!!parent) {
      setBounds(parentBounds, parent.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent.current]);

  return createPortal(children, content);
};

Tooltip.defaultProps = {
  fullWidth: false,
  offset: 0,
  zIndex: 1,
  isVisible: undefined
};

Tooltip.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  parent: shape({
    current: any
  }).isRequired,
  fullWidth: bool,
  offset: number,
  zIndex: number,
  isVisible: bool
};
