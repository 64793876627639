import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { clearProject, loadProject } from '../../../../state/action/scheduling/project.actions';
import { clearTasks, loadTasks } from '../../../../state/action/scheduling/task.actions';

import { EditProjectDetailsContainer } from '../detail/edit/EditProjectDetails.container';
import { ProjectMembersContainer } from '../member/ProjectMembers.container';
import { TaskListContainer } from '../task/TaskList.container';

import { Stepper } from '../../../../component/stepper/Stepper';

const STEPS = Object.freeze([
  { id: 1, title: 'Project Details', component: EditProjectDetailsContainer, enabled: true },
  { id: 2, title: 'Project Members', component: ProjectMembersContainer, enabled: true },
  { id: 3, title: 'Project Tasks', component: TaskListContainer, enabled: true }
]);

const EditProjectContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const stateLoading = useSelector(({ project_store }) => project_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadProject(id, () => setLoading(false)));
    dispatch(loadTasks(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearProject());
      dispatch(clearTasks());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComplete = () => {
    dispatch(clearTasks());
    navigate('/scheduling/projects');
  };

  const handleCancel = () => navigate(-1);

  return (
    <Stepper steps={STEPS} onCancel={handleCancel} onComplete={handleComplete} loading={loading} />
  );
};

export default EditProjectContainer;
