import React from 'react';
import { Link, Route } from 'react-router-dom';

import { getEmployeeProfileURL } from '../component/employee-directory/employee-directory.helper';

import { ROLES } from '../tool/constant';

import ProtectedRoute from '../provider/ProtectedRoute';

import EmployeeDirectoryContainer from '../container/employee-directory/EmployeeDirectory.container';
import BasicProfileContainer from '../container/user/profile/BasicProfile.container';

export const employeeDirectoryRoutes = () => (
  <Route
    path="employees/directory"
    handle={{
      crumb: () => <Link to="/employees/directory">Employee Directory</Link>,
      pageName: 'Employee Directory',
      pageTitle: 'Employee Directory'
    }}
  >
    <Route
      exact
      path=""
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <EmployeeDirectoryContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path=":userId/profile"
      handle={{
        crumb: ({ params: { userId } }) => <Link to={getEmployeeProfileURL(userId)}>Profile</Link>,
        pageName: 'Profile',
        pageTitle: 'Employee Profile'
      }}
      element={
        <ProtectedRoute allowedRoles={[ROLES.ADMIN, ROLES.EMPLOYEE]}>
          <BasicProfileContainer />
        </ProtectedRoute>
      }
    />
  </Route>
);
