import React from 'react';

import { ReactComponent as LargeBellIcon } from './../../../icon/large-notification-icon.svg';

import './empty-notifications.scss';

const EmptyNotifications = () => {
  return (
    <section className="empty-notifications">
      <div className="empty-notifications__wrapper">
        <LargeBellIcon />
        <h6>There is nothing to see here</h6>
        <span>No one has anything to share just yet.</span>
      </div>
    </section>
  );
};

export default EmptyNotifications;
