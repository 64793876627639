export const LOAD_NOMINATION_COMMENTS = '[KUDOS_STORE] Load Nomination Comments';
export const LOAD_NOMINATION_COMMENT_REPLIES = '[KUDOS_STORE] Load Nomination Comment Replies';
export const CREATE_KUDOS_NOMINATION_COMMENT = '[KUDOS_STORE] Create Kudos Nomination Comment';
export const REMOVE_NOMINATION_COMMENT = '[KUDOS_STORE] Remove Nomination Comment';
export const UPDATE_NOMINATION_COMMENT = '[KUDOS_STORE] Update Nomination Comment';
export const CREATE_KUDOS_COMMENT_REPLY = '[KUDOS_STORE] Create Kudos Nomination Comment Reply';
export const SET_NOMINATION_COMMENTS = '[KUDOS_STORE] Set Nomination Comments';
export const SET_NOMINATION_COMMENT_REPLIES = '[KUDOS_STORE] Set Nomination Comment Replies';
export const SET_REALTIME_NOMINATION_COMMENT = '[KUDOS_STORE] Set real time nomination comment';
export const SET_REALTIME_NOMINATION_COMMENT_UPDATE =
  '[KUDOS_STORE] Set real time nomination comment update';
export const SET_REALTIME_NOMINATION_COMMENT_REMOVE =
  '[KUDOS_STORE] Set real time nomination comment remove';
export const SET_REALTIME_NOMINATION_REPLY = '[KUDOS_STORE] Set real time nomination reply';

export const createKudosNominationComment = (nominationId, payload, onSuccess, onError) => ({
  type: CREATE_KUDOS_NOMINATION_COMMENT,
  nominationId,
  payload,
  onSuccess,
  onError
});

export const loadNominationComments = (nominationId) => ({
  type: LOAD_NOMINATION_COMMENTS,
  nominationId
});

export const createKudosCommentReply = (nominationId, commentId, payload, onSuccess, onError) => ({
  type: CREATE_KUDOS_COMMENT_REPLY,
  nominationId,
  commentId,
  payload,
  onSuccess,
  onError
});

export const removeNominationComment = (nominationId, commentId, onSuccess, onComplete) => ({
  type: REMOVE_NOMINATION_COMMENT,
  nominationId,
  commentId,
  onSuccess,
  onComplete
});

export const loadNominationCommentReplies = (nominationId, commentId) => ({
  type: LOAD_NOMINATION_COMMENT_REPLIES,
  nominationId,
  commentId
});

export const updateNominationComment = (nominationId, commentId, data, onSuccess, onComplete) => ({
  type: UPDATE_NOMINATION_COMMENT,
  nominationId,
  commentId,
  data,
  onSuccess,
  onComplete
});

export const setRealtimeNominationComment = (directoryUsers, commentEvent) => ({
  type: SET_REALTIME_NOMINATION_COMMENT,
  directoryUsers,
  commentEvent
});

export const setRealtimeNominationCommentUpdate = (commentEvent) => ({
  type: SET_REALTIME_NOMINATION_COMMENT_UPDATE,
  commentEvent
});

export const setRealtimeNominationCommentRemove = (commentEvent) => ({
  type: SET_REALTIME_NOMINATION_COMMENT_REMOVE,
  commentEvent
});

export const setRealtimeNominationReply = (directoryUsers, replyEvent) => ({
  type: SET_REALTIME_NOMINATION_REPLY,
  directoryUsers,
  replyEvent
});
