export const mapErrors = (data) => {
  if (!data || !data.subErrors) {
    return undefined;
  }

  return data.subErrors.reduce((accum, subError) => {
    return {
      ...accum,
      [subError.field]: subError.responseMessage
    };
  }, {});
};
