import { bool, func } from 'prop-types';
import React from 'react';

import { noOp } from '@neslotech/utils';

import './radio-selector.scss';

export const RadioSelector = ({ onClick, checked }) => (
  <button className="radio-selector" onClick={onClick}>
    {!checked && <div className="radio-selector__checked" />}
  </button>
);

RadioSelector.defaultProps = {
  onClick: noOp,
  checked: false
};

RadioSelector.propTypes = {
  onClick: func,
  checked: bool
};
