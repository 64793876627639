import { func } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearKudosErrors } from '../../../../state/action/kudos/kudos.actions';

import { useDevice } from '../../../../hook/useDevice';

import { Button } from '../../../../common/component/button/Button';
import ButtonGroup from '../../../../common/component/button/ButtonGroup';
import { Form } from '../../../../common/component/form/Form';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Select } from '../../../../common/component/select/Select';
import { TextArea } from '../../../../common/component/textarea/TextArea';

import './delete-nomination.scss';

const reasonOptions = [
  {
    label: 'Inappropriate Content',
    value: 'inappropriate-content'
  },
  {
    label: 'Harassment or Bullying',
    value: 'harassment'
  },
  {
    label: 'Off-Topic or Irrelevant',
    value: 'irrelevant'
  },
  {
    label: 'Privacy Violation',
    value: 'privacy-violation'
  },
  {
    label: 'Trolling or Provocation',
    value: 'provocation'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const DeleteNomination = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { mobile } = useDevice();

  const errors = useSelector(({ kudos_store }) => kudos_store.errors);

  const [form, setForm] = useState({ action: 'deleted' });

  const handleChange = (newState) => {
    dispatch(clearKudosErrors());

    setForm({ ...form, ...newState });
  };

  return (
    <section className="delete-nomination">
      {mobile && (
        <header>
          <h1>Delete Nomination</h1>
        </header>
      )}
      <Form>
        <FormRow fullWidth>
          <Select
            required
            label="Select a reason for removing this nomination"
            name="reason"
            value={form?.reason}
            error={errors?.reason}
            options={reasonOptions}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fullWidth>
          <TextArea
            required
            label="Write a message"
            name="message"
            value={form.message}
            error={errors?.message}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fullWidth>
          <ButtonGroup>
            <Button label="Remove Nomination" onClick={() => onSubmit(form)} />
            <Button label="Cancel" onClick={() => onCancel()} secondary />
          </ButtonGroup>
        </FormRow>
      </Form>
    </section>
  );
};

DeleteNomination.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired
};

export default DeleteNomination;
