import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useProgressLoader } from '../../../../hook/useProgressLoader';

import './progress-loader.scss';

/**
 * To use this loader, simply bind to the hook:
 * ```
 * const { setBusyState } = useProgressLoader();
 * ```
 * and then set busy to true:
 * ```
 * setBusyState(true);
 * ```
 * The loader seems to handle completion, so it
 * should set busy back to false. But it may
 * be best to explicitly set busy to false
 * in the relevant component itself.
 */
export const ProgressLoader = ({ fullScreen }) => {
  const { busy, setBusyState } = useProgressLoader();
  const [animatedPercentage, setAnimatedPercentage] = useState(0);

  const complete = () => {
    setAnimatedPercentage(100);

    setTimeout(() => {
      setAnimatedPercentage(0);
    }, 500);
  };

  useEffect(() => {
    if (busy && animatedPercentage < 99) {
      const frame = () => {
        setAnimatedPercentage((prevPercentage) => {
          const nextPercentage = prevPercentage + 1;
          if (nextPercentage < 99) {
            requestAnimationFrame(frame);
          } else {
            complete();
          }
          return nextPercentage;
        });
      };

      requestAnimationFrame(frame);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedPercentage, busy, setBusyState]);

  if (!busy) {
    return <></>;
  }

  return (
    <>
      <div id="progress-loader" className={fullScreen ? 'full-screen' : ''}>
        <div style={{ width: `${animatedPercentage}%` }} />
      </div>
      <div id="progress-loader-overlay" className={busy ? 'show' : ''} />
    </>
  );
};

ProgressLoader.defaultProps = {
  fullScreen: false
};

ProgressLoader.propTypes = {
  fullScreen: bool
};
