export const SET_ALL_NOTIFICATIONS = '[NOTIFICATION_STORE] Set All Notifications';

export const LOAD_ALL_NOTIFICATIONS = '[NOTIFICATION_STORE] Load All Notifications';
export const MARK_ALL_NOTIFICATIONS_AS_READ = '[NOTIFICATION_STORE] Mark All Notifications As Read';
export const INVERT_READ_FLAG = '[NOTIFICATION_STORE] Invert Read Flag';

export const ADD_REALTIME_NOTIFICATION = '[NOTIFICATION_STORE] Add realtime notification';

export const SET_NOTIFICATIONS_BUSY = '[NOTIFICATION_STORE] set notifications busy';

export const loadAllNotifications = (userId) => ({
  type: LOAD_ALL_NOTIFICATIONS,
  userId
});

export const setAllNotifications = (allNotifications) => ({
  type: SET_ALL_NOTIFICATIONS,
  allNotifications
});

export const markAllNotificationsAsRead = (userId, navigate, onSuccess, onComplete) => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ,
  userId,
  navigate,
  onSuccess,
  onComplete
});

export const invertReadFlag = (userId, id, navigate, onComplete) => ({
  type: INVERT_READ_FLAG,
  userId,
  id,
  navigate,
  onComplete
});

export const addRealtimeNotification = (notification) => ({
  type: ADD_REALTIME_NOTIFICATION,
  notification
});

export const setNotificationsBusy = (loading) => ({
  type: SET_NOTIFICATIONS_BUSY,
  loading
});
