import { arrayOf, node, oneOfType, string } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SNACK_CRITICAL } from '@neslotech/utils';

import { getAuthTokenCookie } from '../tool/auth.util';

import { addSystemNotice } from '../state/action/system.actions';

import AuthProvider from './Auth.provider';
import { ChannelProvider } from './Channel.provider';

import AppTitle from '../common/component/app-title/AppTitle';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = getAuthTokenCookie();

  const memoizedToken = useMemo(() => {
    return token;
  }, [token]);

  const profile = useSelector(({ profile_store }) => profile_store.contextUser);

  useEffect(() => {
    if (!memoizedToken || (!!profile && !allowedRoles.includes(profile.role))) {
      dispatch(addSystemNotice('You are not authorized to view this page.', SNACK_CRITICAL));
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedToken, allowedRoles, profile?.role]);

  return (
    <AuthProvider>
      <ChannelProvider channelName={profile?.id}>
        <AppTitle>{children}</AppTitle>
      </ChannelProvider>
    </AuthProvider>
  );
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  allowedRoles: arrayOf(string).isRequired
};
