import { func, node, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { USER_AVATAR_ALT } from '../../tool/constant';

import { UnreadIndicator } from './unread-indicator/UnreadIndicator';

import './notification.scss';

const Notification = ({ notification, handleClick, onNotificationReadToggle }) => {
  const [read, setRead] = useState(notification.read ?? false);

  const handleNotificationClick = () => {
    setRead(!read);
    onNotificationReadToggle(notification.id, !read);
    handleClick();
  };

  return (
    <button
      className={getClassNames('notification', {
        nomination: !!notification.metadata?.nominationId
      })}
      onClick={handleNotificationClick}
    >
      <img src={notification.profileImageSrc} referrerPolicy="no-referrer" alt={USER_AVATAR_ALT} />
      <div className="notification__message">
        {notification.message}&nbsp;
        <time>{notification.timeSent}</time>
      </div>
      <UnreadIndicator isRead={read} onClick={handleNotificationClick} />
    </button>
  );
};

Notification.defaultProps = {
  onNotificationReadToggle: noOp,
  handleClick: noOp
};

Notification.propTypes = {
  onNotificationReadToggle: func,
  handleClick: func,
  notification: shape({
    id: string,
    category: string,
    message: node,
    timeSent: string,
    type: string,
    metadata: shape({
      nominationId: string,
      metadataType: string,
      recipientId: string
    })
  }).isRequired
};

export default Notification;
