import Cookies from 'js-cookie';

const ACCESS_TOKEN_ID = 'neslo-auth-token';

/** Sets the auth token cookie */
export const setAuthTokenCookie = (token) => {
  Cookies.set(ACCESS_TOKEN_ID, token);
};

/** Get the auth token cookie */
export const getAuthTokenCookie = () => {
  return Cookies.get(ACCESS_TOKEN_ID);
};

/** Remove the auth token cookie */
export const removeAuthTokenCookie = () => {
  Cookies.remove(ACCESS_TOKEN_ID);
};

/** Retrieves the Google Client ID for authentication */
export const getClientId = () => {
  return process.env.REACT_APP_GOOGLE_CLIENT_ID;
};

export const getAuthHeaders = () => {
  return { Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN_ID)}` };
};
