const STORE_NAME = '[TASK_ALLOCATION_STORE]';

export const CREATE_TASK_ALLOCATION = `${STORE_NAME} Create task allocation`;
export const createTaskAllocation = (taskAllocation, onSuccess, onComplete) => ({
  type: CREATE_TASK_ALLOCATION,
  taskAllocation,
  onSuccess,
  onComplete
});

export const CREATE_USER_TASK_ALLOCATION = `${STORE_NAME} Create user task allocation`;
export const createUserTaskAllocation = (userId, taskAllocation, onSuccess, onComplete) => ({
  type: CREATE_USER_TASK_ALLOCATION,
  userId,
  taskAllocation,
  onSuccess,
  onComplete
});

export const UPDATE_TASK_ALLOCATION = `${STORE_NAME} Update task allocation`;
export const updateTaskAllocation = (
  taskAllocationId,
  userId,
  taskAllocation,
  onSuccess,
  onComplete
) => ({
  type: UPDATE_TASK_ALLOCATION,
  taskAllocationId,
  userId,
  taskAllocation,
  onSuccess,
  onComplete
});

export const REMOVE_TASK_ALLOCATION = `${STORE_NAME} Remove task allocation`;
export const removeTaskAllocation = (taskAllocationId, userId, onSuccess, onComplete) => ({
  type: REMOVE_TASK_ALLOCATION,
  taskAllocationId,
  userId,
  onSuccess,
  onComplete
});
