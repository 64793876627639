import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { Card } from '../../../common/component/card/Card';

import './auth-layout.scss';

const AuthLayout = ({ children }) => (
  <section className="auth-layout">
    <Card white shadowed>
      {children}
    </Card>
  </section>
);

AuthLayout.defaultProps = {};

AuthLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default AuthLayout;
