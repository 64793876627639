import { string } from 'prop-types';
import React from 'react';

import './input-error.scss';

const InputError = ({ error }) => <small className="input__error">{error}</small>;

InputError.propTypes = {
  error: string.isRequired
};

export default InputError;
