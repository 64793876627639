import { arrayOf, bool, number, oneOf, oneOfType, shape, string } from 'prop-types';

import { ROLES } from './constant';

export const PROFILE_TYPE = shape({
  id: string.isRequired,
  bio: string,
  birthday: string,
  defaultImage: string,
  department: string,
  email: string,
  emergencyContact: string,
  emergencyContactNumber: string,
  fullName: string,
  image: string,
  jobTitle: string,
  joinedOn: string,
  phoneNumber: string,
  role: oneOf([ROLES.EMPLOYEE, ROLES.ADMIN, ROLES.OPERATIONS]),
  socialLinks: arrayOf(
    shape({
      id: string,
      type: oneOf(['GIT_HUB', 'BEHANCE', 'LINKED_IN']),
      link: string
    })
  )
});

export const USER_TYPE = shape({
  id: string.isRequired,
  bio: string,
  dateOfBirth: string,
  defaultImage: string,
  department: string,
  email: string,
  emergencyContactName: string,
  emergencyContactNumber: string,
  firstName: string,
  gender: string,
  image: string,
  jobTitle: string,
  lastName: string,
  phoneNumber: string,
  role: oneOf([ROLES.EMPLOYEE, ROLES.ADMIN, ROLES.OPERATIONS]),
  secondName: string
});

export const CATEGORY_TYPE = shape({
  colorCode: string,
  textColorCode: string,
  name: string,
  points: number
});

export const NOMINATION_TYPE = shape({
  id: string.isRequired,
  sender: string,
  receiver: string,
  category: oneOfType([
    shape({
      colorCode: string,
      textColorCode: string,
      name: string,
      points: number
    }),
    string
  ]).isRequired,
  timeSent: string,
  senderId: string,
  receiverId: string,
  date: string,
  message: string,
  profileImageSrc: string,
  totalLikes: number,
  totalComments: number,
  liked: bool
}).isRequired;

export const CHECKBOX_TABLE_ROW_TYPE = shape({
  original: shape({
    firstName: string,
    lastName: string,
    department: string,
    checked: bool
  })
});

export const CHECKBOX_TABLE_USER_TYPE = shape({
  firstName: string.isRequired,
  lastName: string.isRequired,
  department: string,
  checked: bool
});

export const PROJECT_TYPE = shape({
  id: string.isRequired,
  name: string.isRequired,
  startDate: string.isRequired,
  endDate: string,
  description: string,
  clientId: string,
  colour: string.isRequired
});

export const CLIENT_TYPE = shape({
  id: string.isRequired,
  name: string.isRequired,
  additionalInformation: string
});

export const LEAVE_CATEGORY_TYPE = shape({
  id: string.isRequired,
  name: string.isRequired,
  colour: string.isRequired,
  deleted: bool.isRequired
});

export const LEAVE_ALLOCATION_TYPE = shape({
  id: string,
  assignee: string,
  leaveCategoryId: string,
  startDate: string,
  endDate: string,
  hours: number
});

export const TASK_ALLOCATION_TYPE = shape({
  id: string,
  assignee: string,
  projectId: string,
  taskId: string,
  startDate: string,
  endDate: string,
  hours: number
});
