import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import {
  getProjectEndpoint,
  getTaskEndpoint,
  getTasksEndpoint
} from '../../tool/api/scheduling/project.endpoint';

import {
  CREATE_TASK,
  LOAD_TASKS,
  REMOVE_TASK,
  SET_TASKS
} from '../../action/scheduling/task.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performCreateTask({ projectId, payload, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getTasksEndpoint(projectId),
      HttpVerb.POST,
      getAuthHeaders(),
      { title: payload }
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error creating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateTaskRequest() {
  yield takeLatest(CREATE_TASK, performCreateTask);
}

export function* performRemoveTask({ projectId, taskId, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getTaskEndpoint(projectId, taskId),
      HttpVerb.DELETE,
      getAuthHeaders()
    );

    yield call(axios, endpoint, axiosOptions);
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error creating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForRemoveTaskRequest() {
  yield takeLatest(REMOVE_TASK, performRemoveTask);
}

export function* performLoadTasks({ id, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${getProjectEndpoint(id)}/tasks`,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_TASKS, tasks: data });
  } catch ({ response }) {
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadTasks() {
  yield takeLatest(LOAD_TASKS, performLoadTasks);
}

export default function* taskSaga() {
  yield all([watchForCreateTaskRequest(), watchForRemoveTaskRequest(), watchForLoadTasks()]);
}
