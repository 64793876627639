import { GoogleLogin } from '@react-oauth/google';
import { func } from 'prop-types';
import React, { useEffect } from 'react';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import './login.scss';

const Login = ({ onSuccess, onError }) => {
  const { setBusyState, setFullScreen } = useProgressLoader();

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  const handleOnSuccess = async (credentialResponse) => {
    setBusyState(true);
    onSuccess(credentialResponse, () => setBusyState(false));
  };

  const handleOnError = () => {
    setBusyState(false);
    onError();
  };

  return (
    <section className="login">
      <h1>Login to your account</h1>
      <small>Please login by using your google account by clicking continue with Google.</small>
      <GoogleLogin
        onSuccess={handleOnSuccess}
        onError={handleOnError}
        theme="filled_black"
        shape="square"
        width={273}
        logo_alignment="left"
        size="large"
        text="continue_with"
      />
    </section>
  );
};

Login.propTypes = {
  onSuccess: func.isRequired,
  onError: func.isRequired
};

export default Login;
