import { noOp } from '@neslotech/utils';

export const SET_USER_PROFILE = '[PROFILE_STORE] Set user profile';
export const SET_CONTEXT_USER = '[PROFILE_STORE] Set context user profile';
export const SET_USER_BASIC_PROFILE = '[PROFILE_STORE] Set user basic profile';
export const SET_ERRORS = '[PROFILE_STORE] Set Errors';

export const SAVE_PROFILE_INFO = '[PROFILE_STORE] Save profile info';
export const saveProfileInfo = (id, info, key, onSuccess, onComplete) => ({
  type: SAVE_PROFILE_INFO,
  id,
  info,
  key,
  onSuccess,
  onComplete
});

export const UPDATE_PROFILE_INFO = '[PROFILE_STORE] Update profile info';
export const updateProfileInfo = (userId, info, key, onSuccess, onComplete) => ({
  type: UPDATE_PROFILE_INFO,
  userId,
  info,
  key,
  onSuccess,
  onComplete
});

export const ACTIVATE_PROFILE = '[PROFILE_STORE] Activate profile';
export const activateProfile = (id, onSuccess, onComplete) => ({
  type: ACTIVATE_PROFILE,
  id,
  onSuccess,
  onComplete
});

export const LOAD_PROFILE = '[PROFILE_STORE] Load Profile';
export const loadProfile = (userId, onComplete) => ({
  type: LOAD_PROFILE,
  userId,
  onComplete
});

export const LOAD_BASIC_PROFILE = '[PROFILE_STORE] Load Basic Profile';
export const loadBasicProfile = (userId, onComplete = noOp) => ({
  type: LOAD_BASIC_PROFILE,
  userId,
  onComplete
});

export const LOAD_CONTEXT_USER = '[PROFILE_STORE] Load Context User';
export const loadContextUser = (userId, onComplete = noOp) => ({
  type: LOAD_CONTEXT_USER,
  userId,
  onComplete
});

export const CLEAR_ERRORS = '[PROFILE_STORE] Clear Errors';
export const clearErrors = () => ({
  type: CLEAR_ERRORS
});
