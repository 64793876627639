import { SET_LEAVE_CATEGORIES } from '../../../action/scheduling/settings/leave-category.actions';

export const initialState = {
  categories: []
};

export function leaveCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LEAVE_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        categories
      };
    default:
      return state;
  }
}
