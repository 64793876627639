import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addUser, clearErrors } from '../../../../state/action/user.action';

import { useProgressLoader } from '../../../../hook/useProgressLoader';

import AddUser from '../../../../component/user/management/add/AddUser';

import { SidePortal } from '../../../../common/layout/portal/SidePortal';

const serverify = (form) => ({
  username: form.username,
  role: form.role,
  alternateEmailAddress: form.alternateEmail,
  firstName: form.firstName,
  lastName: form.lastName
});

const AddUserContainer = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const errors = useSelector(({ user_store }) => user_store.errors);

  const { setBusyState, setFullScreen } = useProgressLoader();

  useEffect(() => {
    setFullScreen(true);

    return () => setFullScreen(false);
  }, [setFullScreen]);

  const handleAddClick = (form) => {
    setBusyState(true);
    const payload = serverify(form);
    dispatch(addUser(payload, handleOnCloseClick, () => setBusyState(false)));
  };

  const handleOnCloseClick = () => {
    dispatch(clearErrors());
    onClose();
  };

  return (
    <SidePortal open={open}>
      <AddUser errors={errors} onAddClick={handleAddClick} onClose={handleOnCloseClick} />
    </SidePortal>
  );
};

export default AddUserContainer;
