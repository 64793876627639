import { all, call, put, takeLeading } from '@redux-saga/core/effects';
import axios from 'axios';

import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  getInvertReadFlagRequest,
  getLoadAllNotificationsRequest,
  getMarkAllNotificationsAsReadRequest
} from '../tool/api/notification.endpoint';

import {
  INVERT_READ_FLAG,
  LOAD_ALL_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  SET_ALL_NOTIFICATIONS,
  setNotificationsBusy
} from '../action/notification.actions';
import { addSystemNotice } from '../action/system.actions';

export function* performLoadAllNotifications({ userId }) {
  try {
    yield put(setNotificationsBusy(true));

    const [endpoint, requestOptions] = getLoadAllNotificationsRequest(userId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ALL_NOTIFICATIONS, allNotifications: data });
  } catch ({ response }) {
    yield put(addSystemNotice('Failed to load my notifications', SNACK_CRITICAL));
  } finally {
    yield put(setNotificationsBusy(false));
  }
}

export function* watchForLoadAllNotifications() {
  yield takeLeading(LOAD_ALL_NOTIFICATIONS, performLoadAllNotifications);
}

export function* performMarkAllNotificationsAsRead({ userId, navigate, onSuccess, onComplete }) {
  try {
    const [endpoint, requestOptions] = getMarkAllNotificationsAsReadRequest(userId);

    yield call(axios, endpoint, requestOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        'An error occurred while attempting to mark all notifications as read',
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForMarkAllNotificationsAsRead() {
  yield takeLeading(MARK_ALL_NOTIFICATIONS_AS_READ, performMarkAllNotificationsAsRead);
}

export function* performInvertReadFlag({ userId, id, navigate, onComplete }) {
  try {
    const [endpoint, requestOptions] = getInvertReadFlagRequest(userId, id);

    yield call(axios, endpoint, requestOptions);
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        'An error occurred while attempting to invert the notification read flag',
        SNACK_CRITICAL
      )
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForInvertReadFlag() {
  yield takeLeading(INVERT_READ_FLAG, performInvertReadFlag);
}

export default function* notificationsSaga() {
  yield all([
    watchForLoadAllNotifications(),
    watchForMarkAllNotificationsAsRead(),
    watchForInvertReadFlag()
  ]);
}
