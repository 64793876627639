import React from 'react';

import KudosCategoryManagementProvider from '../../provider/KudosCategoryManagement.provider';

import KudosCategoryManagement from '../../component/kudos/management/KudosCategoryManagement';
import AppLayout from '../../component/layout/AppLayout';

const KudosCategoryManagementContainer = () => {
  return (
    <AppLayout fullWidthBreadcrumb>
      <KudosCategoryManagementProvider>
        <KudosCategoryManagement />
      </KudosCategoryManagementProvider>
    </AppLayout>
  );
};

export default KudosCategoryManagementContainer;
