import PointsCardContainer from '../../../container/kudos/PointsCard.container';
import PopularCategoriesContainer from '../../../container/kudos/PopularCategories.container';

import Leaderboard from '../my-leaderboard/Leaderboard';

import './kudos-sidebar.scss';

const KudosSidebar = () => (
  <section id="kudos-sidebar">
    <PointsCardContainer />
    <Leaderboard />
    <PopularCategoriesContainer />
  </section>
);

export default KudosSidebar;
