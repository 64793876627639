import React from 'react';
import { useLocation, useMatches } from 'react-router-dom';

import { getClassNames, isEmpty } from '@neslotech/utils';

import './breadcrumb.scss';

export const Breadcrumb = ({ fullWidth }) => {
  const matches = useMatches();
  const location = useLocation();
  const path = location.pathname;

  const settingsPageActive = path.includes('/scheduling/settings');

  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader data to each one
    .map((match) => match.handle.crumb(match));

  if (isEmpty(crumbs)) {
    return null;
  }

  return (
    <nav
      className={getClassNames('breadcrumb', {
        'full-width': fullWidth,
        'scheduling-settings': settingsPageActive
      })}
    >
      <ul>
        {crumbs.map((crumb, index) => (
          <li key={index}>{crumb}</li>
        ))}
      </ul>
    </nav>
  );
};
