import { arrayOf, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isValuePresent } from '@neslotech/utils';

import { CLIENT_TYPE } from '../../../../../tool/prop-types';

import { FormAction } from '../../../../../common/component/form/form-action/FormAction';

import { Form } from '../../../../../common/component/form/Form';
import { FormRow } from '../../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../../common/component/input/Input';
import { TextArea } from '../../../../../common/component/textarea/TextArea';

import { ReactComponent as TrashIcon } from '../../../../../icon/trash-icon.svg';

import { SelectedColourPicker } from '../../../colour-picker/SelectedColourPicker';

import './client-edit-form.scss';

const REQUIRED_FIELDS = Object.freeze(['name', 'colour']);

const formify = (client) => {
  return {
    name: client.name,
    colour: client.colour,
    additionalInformation: client.additionalInformation
  };
};

const ClientEditForm = ({ client, onSubmit, onUnlinkProject }) => {
  const navigate = useNavigate();

  const [form, setForm] = useState(formify(client));

  useEffect(() => {
    setForm(formify(client));
  }, [client]);

  const isSubmitDisabled = useMemo(
    () => REQUIRED_FIELDS.some((field) => !isValuePresent(form[field])),
    [form]
  );

  const handleInputChange = (newState) => setForm({ ...form, ...newState });

  return (
    <section className="client-edit-form">
      <Form>
        <fieldset>
          <FormRow noMargin>
            <SelectedColourPicker name="colour" colour={form.colour} onChange={handleInputChange} />
            <Input
              name="name"
              placeholder="Client Name"
              value={form.name}
              onChange={handleInputChange}
              required
              borderless
            />
          </FormRow>
          <FormRow>
            <ul>
              {(client.projects ?? []).map((project) => (
                <li key={project.id}>
                  {project.name}
                  <button onClick={() => onUnlinkProject(project.id)}>
                    <TrashIcon />
                  </button>
                </li>
              ))}
            </ul>
          </FormRow>
          <FormRow>
            <TextArea
              label="Additional Information"
              name="additionalInformation"
              value={form.additionalInformation}
              onChange={handleInputChange}
            />
          </FormRow>
        </fieldset>
        <div className="client-edit-form__actions">
          <FormAction label="Cancel" secondary onClick={() => navigate('/scheduling/clients')} />
          <FormAction label="Update" disabled={isSubmitDisabled} onClick={() => onSubmit(form)} />
        </div>
      </Form>
    </section>
  );
};

ClientEditForm.propTypes = {
  client: shape({
    ...CLIENT_TYPE,
    projects: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired
      })
    )
  }).isRequired,
  onSubmit: func.isRequired,
  onUnlinkProject: func.isRequired
};

export default ClientEditForm;
